import { useContext, useEffect, useState } from "react";
import coins from "../_constants/polygonTokens.json";
import EthCoins from "../_constants/ethereumTokens.json";
import BscCoins from "../_constants/BSC.json";
import { ERC20Balance } from "../../../utils/erc20";
import { ChainInfo } from "../../../App";
import Avatar from "react-avatar";
import { useAccount } from "wagmi";
import { formatUnits } from "ethers/lib/utils.js";

const TokensRow = (props) => {
  const [data, setData] = useState({ decimals: 6, name: "", symbol: "" });
  const [balance, setBalance] = useState({ balance: -1, symbol: "" });
  const [chainId, setChainId] = useState();
  const [failToLoad, setFailToLoad] = useState(false);
  const { selectedChain } = useContext(ChainInfo);
  const { address } = useAccount();

  useEffect(() => {
    detectChian();
    console.log(props)
  }, []);

  const detectChian = async () => {
    const chainId = selectedChain;
    setChainId(chainId);
    if (chainId === 137) {
      getUserTokensMetadata(props.item.contractAddress);
    } else if (chainId === 56) {
      getUserBSCTokensMetadata(props.item.token_address);
    } else if (chainId === 1) {
      getUserTokensMetadataForEthereum(props.item.contractAddress);
    } else if (chainId === 43114) {
      setFailToLoad(true)
    }
  };

  const getUserTokensMetadataForEthereum = (address) => {
    EthCoins.forEach((item) => {
      if (item.address === address) {
        // console.log(item, ethers.utils.formatUnits(props.item.tokenBalance, item.decimals), item.decimals, props.item.tokenBalance);
        getContract();
        setData(item);
        return true;
      }
    });

    return false;
  };

  const getUserTokensMetadata = (address) => {
    coins.forEach((item) => {
      if (item.address === address) {
        // console.log(item, ethers.utils.formatUnits(props.item.tokenBalance, item.decimals), item.decimals, props.item.tokenBalance);
        getContract();
        setData(item);
        return true;
      }
    });

    return false;
  };

  const getUserBSCTokensMetadata = (address) => {
    BscCoins.forEach((item) => {
      if (item.address === address) {
        getContract();
        // setData(item);
        if (
          item?.symbol?.toLowerCase() === "bnb" ||
          item?.symbol?.toLowerCase() === "wbnb"
        ) {

          setData({
            chainId: 137,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            address: item.address,
            logoURI: "https://tokens.pancakeswap.finance/images/symbol/bnb.png",
            tags: ["erc20"],
            extensions: {
              rootAddress: item.address,
              project: {
                name: "-",
                summary: "-",
                contact: "-",
                website: ""
              }
            }
          })
          // logoURI = "https://tokens.pancakeswap.finance/images/symbol/bnb.png";
        } else {
          setData({
            chainId: 137,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            address: item.address,
            logoURI: "https://tokens.pancakeswap.finance/images/symbol/" + item?.symbol?.toLowerCase() + ".png",
            tags: ["erc20"],
            extensions: {
              rootAddress: item.address,
              project: {
                name: "-",
                summary: "-",
                contact: "-",
                website: ""
              }
            }
          })

        }


        return true;
      }
    });

    return false;
  };

  const getContract = async () => {
    const data = await ERC20Balance(
      props.provider,
      props.item.contractAddress || props.item.token_address,
      props.address
    );

    // console.log(data)
    setBalance(data);
  };

  return (
    <>
      {" "}
      {(props?.item?.symbol || data?.symbol !== "") ? (
        <div
          className="w-[100%] flex justify-center items-center p-1 rounded-[12px] mt-2"
          onClick={() => {
            if (chainId === 137) {
              props.onTap(props?.item?.contractAddress, data?.logoURI)
            } else if (chainId === 56) {
              props.onTap(props?.item?.token_address, data?.logoURI)
            } else if (chainId === 1) {
              props.onTap(props?.item?.contractAddress, data?.logoURI)
            } else if (chainId === 43114) {
              props.onTap(props?.item?.token_address, props?.item?.logo)
            }
          }}
        >
          <div className="w-[10%]">

            {(failToLoad) ?
              <Avatar
                name={data?.name || props?.item?.name}
                size="40"
                round
                color="#FE73A5"
                style={{ padding: 1 }}
              /> :
              <img
                className="w-[40px] h-[40px] rounded-md"
                onError={() => { setFailToLoad(true) }}
                src={
                  data?.logoURI || props?.item?.thumbnail
                }
                alt={"image"}
              />}
          </div>

          <div className="w-[70%] ml-2">
            <p className="w-[100%] text-start font-bold text-[14px] text-[white]">
              {data?.name || props?.item?.name}
            </p>
            <p className="w-[100%] text-[12px]  text-start font-bold text-[gray]">
              {data?.symbol || props?.item?.symbol}
            </p>
          </div>

          <div className="w-[20%]">
            {(selectedChain !== 43114) ? <p className="w-[100%] text-[14px] text-start font-bold text-[white]">
              {parseInt(balance.balance) !== -1
                ? parseFloat(balance.balance).toFixed(3) + " " +  data?.symbol || props?.item?.symbol
                : "Calculating..."}
            </p> :
              <p className="w-[100%] text-[14px] text-start font-bold text-[white]" style={{ textAlign: "end" }}>
                {/* {parseInt(props?.item?.balance) !== -1
                ? parseFloat(props?.item?.balance).toFixed(3) + " " + props?.item?.symbol
                : "Calculating..."} */}
                {parseFloat(formatUnits(props?.item?.balance, props?.item?.decimals))?.toFixed() + "  " + props?.item?.symbol}
              </p>}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TokensRow;
