// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";
// import { Web3Modal } from "@web3modal/react";
// import { configureChains, createClient, useDisconnect, WagmiConfig } from "wagmi";
// import {
//   mainnet,
//   polygon,
//   polygonMumbai,
//   goerli,
//   bsc,
//   sepolia,
//   bscTestnet,
//   baseGoerli,
//   base
// } from "@wagmi/chains";

// import LaunchDrops from "./pages/launchDrops";
// import { Toaster } from "react-hot-toast";
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'
// import BugsnagPerformance from '@bugsnag/browser-performance'

// const chains = [polygon,mainnet, polygonMumbai, goerli, sepolia, bscTestnet, bsc, baseGoerli, base];
// // const chains = [polygon, mainnet];
// // const chains = [pol,ygonMumbai, goerli];
// const projectId = "66333139f2c75fcc7f2d02e162f079e6"

// Bugsnag.start({
//   apiKey: '452a8b0a53ecdbcd5141751730662955',
//   plugins: [new BugsnagPluginReact()]
// })
// BugsnagPerformance.start({ apiKey: '452a8b0a53ecdbcd5141751730662955' })

// const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, version: 1, chains }),
//   provider,
// });

// const ErrorBoundary = Bugsnag.getPlugin('react')
//   .createErrorBoundary(React)

// const ethereumClient = new EthereumClient(wagmiClient, chains);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <ErrorBoundary>
//     <Toaster />
//     <WagmiConfig client={wagmiClient}>
//       <App />
//     </WagmiConfig>

//     <Web3Modal
//       projectId={projectId}
//       ethereumClient={ethereumClient}
//       themeVariables={{
//         "--w3m-font-family": "Roboto, sans-serif",
//         "--w3m-accent-color": "#FE73A5",
//       }}
//     />
//     </ErrorBoundary>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  mainnet,
  polygon,
  polygonMumbai,
  goerli,
  bsc,
  sepolia,
  bscTestnet,
  baseGoerli,
  base,
  avalanche,
  avalancheFuji,
} from "@wagmi/chains";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

import LaunchDrops from "./pages/launchDrops";
import { Toaster } from "react-hot-toast";

const amoy = {
  id: 80002,
  name: "Amoy",
  network: "amoy",
  nativeCurrency: {
    name: "Matic",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: {
    alchemy: {
      http: ["https://rpc-amoy.polygon.technology/"],
    },
    infura: {
      http: ["https://rpc-amoy.polygon.technology/"],
    },
    default: {
      http: ["https://rpc-amoy.polygon.technology/"],
    },
    public: {
      http: ["https://rpc-amoy.polygon.technology/"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Amoyscan",
      url: "https://amoy.polygonscan.com/",
    },
    default: {
      name: "Amoyscan",
      url: "https://amoy.polygonscan.com/",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 7654707,
    },
  },
};

const chains = [
  amoy,
  polygon,
  mainnet,
  polygonMumbai,
  goerli,
  sepolia,
  bscTestnet,
  bsc,
  baseGoerli,
  base,
  avalanche,
  avalancheFuji,
];
// const chains = [polygon, mainnet];
// const chains = [pol,ygonMumbai, goerli];
const projectId = "66333139f2c75fcc7f2d02e162f079e6";

Bugsnag.start({
  apiKey: "452a8b0a53ecdbcd5141751730662955",
  plugins: [new BugsnagPluginReact()],
});

// BugsnagPerformance.start({ apiKey: '452a8b0a53ecdbcd5141751730662955' })

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const ethereumClient = new EthereumClient(wagmiClient, chains);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Toaster />
      <WagmiConfig client={wagmiClient}>
        <App />
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-font-family": "Roboto, sans-serif",
          "--w3m-accent-color": "#FE73A5",
        }}
      />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
