import { BigNumber, ethers } from "ethers";
import DEPLOYMENT from "../data/deployment.json";
import { getDecimals } from "./erc20";

const domainType = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
];

const transactionType = [
  { name: "nonce", type: "uint256" },
  { name: "from", type: "address" },
];

export const generateSignature = async (provider, signer, userAddress) => {
  const { chainId } = await provider.getNetwork();
  console.log("signed : ", userAddress, chainId, DEPLOYMENT.deployed_address[chainId]);
  const contract = new ethers.Contract(
    DEPLOYMENT.deployed_address[chainId],
    DEPLOYMENT.abi,
    provider
    );
    console.log("contract instance created");
    
    let message = {};
    let nonce = await contract.nonces(userAddress) || 0;
    nonce = parseInt(nonce._hex);
    message.nonce = nonce;
    message.from = userAddress;
    console.log("contract nonce", nonce );
    
  const types = {
    MetaTransaction: transactionType,
  };

  let domainData = {
    name: "Komet",
    version: "1",
    chainId: chainId,
    verifyingContract: DEPLOYMENT.deployed_address[chainId],
  };

  const data = { types, domainData, message };


  const result = await signer._signTypedData(domainData, types, message);
  const signature = result.substring(2);
  const r = "0x" + signature.substring(0, 64);
  const s = "0x" + signature.substring(64, 128);
  const v = parseInt(signature.substring(128, 130), 16);

  return {
    r,
    s,
    v,
  };
};

export const getBatchingERC20Data = async (
  provider,
  signer,
  userAddress,
  contractAddress,
  totalAmount,
  senders
) => {
  const decimals = await getDecimals(provider, contractAddress);
  totalAmount = ethers.utils.parseUnits(totalAmount.toString(), decimals);

  const dataArray = [];
  for (let i in senders) {
    let num = ethers.utils.parseUnits(senders[i].amount.toString(), decimals);
    const data = [senders[i].address, num];
    dataArray.push(data);
  }

  const iface = new ethers.utils.Interface(DEPLOYMENT.abi);
  const encodedData = await iface.encodeFunctionData(
    "batchTransferERC20TokensSingle",
    [dataArray, totalAmount, contractAddress, decimals]
  );

  return encodedData;
};

export const getBatchingERC721Data = async (
  provider,
  signer,
  userAddress,
  contractAddress,
  senders
) => {
  let transferItems = [];
  for (let i in senders) {
    const transferItem = [
      userAddress,
      senders[i].address,
      contractAddress,
      senders[i].amount,
    ];
    transferItems.push(transferItem);
  }

  const iface = new ethers.utils.Interface(DEPLOYMENT.abi);
  const encodedData = await iface.encodeFunctionData(
    "batchTransferNFTForUser",
    [transferItems]
  );

  return encodedData;
};

export const getBatchingERC1155Data = async (
  provider,
  signer,
  userAddress,
  contractAddress,
  senders
) => {
  let transferItems = [];

  for (let i in senders) {
    let item = senders[i];

    let ids = item.amount.split(",");
    ids = ids.map((i) => parseInt(i));

    let amounts = item.erc1155Amount.split(",");
    amounts = amounts.map((amt) => parseInt(amt));

    const transferItem = [item.address, amounts, ids, "0x00"];
    transferItems.push(transferItem);
  }

  const iface = new ethers.utils.Interface(DEPLOYMENT.abi);
  const encodedData = await iface.encodeFunctionData("batchTransferERC1155", [
    transferItems,
    contractAddress,
  ]);

  return encodedData;
};

// const limit = await contractinit.estimateGas.fulfillBasicOrder_efficient_6GL6yc(
//     data.fulfillment_data?.transaction?.input_data?.parameters,
// )
// gasLimit = parseInt(limit?._hex, 16)
// const transfer = await contractinit.fulfillBasicOrder_efficient_6GL6yc(
//     data.fulfillment_data?.transaction?.input_data?.parameters,
//     {
//         gasPrice: price,
//         gasLimit: 200000,
//     },
// )
// console.log(transfer)

export const getBatchingNativeData = async (
  provider,
  signer,
  userAddress,
  totalAmount,
  senders
) => {
  const decimals = 18; //await getDecimals(provider, contractAddress);
  totalAmount = ethers.utils.parseUnits(totalAmount.toString(), decimals);

  const dataArray = [];
  for (let i in senders) {
    let num = ethers.utils.parseUnits(senders[i].amount.toString(), decimals);
    const data = [senders[i].address, num];
    dataArray.push(data);
  }

  const iface = new ethers.utils.Interface(DEPLOYMENT.abi);
  const encodedData = await iface.encodeFunctionData("transferNativeCurrency", [
    dataArray,
    totalAmount,
  ]);

  return { encodedData, totalAmount };
};

export const sendBatchingTransaction = async (
  provider,
  signer,
  userAddress,
  r,
  s,
  v,
  data,
  value
) => {
//   console.log(
//     "signature : ",
//     "r : " + r,
//     "s : " + s,
//     "v : " + v,
//     "data : ",
//     data,
//     "value : ",
//     value
//   );
  const { chainId } = await provider.getNetwork();
  const iface = new ethers.utils.Interface(DEPLOYMENT.abi);

  const encodedData = iface.encodeFunctionData("executeTx", [data, r, s, v]);
  const sentTx = await signer.sendTransaction({
    to: DEPLOYMENT.deployed_address[chainId],
    data: encodedData,
    value:
      value >= 100
        ? ethers.utils.parseEther(value?.toString())._hex
        : ethers.utils.parseEther(value?.toString()),
  });
//   console.log(DEPLOYMENT.deployed_address[chainId]);
  return sentTx;
};
