import {useEffect, useState} from "react";
import { fetchBrand, fetchDropsData } from "../api/fetchDropz";
import { useAccount } from "wagmi";
import { errorToast } from "../../utils/toast";
import Bugsnag from "@bugsnag/js";

const useDropsDashboardHooks  = () => {

    const [drops, setDrops] = useState([]);
    const [brand, setBrand] = useState();
    const {address} = useAccount();

    useEffect(() => {
        if (address) {
            getUserBradByWallet(address);
        }
    }, [address])

    const getUserBradByWallet = async (address) => {
        try {
            const result = await fetchBrand(address);
            if (result?.length > 0) {
                const userActiveDrops = await fetchDropsData(result[0]?.id, true);
                setBrand(result[0]);
                setDrops([...userActiveDrops]);
                if (userActiveDrops?.length <= 0) {
                    const userDeactiveDrops = await fetchDropsData(result[0]?.id, false);
                    setBrand(result[0]);
                    setDrops([...userActiveDrops, ...userDeactiveDrops]);
                }
            }

        } catch (err) {
            Bugsnag.notify(new Error(err));
            console.log(err)
            errorToast(err);
        }
    }

    return  {
        drops,
        brand,
        getUserBradByWallet
    }
}

export default useDropsDashboardHooks;