const WarringDialog = () => {
  return (
    <div className="w-[100%] h-[100%] absolute top-0 left-0 z-20 flex justify-center items-center floating-dialog backdrop-blur-lg showOrHide">
      <div className="w-[90%] bg-[#06040F] border-[#FD42A6] border-[1px] rounded-[6px] flex justiy-center items-center p-[40px]">
        <div className="w-[100%]">
          <p className="text-[white] text-center text-[20px] sfNormal">
            Please use desktop version for better experience.
          </p>

          <div className="w-[100%] flex justify-center items-center mt-[32px]">
            {/* <button
                  className="w-[100px] h-[40px] p-1 button-friend-tech"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  OK
                </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarringDialog;
