import { Web3Button, useWeb3Modal } from "@web3modal/react";
import { useEffect, useRef, useState } from "react";
import DropZoneLogo from "../../assets/DropZone.svg";
import DropzLogo from "../../assets/dropz_icon.svg";
import FriendTectLogo from "../../assets/friendtech_logo.svg";
import { useNavigate } from "react-router-dom";
import NetworkDropDrown from "../../components/bundle/_components/networkDropDrown";
import useScrollLock from "../../components/bundle/_components/useScrollLock";
import { useAccount, useDisconnect } from "wagmi";
import CustomWalletConnect from "../../components/bundle/_components/CustomWalletConnect";

const Header = () => {
  const [refresh, setRefresh] = useState(false);
  const [openNavigationBar, setNavigationBar] = useState(false);
  const [notify, setNotify] = useState(false);
  const { isConnected } = useAccount();
  const openRef = useRef();
  const { isLock, toggle } = useScrollLock();
  const { open } = useWeb3Modal();
  const {} = useDisconnect({
    onSuccess: () => {
      window.localStorage.setItem("wagmi.connected", false);
      setRefresh(!refresh);
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (notify) {
      openRef?.current?.classList?.remove("slider-right");
      openRef?.current?.classList?.add("slider-left");
    } else {
      openRef?.current?.classList?.remove("slider-left");
      openRef?.current?.classList?.add("slider-right");
    }
  }, [notify, refresh]);

  const isWalletConnected = () => {
    const isWalletConnected =
      window.localStorage.getItem("wagmi.connected") || isConnected || false;
    return isWalletConnected;
  };

  return (
    <>
      <nav className="w-[100%] border-gray-200 py-2.5 flex justify-center items-center">
        <div className="w-[90%] flex flex items-center justify-between rounded-full header-background">
          <div className="flex">
            <a href="/" className="flex items-center">
              <img
                src={DropZoneLogo}
                className="w-[120px] h-[60px] header-image"
                alt="Flowbite Logo"
              />
            </a>

            <a href="https://friend.tech" className="flex items-center">
              <img
                src={FriendTectLogo}
                className="w-[120px] h-[60px] ml-[24px]"
                alt="Flowbite Logo"
              />
            </a>
          </div>
          <div className="flex items-center md:order-2">
            <NetworkDropDrown className="ml-[16px]" />
            {isWalletConnected() ? (
              <div
                className="w-[156px] h-[32px] bg-[#1B1833] flex justify-center items-center p-[7px] cursor-pointer mx-[16px]"
                onClick={() => {
                  navigate("/dropz");
                  // navigate("/mydropz");
                }}
              >
                <button className="w-[156px] h-[33px] flex justify-center items-center bg-[#1B1833]">
                  <img
                    src={DropzLogo}
                    className="w-[14px] h-[14px] header-image"
                    alt="Flowbite Logo"
                  />
                  <p className="text-[white] ml-[12px] sfNormal text-[12px] font-bold">
                    LAUNCH DROPZ
                  </p>
                </button>
              </div>
            ) : null}
            {/* <Web3Button /> */}

            <CustomWalletConnect openAccountModal={true} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
