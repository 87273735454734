import { useEffect, useState } from "react";
import { SocialUserData, viewUserData } from "../api/fetchDropz";
import CsvDownloadButton from "react-json-to-csv";
import { TbNotesOff } from "react-icons/tb";
import Bugsnag from "@bugsnag/js";

const ViewData = ({ dropId, dropName, setOpen }) => {

  const [claimData, setClaimData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);


  useEffect(() => {
    getData(dropId);
  }, []);

  const getData = async (dropId) => {
    try {
      setIsFetching(true);
      const data = await viewUserData(dropId);
      const social = await SocialUserData();

      const viewdata = data.map((value) => {
        return {
          dropId: value?.dropId,
          userId: value?.userId,
          walletAddress: value?.walletAddress,
          twitter: getTwitterIds(value?.userId?.toString(), social)
            ?.socialUsername,
          remarks: value?.remarks,
        };
      });
      setClaimData(viewdata);
      setIsFetching(false);
    } catch (err) {
      Bugsnag.notify(new Error(err));
      setIsFetching(false);
      console.log(err);
    }
  };

  const getTwitterIds = (userId, social) => {
    let twitter = "";
    if (social[parseInt(userId)]) {
      social[parseInt(userId)].forEach((value) => {
        console.log(value);
        if (value?.socialName == "TWITTER") {
          twitter = value;
        }
      });
    }
    return twitter;
  };

  return (
    <div className="w-[100%] h-[100%] absolute top-0 left-0 flex justify-center items-center floating-dialog backdrop-blur-md">
      <div className="w-[80%] h-max-[520px] rounded-[6px] bg-[black] p-[24px]">
        <p className="sfNormal text-[18px] text-[white] font-bold">
          Claim Users
        </p>

        <div className="w-[100%] mt-[25px] flex justify-center items-center">
          <table className="w-[95%] flex justify-center items-center transaction-outter-box-width">
            <tr className="w-[100%] flex justify-between items-center">
              <td className="w-[25%] text-start final-address">sno.</td>
              <td className="w-[25%] text-start final-address">DopId</td>
              <td className="w-[25%] text-start final-address">
                Wallet Address
              </td>
              <td className="w-[25%] text-center final-address">Twitter</td>
              {dropId === 22 ? (
                <>
                  <td className="w-[25%] text-center final-address">
                    Bharatbox username
                  </td>
                  <td className="w-[25%] text-center final-address">
                    Sandbox Profile link
                  </td>
                  <td className="w-[25%] text-center final-address">
                    Wallet Address
                  </td>
                </>
              ) : null}
            </tr>

            {claimData?.length > 0 ? (
              <tr className="w-[100%] h-[300px] overflow-y-scroll">
                {claimData.map((data, index) => {

                  const array = data?.remarks?.split(",");
                  return (
                    <tr className="w-[100%] flex justify-between items-center selectedAddress ">
                      <td className="w-[25%] header-text text-[white] text-start ">
                        {index + 1 + ". "}
                      </td>
                      <td className="w-[25%] header-text text-[white] text-start ">
                        {data?.dropId}
                      </td>
                      <td className="w-[25%] header-text text-[white] text-start">
                        <p className="w-[100%] header-text text-[white] text-start overflow-hidden">
                          {data?.walletAddress?.substring(0, 8) + "..."}
                          {data?.walletAddress?.substring(
                            data?.walletAddress?.length - 8,
                            data?.walletAddress?.length
                          )}
                        </p>
                      </td>
                      <td className="w-[25%] header-text text-[white] text-center">
                        {data?.twitter || "N/A"}
                      </td>
                      {dropId === 22 ? (
                        <>
                        <td className="w-[25%] header-text text-[white] text-center">
                          {array[0] || "N/A"}
                        </td>
                        <td className="w-[25%] header-text text-[white] text-center">
                          {array[1] || "N/A"}
                        </td>
                        <td className="w-[25%] header-text text-[white] text-center">
                          {array[2] || "N/A"}
                        </td>
                        </>
                      ) : null}
                    </tr>
                  );
                })}
              </tr>
            ) : (
              <tr className="w-[100%] h-[100%] flex justify-center items-center">
                <div className="p-[100px]">

                {isFetching ? (
                  <div className="p-[100px]">
                    <p className="text-[gray] text-[12px] text-center">Fetching please wait...</p>
                  </div>
                ) : (
                  <div className="p-[100px]">
                    <TbNotesOff className="w-[80px] h-[80px]" color="gray" />
                    <p className="text-[gray] text-[12px] text-center">No Data Found</p>
                  </div>
                )}
                  {/* <TbNotesOff className="w-[80px] h-[80px]" color="gray" />
                  <p className="text-[gray] text-[12px] text-center">
                    No Data Found
                  </p> */}
                </div>
              </tr>
            )}
          </table>
        </div>

        <div className="mt-[10px] flex justify-end">
          <button
            className="mt-1 h-[33px] w-[120px] rounded-[4px] mr-[10px]"
            style={{
              borderColor: "gray",
              borderWidth: "1px",
            }}
            onClick={() => {
              setOpen();
            }}
          >
            <p className="text-[14px] text-[white] semiBoldFont mx-2">Close</p>
          </button>
          <div
            className="mt-1 h-[33px] w-[120px] rounded-[4px]"
            style={{
              background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
            }}
          >
            <p className="text-[14px] text-[white] semiBoldFont mx-2 h-[100%] flex justify-center items-center">
              <CsvDownloadButton
                data={claimData}
                filename={dropName || "userData"}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewData;
