import axios from "axios";

const BASEURL = "https://prod-api.komet.me/";

export const fetchBrand = async (address) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      BASEURL + "dropzBrand/fetch/v1/wallet?walletAddress=" +
      address,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const result = await axios.request(config);

  return result?.data;
};

export const viewUserData = async (dropId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      BASEURL + "drop/claimed/all/v1?dropId=" + dropId,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const result = await axios.request(config);

  return result?.data;
};

export const SocialUserData = async (dropId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      BASEURL + "user/fetchAllSocialIndex/v1",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const result = await axios.request(config);

  return result?.data;
};

export const fetchDropsData = async (brandId, active) => {
   
let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: BASEURL + `drop/admin/v1?brandId=${brandId}`,
    headers: { 
      'Content-Type': 'application/json'
    },
  };
  
  const result = await axios.request(config);
  return result?.data;
}

export const fetchDropsActivities = async (brandId) => {
   
let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: BASEURL + 'dropzBrand/fetch/activity/v1?dropId=' + brandId,
    headers: { 
      'Content-Type': 'application/json'
    },
  };
  
  const result = await axios.request(config);
  return result?.data;
}