import React from "react";
import Dropz from "../dropz";

function LaunchDrops() {
  return (
    <div className="w-[100%]">
      <Dropz />
    </div>
  );
}

export default LaunchDrops;
