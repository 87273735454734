import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import {
  getFriendTechHolders,
  getFriendTechUsersHolders,
  getFrientectAccountDetails,
} from "../../utils/friendtech";
import { errorToast } from "../../utils/toast";
import useDropzoneHooks from "../../components/bundle/useDropzoneHook";
import { getNativeBalance } from "../../utils/erc20";
import { useAccount, useProvider } from "wagmi";
import TrashIcon from "../../assets/trash.svg";
import ProgressBarSuccess from "../../assets/progress_success.svg";
import ProgressBarFailed from "../../assets/progress_failed.svg";
import { RotatingLines } from "react-loader-spinner";
import { MdOutlineDoneOutline } from "react-icons/md";
import Bugsnag from "@bugsnag/js";

const Main = ({ steps, setSteps }) => {
  const options = ["Per Holder", "Per Key"];
  const [selectedOptions, setSelctedOptions] = useState(0);
  const [input, setInput] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [data, setData] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState([]);
  const [Holders, setHolders] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [nativeBalance, setNativeBalance] = useState(0);
  const provider = useProvider();
  const wallet = useAccount();

  const {
    AddSender,
    setSenders,
    setRadioButtonState,
    getTotalAmount,
    requestApproval,
    isPending,
    isTransactionStart,
    senders,
    contractInfo,
    radioButtonState,
  } = useDropzoneHooks({ setSteps, steps });

  useEffect(() => {
    setRadioButtonState(2);
    getFriendTechAccountInfo();
    getBalance();
  }, []);

  const getFriendTechAccountInfo = async () => {
    try {
      // const testaddress = "0x45c1eb5b30090bd284d7f55d74bea70bbefa593d"
      const result = await getFrientectAccountDetails(wallet.address);
      if (result?.holderCount > 0) {
        // getHolders();
        const data = await getFriendTechUsersHolders(wallet.address);
        setHolders(data?.paginationData);
        setData(data?.data);
      } else {
        errorToast("we are unable to find hodlers data");
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      errorToast("we are unable to find your account on friend.tech");
    }
  };

  const getBalance = async () => {
    const balance = await getNativeBalance(provider, wallet.address);
    setNativeBalance(balance);
  };

  const getHolders = async () => {
    try {
      const result = await getFriendTechHolders();
      setHolders(result?.paginationData);
      setData(result?.data?.data?.trades);
    } catch (err) {
      Bugsnag.notify(new Error(err));
      console.log(err);
      errorToast(err?.message);
    }
  };

  const handleSelcetedAddress = (address, amount, erc1155, id) => {
    setSelectedAddress((state) => [...state, { address, amount, erc1155, id }]);
    setSelectedAddressId((state) => [...state, id]);
  };

  const handleSelcetedAllAddress = () => {
    try {
      const array = [];
      const ids = [];
      data?.map((item) => {
        const token = parseFloat(
          selectedOptions === 1 ? input * item?.balance : input
        );
        array.push({ address: item?.address, amount: token, erc1155: 0 });
        ids.push(item?.id);
      });
      setSelectedAddress((state) => [...array]);
      setSelectedAddressId((state) => [...ids]);
      setSenders(() => [...array]);
    } catch (err) {
      Bugsnag.notify(new Error(err));
      errorToast("Unexpected error");
    }
  };

  const handleRemoveAddress = (index) => {
    const a = selectedAddress;
    const ids = selectedAddress;
    a.splice(index, 1);
    ids.splice(index, 1);
    setSelectedAddress((state) => [...a]);
    setSenders(a);
    setSelectedAddressId(ids);
  };

  function findIndex(arr, element) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === element) {
        return i;
      }
    }
    return -1;
  }

  const handleCheckboxRemoveState = (id) => {
    const index = findIndex(selectedAddressId, id);
    const data = selectedAddress;
    const ids = selectedAddressId;
    data.splice(index, 1);
    ids.splice(index, 1);
    setSelectedAddress((state) => [...data]);
    setSelectedAddressId(ids);
    setSenders(data);
  };

  // auto controls

  useEffect(() => {
    if (selectedAddress.length <= 0) {
      setSteps(-1);
    }
  }, [selectedAddress]);

  return (
    <div className="w-[100%] flex justify-center items-center mt-[76px]">
      <div className="w-[845px]  container-main mb-[100px]">
        {steps == -1 ? (
          <>
            <div className="w-[100%] flex justify-start items-center mt-[20px]">
              {options.map((value, key) => {
                return (
                  <button
                    key={key}
                    onClick={() => {
                      setSelctedOptions(key);
                    }}
                    className={`${
                      selectedOptions == key
                        ? "button-friend-tech-selected"
                        : "button-friend-tech"
                    }`}
                  >
                    {value}
                  </button>
                );
              })}
            </div>

            <div className="w-[446px] flex justify-center items-center outer-box mt-[48px]">
              <input
                type="number"
                className="w-[100%] bg-transparent border-rm text-[white]"
                name="amount"
                placeholder="0.00 ETH"
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
              {/* <button className="transaction-send-button">Send</button> */}
            </div>

            <p className="text-green font-400 mt-[8px]">
              You drop is{" "}
              <span className="text-green font-bold">{input} ETH</span>
            </p>
          </>
        ) : null}

        {steps == 0 ? (
          <div className="transaction-outter-box mt-[48px]">
            <div className="w-[100%] flex justify-between items-center">
              <div className="flex justify-start items-center px-[8px]">
                <p className="final-address"> # Address</p>
              </div>

              <div className="flex jsutify-between items-center">
                <p className="final-address"> Token</p>
              </div>
            </div>

            <div className="w-[100%] flex justify-center items-center my-[18px]">
              <div className="w-[100%] border-[#9E9EA2] border-t-[1px]"></div>
            </div>

            <div className="w-[100%]">
              {selectedAddress.map((value, index) => (
                <div
                  key={index}
                  className="w-[100%] flex justify-between items-center mb-[12px] selectedAddress"
                >
                  <div className="flex justify-start items-center px-[8px]">
                    <p className="header-text text-[white]">
                      {index + 1}. {value.address}
                    </p>
                  </div>

                  <div className="flex jsutify-between items-center">
                    <p className="header-text text-[white]">
                      {" "}
                      {parseFloat(value.amount).toFixed(6)}
                    </p>
                    <img
                      className="w-[20px] h-[20px] ml-[8px]"
                      src={TrashIcon}
                      alt=""
                      onClick={() => {
                        handleRemoveAddress(index);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="w-[100%] flex justify-between items-center">
              <p className="final-address mt-[47px]">
                Total no. of addresses : {selectedAddress.length}
              </p>
              <p className="final-address mt-[47px]">
                Total no. of Amount : {getTotalAmount(selectedAddress)}
              </p>
            </div>
          </div>
        ) : null}

        {steps == -1 ? (
          <div className="transaction-outter-box mt-[48px]">
            <div className="w-[100%] flex justify-between items-center">
              <div className="flex justify-start items-center px-[8px]">
                <input
                  className="w-[16px] h-[16px] mr-[12px]"
                  type="checkbox"
                  checked={isSelectedAll}
                  onClick={() => {
                    if (!isSelectedAll) {
                      setIsSelectedAll(!isSelectedAll);
                      handleSelcetedAllAddress();
                    } else {
                      setIsSelectedAll(!isSelectedAll);
                      setSenders([]);
                      setSelectedAddress([]);
                      setSelectedAddressId([]);
                    }
                  }}
                />
                <p className="header-text text-[#9E9EA2]">
                  {" "}
                  Key Holder ({Holders?.length})
                </p>
              </div>

              <div className="flex jsutify-between items-center">
                <p className="header-text mr-[85px] text-[#9E9EA2]"> Keys</p>
                <p className="header-text text-[#9E9EA2]"> Values</p>
              </div>
            </div>

            <div className="w-[100%] flex justify-center items-center my-[18px]">
              <div className="w-[100%] border-[#9E9EA2] border-t-[1px]"></div>
            </div>

            <PaginationList
              Holders={Holders}
              input={input}
              handleSelcetedAddress={handleSelcetedAddress}
              isSelectedAll={isSelectedAll}
              selectedOptions={selectedOptions}
              selectedAddress={selectedAddress}
              AddSender={AddSender}
              selectedAddressId={selectedAddressId}
              handleRemoveAddress={handleRemoveAddress}
              handleCheckboxRemoveState={handleCheckboxRemoveState}
            />
          </div>
        ) : null}

        {steps == 1 ? <ProgressBar isPending={isPending} /> : null}

        <div className="w-[100%] flex justify-center items-center mt-[64px]">
          <div>

            <div
              className={`approve-button font-bold cursor-pointer relative ${
                !isTransactionStart && input > 0 ? "opacity-100" : "opacity-50"
              } p-[5px] mt-[30px] flex justify-center items-center`}
              aria-disabled={isTransactionStart ? true : false}
              onClick={() => {
                if (steps < 0) {
                  if (selectedAddress.length > 0 && input > 0) {
                    setSteps(0);
                  } else {
                    if (selectedAddress.length <= 0) {
                      errorToast("please select at least one address");
                    } else {
                      errorToast("Amount should be the greater then 0");
                    }
                  }
                } else if (steps == 0) {
                  if (
                    getTotalAmount(selectedAddress) < nativeBalance?.balance &&
                    senders?.length < 280
                  ) {
                    requestApproval(selectedAddress);
                  } else {
                    errorToast(
                      senders?.length < 280
                        ? "Insufficient Funds for the transaction"
                        : "Maximum item size is 280"
                    );
                  }
                }
              }}
            >
              {isTransactionStart ? (
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={isTransactionStart}
                />
              ) : null}
              <p className="ml-2 text-center">
                {!isTransactionStart
                  ? "Continue"
                  : "Approve Transactions In your Wallet"}
              </p>
            </div>

            <div className="w-[100%] flex justify-center items-center">
              <div className="w-[263px] mt-[12px] audit-contract-outter">
                <FaLock color="#4CCBA1" />
                <p>
                  The smart contracts are audited with{" "}
                  <span className="span-tilt">Unsnarl.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;

const CheckboxRow = ({
  input,
  value,
  isSelectedAll,
  selectedOptions,
  selectedAddress,
  handleSelcetedAddress,
  selectedAddressId,
  AddSender,
  handleCheckboxRemoveState,
  index,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div
      className="w-[100%] flex justify-between items-center mb-[12px] px-[8px] py-[6px] selectedAddress"
      onClick={() => {
        if (!isChecked) {
          const token = parseFloat(
            selectedOptions === 1 ? input * value?.balance : input
          );
          handleSelcetedAddress(value?.address, token, 0, value?.id);
          AddSender({
            address: value?.address,
            amount: token,
            erc1155Balance: 0,
          });
          setIsChecked(true);
        } else {
          handleCheckboxRemoveState(value.id);
          setIsChecked(false);
        }
      }}
    >
      <div className="w-[100%] flex justify-between ietms-center ">
        <div className="flex justify-start items-center px-[8px]">
          {isSelectedAll ? (
            <input
              className="w-[16px] h-[16px] mr-[12px]"
              type="checkbox"
              checked={isSelectedAll}
            />
          ) : (
            <input
              className="w-[16px] h-[16px] mr-[12px]"
              type="checkbox"
              checked={selectedAddressId.includes(value?.id)}
            />
          )}
          <p className="header-text text-[white]"> {value?.address}</p>
        </div>

        <div className="w-[42%] flex justify-end items-center">
          {/* <p className="header-text mr-[75px] display-username text-[white]">
          {" "}
          {value?.username || "@Surya"}
        </p> */}
          <p className="header-text mr-[85px] text-[white]">
            {" "}
            {value?.balance}
          </p>
          <p className="header-text text-[white]">
            {" "}
            {parseFloat(
              selectedOptions === 1 ? input * value?.balance : input
            ).toFixed(6)}
          </p>
        </div>
      </div>
    </div>
  );
};

const PaginationList = ({
  Holders,
  input,
  handleSelcetedAddress,
  handleRemoveAddress,
  isSelectedAll,
  selectedOptions,
  selectedAddress,
  handleCheckboxRemoveState,
  selectedAddressId,
  AddSender,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  return (
    <div className="w-[100%]">
      <div className="w-[100%]">
        {Holders[pageIndex]?.map((value, index) => {
          return (
            <CheckboxRow
              input={input}
              value={value}
              index={index}
              selectedOptions={selectedOptions}
              isSelectedAll={isSelectedAll}
              handleSelcetedAddress={handleSelcetedAddress}
              selectedAddress={selectedAddress}
              AddSender={AddSender}
              handleRemoveAddress={handleRemoveAddress}
              handleCheckboxRemoveState={handleCheckboxRemoveState}
              selectedAddressId={selectedAddressId}
            />
          );
        })}

        <div className="w-[100%]">
          <nav className="w-[100%] mt-[45px]">
            <ul className="w-[100%] flex text-sm justify-between">
              <li>
                <a
                  className={`w-[120px] flex items-center justify-center px-3 h-8 leading-tight text-[#FE508F] bg-transparent border sfNormal border-[#FE508F] rounded-[6px] hover:bg-[#FE508F] hover:text-white cursor-pointer ${
                    pageIndex <= 0 ? "opacity-30" : "opacity-100"
                  }`}
                  aria-disabled={pageIndex <= 0 ? true : false}
                  onClick={() => {
                    if (pageIndex > 0) {
                      const pre = pageIndex - 1;
                      setPageIndex(pre);
                    }
                  }}
                >
                  Previous
                </a>
              </li>
              <li>
                <a
                  className={`w-[120px] flex items-center justify-center px-3 h-8 leading-tight text-[#FE508F] bg-transparent border sfNormal border-[#FE508F] rounded-[6px] hover:bg-[#FE508F] hover:text-white cursor-pointer ${
                    pageIndex >= Holders?.length - 1
                      ? "opacity-30"
                      : "opacity-100"
                  }`}
                  aria-disabled={
                    pageIndex >= Holders?.length - 1 ? true : false
                  }
                  onClick={() => {
                    if (pageIndex < Holders?.length - 1) {
                      const next = pageIndex + 1;
                      setPageIndex(next);
                    }
                  }}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ isPending }) => {
  return (
    <div className="w-[100%] flex justify-center items-center">
      <div className="w-[710px] h-[622px]">
        <div className=" flex justify-center items-center">
          <div className="flex">
            {isPending ? (
              <RotatingLines
                strokeColor="#FE73A5"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={isPending}
              />
            ) : (
              <MdOutlineDoneOutline className="w-[20px] h-[20px] text-[green]" />
            )}
            <p className="text-[white] font-bold text-[white] ml-2">
              {isPending ? "pending..." : "Completed"}
            </p>
          </div>
        </div>

        {!isPending ? (
          <img className="w-[100%] h-[100%]" src={ProgressBarSuccess} alt="" />
        ) : (
          <img className="w-[100%] h-[100%]" src={ProgressBarFailed} alt="" />
        )}
      </div>
    </div>
  );
};
