import React, { useState } from "react";


import HeaderDropz from "../_components/Header";
import '../../frendtech/style/index.css'

import { useAccount } from "wagmi";
import DropList from "./DropList";
import { getData } from "../_utils";
import DropzFooter from "./DropzFooter";
import UserModal from "./UserModal";

const MyDropz = () => {
    const {address} = useAccount();
    const [dropsData,setDropsData]=useState([])
    const [selectedDrop,setSelectedDrop]=useState([])
    

    React.useEffect(()=>{
        const getDrops=async()=>{
            const data=getData(address,setDropsData);
           setSelectedDrop(data?.[0])
        }
        getDrops();
    },[address])

  return (
    <div className="w-[100%] relative h-screen  ">
        <HeaderDropz />
        
        <div className="w-[100%] flex flex-col  items-center   overflow-x-hidden ">
    <div className="self-center justify-center w-[100%] self-center dropBackground  flex borderBottom  flex-row pt-[156px] ">
     <DropList dropsData={selectedDrop||dropsData[0]} dropList={dropsData} setSelectedDrop={setSelectedDrop}
     />
    
    </div>
    
 </div>
 <DropzFooter />
 </div>
    
  );
};

export default MyDropz;
