const TweetRow = (props) => {
  return (
    <div className="w-[368px] relative flex flex-col items-center bg-[#02000F] border-[#536471] rounded-[12px] border-[1px] px-[24px] py-[24px] cursor-pointer">
      <div className="w-[100%] flex justify-between items-center">
        <div className="flex">
          <img
            className="w-[46px] h-[46px] mr-[16px] rounded-full"
            src={
              props?.profileUrl ||
              "https://media.istockphoto.com/id/636379014/photo/hands-forming-a-heart-shape-with-sunset-silhouette.jpg?s=612x612&w=0&k=20&c=CgjWWGEasjgwia2VT7ufXa10azba2HXmUDe96wZG8F0="
            }
            alt=""
          />

          <div className="">
            <p className="text-[14px] sfNormal text-[#D9D9D9] text-start">
              {props?.name}
            </p>
            <div className="flex justify-center items-center">
              <p className="text-[#536471] text-[14] sfNormal">
                {props?.username}
              </p>
              <p className="text-[14px] sfNormal text-[#006FD6] ml-[8px]">
                Follow
              </p>
            </div>
          </div>
        </div>

        <img
          className="w-[46px] h-[46px] mr-[16px] rounded-full"
          src={require("../assets/x.jpg")}
          alt=""
        />
      </div>
      <div className="w-[100%] mt-[24px] cursor-pointer">
        {props?.tweets?.split("\n").map((item, index) => (
          <p className="text-[14px] sfNormal text-[#D9D9D9] text-left ">
            {item}
          </p>
        ))}
      </div>

      <p className="w-[100%] mt-[24px] text-[#536471] text-[14] sfNormal text-left">
        {props?.time || "6 : 54PM Dec 24, 2021"}
      </p>

      <div
        className="w-[100%] h-[100%] absolute top-0 left-0 cursor-pointer"
        onClick={() => {
          window?.open(props?.url);
        }}
      ></div>
    </div>
  );
};

export default TweetRow;
