import { useEffect, useState } from "react";
import { fetchDropsActivities } from "../api/fetchDropz";
import {
  updateActivities,
  updateActivitiesV2,
  updateDropId,
} from "../api/SubmitForm";
import { errorToast, successToast } from "../../utils/toast";
import Bugsnag from "@bugsnag/js";

const UpdateRafflesActivities = ({ setOpen, passcode, dropId }) => {
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(passcode || false);

  const [activities, setActivities] = useState([]);
  const [likeLink, setLikeLink] = useState("");
  const [retweetLink, setRetweetLink] = useState("");
  const [holdInfo1, setHoldInfo1] = useState({});
  const [holdInfo2, setHoldInfo2] = useState({});
  const [updatedActivities, setUpdatedActivities] = useState();

  const handleInputs = (text) => {
    setUpdatedActivities({ ...updatedActivities, ...text });
  };

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    const state = await getActivies();
    // setActivities(state);
  };

  const getActivies = async () => {
    try {
      const data = await fetchDropsActivities(dropId);
      setActivities(data);

      let activity = [];
      const activitiesByDrops = data.map((data) => {
        switch (data?.type) {
          case "FOLLOW":
            setStep1(true);
            activity.push({
              follow: { type: data?.type, url: data?.tweetUrl, id: data?.id },
            });
            break;
          case "RETWEET":
            setRetweetLink(data?.tweetUrl);
            setHoldInfo1(data);
            break;
          case "LIKE":
            setLikeLink(data?.tweetUrl);
            setHoldInfo2(data);
            break;
        }
        return { type: data?.type, url: data?.tweetUrl, id: data?.id };
      });

      // setActivities((state => {state = activity}));
      return activities;
    } catch (err) {
      Bugsnag.notify(new Error(err));
      console.log(err);
      errorToast(err);
    }
  };

  const newData = async () => {
    try {
      if (dropId) {
        // const array = Object.values(updatedActivities).filter(
        //   (data) => data !== ""
        // );
        // console.log("update :", array);
        // createActivitesForTwitter(array, 0, dropId);

        const obj = { like: likeLink, retweet: retweetLink };
        const activityData = await createActivies(obj);
        const data = await updateActivitiesV2(activityData);

        if (data) {
          successToast("Activites updated");
        }
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      console.log(err);
      setOpen(false);
      errorToast(err);
    }
  };

  // id: info?.id,
  //   dropId: info?.dropId,
  //   payload: info?.payload,
  //   points: 10,
  //   tweetUrl: info?.tweetUrl,
  //   type: info?.type,

  const createActivies = (value) => {
    let finalArray = [];
    const key = Object.keys(value);
    key.forEach(async (element) => {
      if (element == "retweet" && value[element] !== "") {
        const username = await getTwitterId(value[element]);
        const a = {
          id: holdInfo1?.id,
          payload: username,
          tweetUrl: value[element],
          dropId: holdInfo1?.dropId,
          points: 10,
          type: "RETWEET",
        };
        finalArray.push(a);
      } else if (element == "like" && value[element] !== "") {
        const username = await getTwitterId(value[element]);
        const b = {
          id: holdInfo2?.id,
          payload: username,
          tweetUrl: value[element],
          points: 10,
          dropId: holdInfo2?.dropId,
          type: "LIKE",
        };
        finalArray.push(b);
      }
    });
    return finalArray;
  };

  const createActivitesForTwitter = async (array, index, dropId) => {
    try {
      if (index < array?.length) {
        if (
          updatedActivities?.follow !== "" &&
          updatedActivities?.follow == array[index]
        ) {
          const username = await getTwitterUsername(array[index]);
          const info = {
            id: activities?.follow?.id,
            payload: username,
            tweetUrl: array[index],
            dropId: dropId,
            type: "FOLLOW",
          };
          const activity = await updateActivities(info);
        } else if (
          updatedActivities?.retweet !== "" &&
          updatedActivities?.retweet !== array[index]
        ) {
          const username = await getTwitterId(array[index]);
          const info = {
            id: activities?.retweet?.id,
            payload: username,
            tweetUrl: array[index],
            dropId: dropId,
            type: "RETWEET",
          };
          const activity = await updateActivities(info);
        } else if (
          updatedActivities?.like !== "" &&
          updatedActivities?.like == array[index]
        ) {
          const username = await getTwitterId(array[index]);
          const info = {
            id: activities?.like?.id,
            payload: username,
            tweetUrl: array[index],
            dropId: dropId,
            type: "LIKE",
          };
          const activity = await updateActivities(info);
        }

        let newIndex = index + 1;
        createActivitesForTwitter(array, newIndex, dropId);
        // setLoading(false);
      } else {
        const brand = JSON.parse(localStorage.getItem("brand")) || false;
        successToast("Activites Updated");
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      setOpen(false);
      errorToast(err);
    }
  };

  const getTwitterUsername = (url) => {
    return url?.replace("https://twitter.com/", "");
  };

  const getTwitterId = (url) => {
    // return (url?.substring(url?.toString()?.lastIndexOf("/") + 1))?.toString()?.replace("?s=20","");
    return url?.toString()?.substring(url?.toString()?.lastIndexOf("/") + 1, url?.toString()?.lastIndexOf("?"));
  };

  return (
    <div className="w-[100%] regularFont  px-1 mb-[15px]">
      <div className="">
        <ul className="text-[white]">
          {activities?.map((element) => {
            return (
              <>
                {element?.type == "LIKE" ? (
                  <>
                    <label className="ml-[10px]"> Enter Like</label>
                    <input
                      required
                      className="w-[97%] regularFont h-[44px] bg-[white] text-[white] rounded-[7px] my-2 p-2"
                      style={{
                        outline: "none",
                        outlineColor: "black",
                        color: "black",
                      }}
                      onChange={(e) => setLikeLink(e.target.value)}
                      //   onChange={(e) => setWalletAddress(e.target.value)}
                      value={likeLink}
                      placeholder={"passcode"}
                    />
                  </>
                ) : (
                  <>
                    <label className="ml-[10px]"> Enter Retweet</label>
                    <input
                      required
                      className="w-[97%] regularFont h-[44px] bg-[white] text-[white] rounded-[7px] my-2 p-2"
                      style={{
                        outline: "none",
                        outlineColor: "black",
                        color: "black",
                      }}
                      onChange={(e) => setRetweetLink(e.target.value)}
                      //   onChange={(e) => setWalletAddress(e.target.value)}
                      value={retweetLink}
                      placeholder={"passcode"}
                    />{" "}
                  </>
                )}
              </>
            );
          })}
        </ul>
      </div>

      <div className="w-[100%] flex justify-end items-center">
        <button
          className="mt-1 h-[33px] w-[120px] rounded-[4px] mt-[20px]"
          style={{
            background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
          }}
          onClick={() => {
            newData();
            setOpen(false);
          }}
        >
          <p className="text-[14px] text-[white] semiBoldFont mx-2">Update</p>
        </button>
        <button
          className="mt-1 h-[33px] w-[120px] rounded-[4px] mt-[20px] ml-[6px]"
          style={{
            borderColor: "gray",
            borderWidth: "1px",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <p className="text-[14px] text-[white] semiBoldFont mx-2">Close</p>
        </button>
      </div>
    </div>
  );
};

export default UpdateRafflesActivities;
