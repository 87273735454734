import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import LaunchPartnerForm from "./LaunchPartnerForm";
import ProgressBar from "./ProgressBar";
import Dashboard from "./Dashboard";
import { IoArrowBack } from "react-icons/io5";

export default function LaunchStep3({
  onSubmit,
  onUpdate,
  setChain,
  step,
  setStep,
  step3Data,
  setStep3Data,
  loading,
  isDashboard,
  isUpdate,
}) {
  const [rewardOne, setRewardOne] = useState(step3Data?.reward || "");
  const [rewardTwo, setRewardTwo] = useState(step3Data?.reward || "");
  const [contractAddress, setContractAddress] = useState(
    step3Data?.contractAddress || ""
  );
  const [selectedType, setSelectedType] = useState(
    step3Data?.influencers || "POAP"
  );
  const [MetadataLink, setMetadataLink] = useState("");

  useEffect(() => {
    setRewardOne(step3Data?.rewardOne);
    setRewardTwo(step3Data?.rewardTwo);
    setContractAddress(step3Data?.contractAddress);
    setSelectedType(step3Data?.influencers || "POAP");
    setMetadataLink(step3Data?.MetadataLink);
  }, [step3Data]);

  // useEffect(() => {
  //   console.log("isUpdate", isUpdate);
  // }, [isUpdate]);

  const labelStyle =
    "w-[100%] ml-2 self-start text-[white] my-2 text-[15px] semiBoldFont";
  return (
    <div className="w-[100%] h-screen flex  flex-col mt-[-60px]">
      <div className="flex-col  w-[100%] pt-[80px] lauch-bg flex ">
        <div className="flex flex-1 flex-col sm:flex-row border-[white] border-b w-[100%] min-h-[90%]">
          <div className="flex w-[100%] flex-col items-center justify-center border-[white] border-r">
            <p className="text-[white] text-[20px] regularFont"></p>

            <div className="flex flex-col w-[100%] px-6 my-4">
              <div className="w-[100%] h-[120px]">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    const isBrand = localStorage.getItem("brand");
                    setStep((step) => step - 1);
                    setStep3Data({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  }}
                >
                  <IoArrowBack
                    className="w-[25px] h-[25px] cursor-pointer"
                    color="white"
                  />
                  <p className="ml-[6px] sfNormal text-[20px] text-[white]">
                    Back
                  </p>
                </div>
              </div>

              <p className="text-[white] text-[20px] regularFont my-4 mt-4">
                Step {localStorage.getItem("brand") ? step - 1 : step} of{" "}
                {localStorage.getItem("brand") ? step - 1 : 3} : Dropz Reward
                details
              </p>
              {/* <p className="text-[white] text-[20px] regularFont my-4 mt-4">
                Step 3 of 3 : Dropz Reward details
              </p> */}
              <ProgressBar step={step} />
              <div className="my-4"></div>
              <div className="m-1 flex items-center">
                <AiOutlineCheck color={rewardOne ? "#4CCBA1" : "grey"} />
                <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                Rewards Details:
                </p>
              </div>
              
              {/* <div className="m-1 flex items-center">
                <AiOutlineCheck color={selectedType ? "#4CCBA1" : "grey"} />
                <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  What is the reward type?
                </p>
              </div> */}

              {/* <div className="m-1 flex items-center">
                <AiOutlineCheck color={contractAddress ? "#4CCBA1" : "grey"} />
                <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Contract Address:
                </p>
              </div> */}
              <div className="m-1 flex items-center">
                <AiOutlineCheck color={MetadataLink ? "#4CCBA1" : "grey"} />
                <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                Supply, Art, and Metadata Links:
                </p>
              </div>
            </div>
            <LaunchPartnerForm />
          </div>

          <div className="flex w-[100%] items-center justify-center sm:ml-10 ml-6 flex-col py-4">
            <form
              id="step3"
              className=" w-[100%] flex flex-col items-center justify-center"
              onSubmit={(e) => {
                e.preventDefault();
                setStep3Data({
                  rewardOne: rewardOne,
                  rewardTwo: rewardTwo,
                  rewardType: selectedType,
                  contractAddress: contractAddress,
                  MetadataLink: MetadataLink,
                });
                setTimeout(() => {
                  if (isUpdate) {
                    onUpdate({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  } else {
                    onSubmit({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  }
                }, 1000);
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label className={labelStyle}>Rewards Details</label>
              <div className="w-[85%] h-[50px] self-start regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  id="launchdate"
                  name="launchdate"
                  type="text"
                  value={rewardOne}
                  onChange={(e) => setRewardOne(e.target.value)}
                  placeholder={"Describe the rewards offered in your Dropz campaign."}
                  className="text-black grow items-center rounded-xl sm:w-[30%] px-[10px] lg:px-4 text-sm outline-none regularFont lg:text-lg h-10"
                  height="48"
                />
              </div>
              <div className="w-[85%] h-[50px] self-start regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  id="launchdate"
                  name="launchdate"
                  type="text"
                  value={rewardTwo}
                  onChange={(e) => setRewardTwo(e.target.value)}
                  placeholder={"Describe the rewards offered in your Dropz campaign."}
                  className="text-black grow items-center rounded-xl sm:w-[30%] px-[10px] lg:px-4 text-sm outline-none regularFont lg:text-lg h-10"
                  height="48"
                />
              </div>
              {/* <label className={labelStyle}>What is the reward type?</label> */}
              {/* <div className="w-[55%] self-start h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <select
                  value={selectedType}
                  required
                  onChange={(e) => {
                    //console.log(e.target.value);
                    setSelectedType(e.target.value);
                  }}
                  className="w-[80%] p-2 px-4 rounded-xl text-[black] regularFont"
                >
                  <option value="POAP">POAPs</option>
                  <option value="SBT">SBTs</option>
                  <option value="Other">Other</option>
                </select>
              </div> */}
              {/* <label className={labelStyle}>
                Contract Address (Optional)
              </label>
              <div className="w-[85%] regularFont self-start rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  placeholder="contract Address"
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[black]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  value={contractAddress}
                  onChange={(e) => {
                    // setDropzTitle(e.target.value);
                    setContractAddress(e.target.value);
                  }}
                />
              </div> */}
              <label className={labelStyle}>
                Supply, Art, and Metadata Links (Optional)
              </label>
              <div className="w-[85%]  regularFont self-start rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <textarea
                  type="text"
                  id="structure"
                  name="structure"
                  className="w-[97%] regularFont min-h-[110px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                    padding : 5,
                  }}
                  onChange={(e) => setMetadataLink(e.target.value)}
                  value={MetadataLink}
                  placeholder={`Required for "Claim" type campaigns. For smart contract deployment assistance, include these details: supply, artwork, and metadata of the NFT reward.`}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="h-[100px] items-center h-[48px] w-[98.5%] flex justify-end m-[10px]">
          <FaLock color="#4CCBA1" />
          <p className="text-[14px] text-[white] regularFont mt-1 mx-2">
            Secured by 256 End-to-end Encryption
          </p>
          <button
            onClick={() => {
              setStep((step) => step - 1);
              setStep3Data({
                rewardOne: rewardOne,
                rewardTwo: rewardTwo,
                rewardType: selectedType,
                contractAddress: contractAddress,
                MetadataLink: MetadataLink,
              });
            }}
            className="mt-1 mx-2"
          >
            <p className="text-[14px] text-[white] regularFont mx-2">Back</p>
          </button>
          {/* {loading ? (
            <div className="loader"></div>
          ) : (
            <>
              {isUpdate ? (
                <button
                  form="step3"
                  type="submit"
                  className="mt-1 h-[33px] w-[120px] rounded-[4px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
                  }}
                  onClick={() => {
                    setStep3Data({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  }}
                >
                  <p className="text-[14px] text-[white] regularFont mx-2">
                    Update
                  </p>
                </button>
              ) : (
                <button
                  form="step3"
                  type="submit"
                  className="mt-1 h-[33px] w-[120px] rounded-[4px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
                  }}
                  onClick={() => {
                    setStep3Data({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  }}
                >
                  <p className="text-[14px] text-[white] regularFont mx-2">
                    Submit
                  </p>
                </button>
              )}
            </>
          )} */}


          <>
              {isUpdate ? (
                <button
                  form="step3"
                  type="submit"
                  className="mt-1 h-[33px] w-[120px] rounded-[4px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
                  }}
                  onClick={() => {
                    setStep3Data({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  }}
                >
                  <p className="text-[14px] text-[white] regularFont mx-2">
                    Update
                  </p>
                </button>
              ) : (
                <button
                  form="step3"
                  type="submit"
                  className="mt-1 h-[33px] w-[120px] rounded-[4px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
                  }}
                  onClick={() => {
                    setStep3Data({
                      rewardOne: rewardOne,
                      rewardTwo: rewardTwo,
                      rewardType: selectedType,
                      contractAddress: contractAddress,
                      MetadataLink: MetadataLink,
                    });
                  }}
                >
                  <p className="text-[14px] text-[white] regularFont mx-2">
                    Submit
                  </p>
                </button>
              )}
            </>
        </div>
      </div>
    </div>
  );
}
