import axios from "axios";

const BASEURL = "https://prod-api.komet.me";

export const createBrandId = async (formData) => {
  let data = JSON.stringify({
    discordUrl: formData?.discordUrl,
    emailAddress: formData?.email,
    imageUrl: formData?.url,
    name: formData?.name,
    telegram: formData?.telegram,
    twitterUrl: formData?.twitterUrl,
    twitterUserId: formData?.twitterUrl,
    username: formData?.name,
    walletAddress: formData?.walletAddress,
    website: formData?.siteUrl,
  });


  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/dropzBrand/add/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const resposne = await axios.request(config);
  if (resposne?.data !== undefined) {
    window.localStorage.setItem(
      "brand",
      JSON.stringify(resposne?.data[0]?.toSting())
    );
  }
  return resposne?.data;
};

export const createDropId = async (formData, barndId) => {
  let data = JSON.stringify({
    activeFrom: formData?.startLaunch,
    activeTo: formData?.endLaunch,
    chain: "polygon",
    contractAddress: formData?.contractAddress || "",
    description: formData?.description,
    dropName: formData?.title,
    dropzBrandId: barndId,
    imageUrl: formData?.thumbnail,
    komets: 0,
    metadataLink: formData?.MetadataLink || "",
    passcode: formData?.passcode || "",
    reward1: formData?.rewardOne,
    reward2: formData?.rewardTwo,
    rewardType: formData?.rewardType || "",
    type: formData?.dropzType,
    utility: formData?.category || "",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/drop/add/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};

export const updateDropId = async (formData, barndId) => {
  let data = JSON.stringify({
    activeFrom: formData?.startLaunch,
    activeTo: formData?.endLaunch,
    chain: "polygon",
    contractAddress: formData?.contractAddress,
    description: formData?.description,
    dropName: formData?.title,
    dropzBrandId: barndId,
    imageUrl: formData?.thumbnail,
    komets: 0,
    metadataLink: formData?.metadataLink,
    passcode: formData?.passcode,
    reward1: formData?.rewardOne,
    reward2: formData?.rewardTwo,
    rewardType: formData?.rewardType,
    type: formData?.dropzType,
    id: formData?.id,
    utility: formData?.category || "",
    isLive: formData?.isLive || false,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/drop/add/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};

export const DisableDropId = async (formData, barndId, disable) => {
  let data = JSON.stringify({
    activeFrom: formData?.startLaunch,
    activeTo: formData?.endLaunch,
    chain: "polygon",
    contractAddress: formData?.contractAddress,
    description: formData?.description,
    dropName: formData?.title,
    dropzBrandId: barndId,
    imageUrl: formData?.thumbnail,
    komets: 0,
    metadataLink: formData?.metadataLink,
    passcode: formData?.activities?.passcode,
    reward1: formData?.rewardOne,
    reward2: formData?.rewardOne,
    rewardType: formData?.rewardType,
    type: formData?.dropzType,
    id: formData?.id,
    utility: "",
    isLive: disable,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/drop/add/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};

export const createActivities = async (info) => {
  let data = JSON.stringify({
    dropId: info?.dropId,
    payload: info?.payload,
    points: 10,
    tweetUrl: info?.tweetUrl,
    type: info?.type,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/dropzBrand/add/activity/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};

export const createActivitiesV2 = async (info) => {
  let data = JSON.stringify(info);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/dropzBrand/add/activities/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};

export const updateActivities = async (info) => {
  let data = JSON.stringify({
    id: info?.id,
    dropId: info?.dropId,
    payload: info?.payload,
    points: 10,
    tweetUrl: info?.tweetUrl,
    type: info?.type,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/dropzBrand/add/activity/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};

export const updateActivitiesV2 = async (info) => {
  let data = JSON.stringify(info);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASEURL + "/dropzBrand/add/activities/v1",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data;
};
