// avalanch chain main apis 
import axios from "axios";

export const getContractMetadataAvax = async (contractAddress) => {
    console.log("contract address avax : ", contractAddress);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://deep-index.moralis.io/api/v2.2/nft/${contractAddress}/metadata?chain=avalanche`,
        headers: {
            'accept': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYxMjA5OWIzLWExODgtNDAyZC1iNGMyLWNjMmU1MDc5NDc2YSIsIm9yZ0lkIjoiMTk4OTE3IiwidXNlcklkIjoiMTk4NTkxIiwidHlwZUlkIjoiODM3MjNmMjAtNTUxOC00MGM1LWExZGQtYTA5MTUzNTZhZmVhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY3NTcxNjIsImV4cCI6NDg0MjUxNzE2Mn0.LqZFK4nfGjY23hiJZTFoPISJlyUw3K20KJFCjShb1sY'
        }
    };

    const response = await axios.request(config)

    return response?.data;
}
// get nft balance 

export const getNftBalanceOwnByOwnerAvax = async (walletAddress, contractAddress) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://deep-index.moralis.io/api/v2.2/${walletAddress}/nft?chain=avalanche&format=decimal&token_addresses%5B0%5D=${contractAddress}&media_items=false`,
        headers: {
            'accept': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYxMjA5OWIzLWExODgtNDAyZC1iNGMyLWNjMmU1MDc5NDc2YSIsIm9yZ0lkIjoiMTk4OTE3IiwidXNlcklkIjoiMTk4NTkxIiwidHlwZUlkIjoiODM3MjNmMjAtNTUxOC00MGM1LWExZGQtYTA5MTUzNTZhZmVhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY3NTcxNjIsImV4cCI6NDg0MjUxNzE2Mn0.LqZFK4nfGjY23hiJZTFoPISJlyUw3K20KJFCjShb1sY'
        }
    };

    const response = await axios.request(config)
    return response?.data?.result;
}

export const getOwnerErc20TokensOnAvax = async (walletAddress) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://deep-index.moralis.io/api/v2.2/${walletAddress}/erc20?chain=avalanche`,
        headers: {
            'accept': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYxMjA5OWIzLWExODgtNDAyZC1iNGMyLWNjMmU1MDc5NDc2YSIsIm9yZ0lkIjoiMTk4OTE3IiwidXNlcklkIjoiMTk4NTkxIiwidHlwZUlkIjoiODM3MjNmMjAtNTUxOC00MGM1LWExZGQtYTA5MTUzNTZhZmVhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY3NTcxNjIsImV4cCI6NDg0MjUxNzE2Mn0.LqZFK4nfGjY23hiJZTFoPISJlyUw3K20KJFCjShb1sY'
        }
    };

    const resposne = await axios.request(config)
    return resposne?.data;
}

export const getErc20TokenContractMetadata = async (contractAddress) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://deep-index.moralis.io/api/v2.2/erc20/metadata?chain=avalanche&addresses%5B0%5D=${contractAddress}`,
        headers: {
            'accept': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYxMjA5OWIzLWExODgtNDAyZC1iNGMyLWNjMmU1MDc5NDc2YSIsIm9yZ0lkIjoiMTk4OTE3IiwidXNlcklkIjoiMTk4NTkxIiwidHlwZUlkIjoiODM3MjNmMjAtNTUxOC00MGM1LWExZGQtYTA5MTUzNTZhZmVhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY3NTcxNjIsImV4cCI6NDg0MjUxNzE2Mn0.LqZFK4nfGjY23hiJZTFoPISJlyUw3K20KJFCjShb1sY'
        }
    };

    const response = await axios.request(config)
    return response?.data;
}

export const getErc20TokenContractBalanceFetch = async (walletAddress, contractAddress) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://deep-index.moralis.io/api/v2.2/${walletAddress}/erc20?chain=avalanche&token_addresses%5B0%5D=${contractAddress}`,
        headers: {
            'accept': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYxMjA5OWIzLWExODgtNDAyZC1iNGMyLWNjMmU1MDc5NDc2YSIsIm9yZ0lkIjoiMTk4OTE3IiwidXNlcklkIjoiMTk4NTkxIiwidHlwZUlkIjoiODM3MjNmMjAtNTUxOC00MGM1LWExZGQtYTA5MTUzNTZhZmVhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY3NTcxNjIsImV4cCI6NDg0MjUxNzE2Mn0.LqZFK4nfGjY23hiJZTFoPISJlyUw3K20KJFCjShb1sY'
        }
    };

    const response = await axios.request(config)
    return response?.data;

}

export const getTokenPriceForAvax = async (token_address, chain) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://deep-index.moralis.io/api/v2.2/erc20/${token_address}/price?chain=${(chain == 43114) ? "avalanche" : "polygon"}&include=percent_change`,
        headers: {
            'accept': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYxMjA5OWIzLWExODgtNDAyZC1iNGMyLWNjMmU1MDc5NDc2YSIsIm9yZ0lkIjoiMTk4OTE3IiwidXNlcklkIjoiMTk4NTkxIiwidHlwZUlkIjoiODM3MjNmMjAtNTUxOC00MGM1LWExZGQtYTA5MTUzNTZhZmVhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY3NTcxNjIsImV4cCI6NDg0MjUxNzE2Mn0.LqZFK4nfGjY23hiJZTFoPISJlyUw3K20KJFCjShb1sY'
        }
    };

    const data = await axios.request(config)
    return data?.data;

}

export const getNativeTokenPriceForAvax = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://prod-api.komet.me/api/v1/public/pricefeed/bulk-fetch?ids=avalanche-2&vsCurrencies=usd&precision=full',
      headers: { }
    };
    
    const data = await axios.request(config)
    return data?.data;
}