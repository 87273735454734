import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const FaqContainer = ({ question, answere, element }) => {
  const [expand, setExpand] = useState(false);

  const createMarkup = () => ({ __html: element });
  return (
    <div className="container-faq mb-[16px]">
      <div
        className="flex justify-between items-center"
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <p className="text-[16px] font-500 text-[#F5F5FA] sfNormal">
          {question}
        </p>
        {expand ? (
          <BiChevronUp className="w-[20px] h-[20px]" color="#6B7280" />
        ) : (
          <BiChevronDown className="w-[20px] h-[20px]" color="#6B7280" />
        )}
      </div>
      {expand ? (
        <div>
          <div className="w-[100%] h-[1px] bg-[#1D1D1D] my-4 self-center" />
          <p className="text-[#98989F] text-[16px] text-start font-500 sfNormal">
            {answere}
          </p>
          <p
            className="text-[#98989F] text-[16px] text-start font-500 sfNormal"
            dangerouslySetInnerHTML={createMarkup()}
          ></p>
        </div>
      ) : null}
    </div>
  );
};

export default FaqContainer;
