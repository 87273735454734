import { useState } from "react";
import Header from "./_components/Header";
import Main from "./_components/main";
import "./style/index.css";
import Footer from "../pages/footer";
import FaqContainer from "../pages/FaqRow";

const FriendTech = () => {
  const [steps, setSteps] = useState(-1);
  return (
    <div className="w-[100%] background-image  mt-[24px]">
      <Header />
      <Main steps={steps} setSteps={setSteps} />

      <div className="w-[100%] mt-[140px] flex justify-center items-center">
        <div className="w-[774px]">
          <p className="header-lg mb-[40px] text-center">
            Frequently Asked Questions
          </p>

          {/* <FaqContainer
            question={"What is DropZone?"}
            answere={
              "A smart contract protocol empowering communities to distribute airdrops & rewards in a single-transaction! This saves transaction fees and time for the projects."
            }
          />
          <FaqContainer
            question={"How does it work?"}
            element={
              '<p>The protocol helps creators to distribute airdrops/rewards with 3 simple steps! <br></br>1. Select the type of token(ERCs - 20, 1155, 721....).<br /><br />2. Enter the wallet addresses and respective rewards amounts.<br></br>3. Approve the total amount and it"s done!</p>'
            }
          />
          <FaqContainer
            question={"Why use Komet - DropZone?"}
            element={`Build trust with your community by rewarding loyal users!
            <br> </br>
            1. Drop-zone is the most user-friendly platform built with
            following features:
            <br> </br>
            2. Save gas fees up-to 60% for distributing rewards.
            <br> </br>
            3. Combine multiple sends into single transaction.
            <br> </br>
            4. Allows NFTs(ERC-721,1155) and ERC-20 tokens.
            <br> </br>
            5. Multi-chain support.
            <br> </br>
            More coming soon!`}
          /> */}

          <FaqContainer
            question={"What is DropZone?"}
            answere={
              "Dropzone, by Komet, is a smart contract protocol that simplifies reward distribution and airdrops for community owners. It minimizes gas fees and allows bulk transfers of tokens, NFTs, and digital assets in a single transaction."
            }
          />
          <FaqContainer
            question={"How does dropzone work?"}
            element={
              "<p>1. Connect Wallet: Link your wallet for interaction. <br></br>2. Select Chain: Choose Ethereum or Polygon.<br /><br />3. Choose Token Type: Pick ERC-721, ERC-1155, native tokens (like MATIC or Ether), or ERC-20.<br></br>4. Smart Contract Address ( for ERC 721 or 1155 ): Paste the NFT collection's contract address.<br></br>5. Import CSV with recipient address and token IDs or enter manually.</p>"
            }
          />
          <FaqContainer
            question={"What are the tokens supported on Dropzone?"}
            element={`Dropzone is currently LIVE on Ethereum and Polygon and supports all token standards for distribution, including:
            <br> </br>
            - ERC-721: NFT collections.
            <br> </br>
            - ERC-1155: Proof of presence, work, or rewards.
            <br> </br>
            - ERC-20 tokens like USDC, USDT, and more.
            <br> </br>
            - Native currencies Matic and Ether.
            <br> </br>
            More coming soon!`}
          />
          <FaqContainer
            question={"How many wallets are allowed per transaction?"}
            element={`You can transfer funds to a maximum of 280 wallets in a single transaction.`}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FriendTech;
