import { useEffect, useState } from "react";
import Main from "../components/bundle/index";
import Header from "../components/bundle/_components/Header";
import FaqContainer from "./FaqRow";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import WarringDialog from "./WarringDialog";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import TweetRow from "./twitter";
import twittersTweet from "../data/twitter.json";

const BundleTransaction = () => {
  const [steps, setSteps] = useState(-1);
  const navigate = useNavigate();

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);

  useEffect(() => {
    setOffsetRadius(2);
    setShowArrows(false);
  }, []);

  const imageUrl =
    "https://media.istockphoto.com/id/636379014/photo/hands-forming-a-heart-shape-with-sunset-silhouette.jpg?s=612x612&w=0&k=20&c=CgjWWGEasjgwia2VT7ufXa10azba2HXmUDe96wZG8F0=";

  const slides = [
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[0].name}
          profileUrl={twittersTweet[0].logo}
          username={twittersTweet[0].username}
          tweets={twittersTweet[0].tweet}
          time={twittersTweet[0].time}
          url={twittersTweet[0].url}
          />
          ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[1].name}
          profileUrl={twittersTweet[1].logo}
          username={twittersTweet[1].username}
          tweets={twittersTweet[1].tweet}
          time={twittersTweet[1].time}
          url={twittersTweet[1].url}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[2].name}
          profileUrl={twittersTweet[2].logo}
          username={twittersTweet[2].username}
          tweets={twittersTweet[2].tweet}
          time={twittersTweet[2].time}
          url={twittersTweet[2].url}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[3].name}
          profileUrl={twittersTweet[3].logo}
          username={twittersTweet[3].username}
          tweets={twittersTweet[3].tweet}
          time={twittersTweet[3].time}
          url={twittersTweet[3].url}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[4].name}
          profileUrl={twittersTweet[4].logo}
          username={twittersTweet[4].username}
          tweets={twittersTweet[4].tweet}
          time={twittersTweet[4].time}
          url={twittersTweet[4].url}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[5].name}
          profileUrl={twittersTweet[5].logo}
          username={twittersTweet[5].username}
          tweets={twittersTweet[5].tweet}
          time={twittersTweet[5].time}
          url={twittersTweet[5].url}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[6].name}
          profileUrl={twittersTweet[6].logo}
          username={twittersTweet[6].username}
          tweets={twittersTweet[6].tweet}
          time={twittersTweet[6].time}
          url={twittersTweet[6].url}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <TweetRow
          name={twittersTweet[7].name}
          profileUrl={twittersTweet[7].logo}
          username={twittersTweet[7].username}
          tweets={twittersTweet[7].tweet}
          time={twittersTweet[7].time}
          url={twittersTweet[7].url}
        />
      ),
    },
  
  ];

  return (
    <div className="w-screen background-image">
      <div className="w-[100%] h-[52px] relative text-center bg-[#FFD230] flex justify-center items-center mb-[24px]">
        <p className="text-center sfNormal text-[16px] font-500 z-[20]">
          Start dropping rewards to your{" "}
          <span className="text-center sfBold text-[16px] font-500">
            Friend.tech
          </span>{" "}
          network with 3 simple steps
          <span
            className="text-center sfBold text-[16px] font-500 cursor-pointer"
            onClick={() => {
              navigate("/friend_tech");
            }}
          >
            – CLICK HERE
          </span>
        </p>

        <div className="w-[100%] h-[100%] absolute top-0 left-0 yellow-over"></div>
      </div>

      <Header />
      <Main steps={steps} setSteps={setSteps} />

      {/* Tweets crousel */}

      {/* image src : https://media.istockphoto.com/id/636379014/photo/hands-forming-a-heart-shape-with-sunset-silhouette.jpg?s=612x612&w=0&k=20&c=CgjWWGEasjgwia2VT7ufXa10azba2HXmUDe96wZG8F0= */}

      <div className="w-[100%] py-[156px]">
        <p className="header-lg mb-[40px] pb-[84px]">What community builders have to say?</p>
        <div className="w-[100%] mt-[64px] flex justify-center items-center">
          <div className="w-[70%] h-[200px] relative">
            <Carousel
              slides={slides}
              goToSlide={goToSlide}
              offsetRadius={2}
              animationConfig={config.default}
            />

            {/* buttons */}

              <div
                className="w-[30%] h-[100%] absolute top-0 left-0 z-[20]"
                onClick={() => {
                  setGoToSlide((state) => state - 1);
                }}
              ></div>
              <div
                className="w-[30%] h-[100%] absolute top-0 right-0 z-[20]"
                onClick={() => {
                  setGoToSlide((state) => state + 1);
                }}
              ></div>
          </div>
        </div>
      </div>

      {/* Faq's */}

      <div className="w-[100%] mt-[140px] flex justify-center items-center">
        <div className="w-[774px]">
          <p className="header-lg mb-[40px]">Frequently Asked Questions</p>

          <FaqContainer
            question={"What is DropZone?"}
            answere={
              "Dropzone, by Komet, is a smart contract protocol that simplifies reward distribution and airdrops for community owners. It minimizes gas fees and allows bulk transfers of tokens, NFTs, and digital assets in a single transaction."
            }
          />
          <FaqContainer
            question={"How does dropzone work?"}
            element={
              "<p>1. Connect Wallet: Link your wallet for interaction. <br></br>2. Select Chain: Choose Ethereum or Polygon.<br /><br />3. Choose Token Type: Pick ERC-721, ERC-1155, native tokens (like MATIC or Ether), or ERC-20.<br></br>4. Smart Contract Address ( for ERC 721 or 1155 ): Paste the NFT collection's contract address.<br></br>5. Import CSV with recipient address and token IDs or enter manually.</p>"
            }
          />
          <FaqContainer
            question={"What are the tokens supported on Dropzone?"}
            element={`Dropzone is currently LIVE on Ethereum and Polygon and supports all token standards for distribution, including:
            <br> </br>
            - ERC-721: NFT collections.
            <br> </br>
            - ERC-1155: Proof of presence, work, or rewards.
            <br> </br>
            - ERC-20 tokens like USDC, USDT, and more.
            <br> </br>
            - Native currencies Matic and Ether.
            <br> </br>
            More coming soon!`}
          />
          <FaqContainer
            question={"How many wallets are allowed per transaction?"}
            element={`You can transfer funds to a maximum of 280 wallets in a single transaction.`}
          />
        </div>
      </div>

      <Footer />

      {/* Warring Dialog */}

      <div>
        <WarringDialog />
      </div>
    </div>
  );
};

export default BundleTransaction;
