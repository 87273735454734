import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import useScrollLock from "./useScrollLock";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { errorToast } from "../../../utils/toast";

const AddressDialog = ({ onAdd, onClose, selector, balance, list }) => {
  const [address, setAddress] = useState();
  const [amount, setAmount] = useState();
  const [erc1155Amount, setERC1155Amount] = useState(0);
  const [sum, setSum] = useState();
  const [isError, setIsError] = useState(false);
  const { toggle } = useScrollLock();

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleAmount = (e) => {
    // if (selector === 0 || selector === 2) {
    //   console.log("isbalance", isBalanceRemains(), sum);
    //   if (isBalanceRemains() >= 0) {
    //     if (e.target.value <= balance && e.target.value <= isBalanceRemains()) {
    //       setIsError(false);
    //     } else {
    //       setIsError(true);
    //     }
    //   } else {
    //     setIsError(true);
    //   }
    // } else {
    //   setAmount(e.target.value);
    // }
    setAmount(e.target.value.toString());
  };

  const handleERC1155Amount = (e) => {
    setERC1155Amount(e.target.value);
  };

  const isBalanceRemains = () => {
    const remain = parseFloat(balance) - parseFloat(sum);
    return remain;
  };

  useEffect(() => {
    let sum = 0;
    list.forEach((element) => {
      sum += parseFloat(element.amount);
    });
    setSum(sum);
  }, []);

  useEffect(() => {
    toggle(true);
  }, []);

  return (
    <div
      className="w-full floating-dialog flex justify-center items-center backdrop-blur-md"
      style={{ backgroundColor: "rgba(0,0,0, 0.5)" }}
    >
      <div className="w-[50%] bg-[#06040F] rounded-[6px] px-10  py-[16px]">
        <div className="w-full flex justify-between items-center py-2">
          <p className="text-[white] text-[20px] sfNormal ">
            Add Recipient's Address
          </p>
          <IoMdClose
            className="w-[20px] h-[20px] text-[#FE73A5] cursor-pointer"
            onClick={() => onClose(false)}
          />
        </div>

        <div className="w-full flex justify-between items-center mt-[10px]">
          <div className="w-[68%]">
            <p className="text-[white] text-[14px] text-start sfNormal">
              Recipient's Address
            </p>
            <input
              className="w-[100%] h-[40px] rounded-[6px] mt-[5px] p-[10px] bg-[#2A2938] text-[white] text-[14px] outline-none focus:outline-none"
              onChange={handleAddress}
              type="text"
            />
          </div>

          {selector !== 3 ? (
            <div className="w-[30%]">
              <p className="text-[white] text-[14px] text-start sfNormal ">
                Token {selector === 0 || selector === 2 ? "Amount" : "Id"}
              </p>
              <input
                className="w-[100%] h-[40px] rounded-[6px] mt-[5px] p-[10px] bg-[#2A2938] text-[white] text-[14px] outline-none focus:outline-none"
                onChange={handleAmount}
                type={"text"}
              />
            </div>
          ) : null}

          {selector === 3 ? (
            <div className=" ml-4 flex justify-between items-center sfNormal  ">
              <div className="w-[48%]">
                {(selector !== 3) ? <p className="text-[white] text-[14px] text-start">
                  Token {selector === 0 || selector === 2 ? "Amount" : "Id"}
                </p> : <p className="text-[white] text-[14px] text-start">
                  Token Id 
                </p>}
                <input
                  className="w-[100%] h-[40px] rounded-[6px] mt-[5px] p-[10px] text-[14px] bg-[#2A2938] text-[white] outline-none focus:outline-none"
                  onChange={handleAmount}
                  type={"text"}
                />
              </div>
              <div className="w-[48%]">
                { (selector !== 3) ? <p className="text-[white] text-[14px] text-start sfNormal">Amount</p> :
                <p className="text-[white] text-[14px] text-start sfNormal">Amount</p>}
                <input
                  className="w-[100%] h-[40px] rounded-[6px] mt-[5px] p-[10px]  text-[14px] bg-[#2A2938] text-[white] outline-none focus:outline-none"
                  onChange={handleERC1155Amount}
                  type={"text"}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <button
            className="approve-button font-bold w-[100%] py-2 text-[white] text-[18px] font-bold rounded-[10px] bg-[#FE73A5] p-[5px] mt-[30px]"
            style={{ opacity: isError ? 0.5 : 1 }}
            onClick={() => {
              if (
                address !== "" &&
                amount !== "" &&
                address !== undefined &&
                amount !== undefined
              ) {
                if (address !== "" && address !== undefined) {
                  if (amount !== "" && amount !== undefined) {
                    if (ethers.utils.isAddress(address)) {
                      if (
                        address !== "0x0000000000000000000000000000000000000000"
                      ) {
                        // console.log(address, amount);

                        if (amount?.toString()?.includes(",")) {
                          amount?.split(",").map((element) => {
                            // console.log(element, amount);
                            onAdd({ address, amount: element, erc1155Amount });
                          });
                        } else {
                          onAdd({ address, amount, erc1155Amount });
                        }
                        toggle(false);
                        onClose(false);
                      } else {
                        errorToast(
                          "0x0000000000000000000000000000000000000000 address found. Please remove this"
                        );
                      }
                    } else {
                      errorToast("Address is not valid");
                    }
                  } else {
                    errorToast("Please Enter Amount");
                  }
                } else {
                  errorToast("Please Enter Address");
                }
              } else {
                // console.log(address, amount);
                // console.log("validation");
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressDialog;
