import { useEffect } from "react";

const Stepper = ({ steps, setSteps }) => {
  const options = ["1. Prepare", "2. Approval", "3. Send"];

  // useEffect(() => {
  //   console.log("steps ", steps + 1);
  // }, [steps]);

  return (
    <div className="w-[100%] flex justify-center items-center bg-gray-900 mt-[20px]">
      <div className="w-[100%] flex justify-between items-center border-[#36344D] border-[1px] rounded-[8px]">
        {options.map((value, index) => {
          return (
            <div key={index}>
              <button
                className={`${
                  steps + 1 == index
                    ? "progress-selected-item"
                    : "progress-unselected-item"
                }`}
                // onClick={() => {
                //   if (steps >= 0)
                //   setSteps(-1);
                // }}
              >
                {value}
              </button>
            </div>
          );
        })}
      </div>
      {/* <div className="w-[65%] lg:w-[40%]">
        <ol className="flex items-center w-full text-sm font-medium text-center text-gray-400 dark:text-gray-400 sm:text-base">
          <li
            className={
              steps >= -1
                ? "flex md:w-full items-center text-blue-600 dark:text-[#FE73A5] sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-2 dark:after:border-gray-700"
                : "flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"
            }
          >
            <span className="flex items-center text-[#FE73A5] after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
              {steps >= 0 ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 sm:w-5 sm:h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <span className="w-[20px] h-[20px] mr-2 bg-[#FE73A5] text-[white] text-[14px] sm:text-center sm:pt-[1px] rounded-full flex justify-center items-center">
                  1
                </span>
              )}
              Prepare{" "}
            </span>
          </li>
          <li
            className={
              steps >= 0
                ? "flex md:w-full items-center text-blue-600 dark:text-[#FE73A5] sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-2 dark:after:border-gray-700"
                : "flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"
            }
          >
            <span className="flex items-center text-[#FE73A5] after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
              {steps >= 1 ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 sm:w-5 sm:h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <span className="w-[20px] h-[20px] text-[#FE73A5] mr-2 bg-[#FE73A5] text-[white] text-[14px] sm:text-center sm:pt-[1px] rounded-full flex justify-center items-center">
                2
              </span>
              )}
              Approval{" "}
            </span>
          </li>
          <li
            className={
              steps >= 1
                ? "flex md:w-full items-center text-[#FE73A5] dark:text-[#FE73A5] sm:after:content-[''] sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"
                : "flex items-center"
            }
          >
            {steps >= 1 ? (
              <svg
                aria-hidden="true"
                className="w-4 h-4 mr-2 sm:w-5 sm:h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            ) : (
                <span className="w-[20px] h-[20px] mr-2 bg-[#FE73A5] text-[white] text-[14px] sm:text-center sm:pt-[1px] rounded-full flex justify-center items-center">
                3
              </span>
            )}
            Send
          </li>
        </ol>
      </div> */}
    </div>
  );
};

export default Stepper;
