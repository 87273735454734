import { useContext, useEffect } from "react";
import { ChainInfo } from "../../../App";
import { getChainLogo } from "./networkDropDrown";
import { useAccount } from "wagmi";
import CustomWalletConnect from "./CustomWalletConnect";

const NativeBalance = ({ nativeBalance, isFetching }) => {
  const { selectedChain } = useContext(ChainInfo);
  const { isConnected } = useAccount();

  useEffect(() => {
    // console.log("balance", nativeBalance);
  }, []);

  return (
    <div className="w-[100%] flex justify-start items-center ml-[78px] mt-[5px]">
      {isConnected ? (
        <>
          <p className="text-[white] text-[20px] font-bold sfNormal">
            Balance :{" "}
          </p>
          <img
            className="w-[20px] h-[20px] rounded-full ml-[8px]"
            src={getChainLogo(selectedChain)}
            alt="logo"
          />
          <p className="text-[white] text-[16px] ml-[4px] sfNormal">
            {isFetching ? "Fetching..." : parseFloat(nativeBalance).toFixed(4)}
          </p>
        </>
      ) : (
        <CustomWalletConnect />
      )}
    </div>
  );
};

export default NativeBalance;
