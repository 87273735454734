import { useState } from "react";
import TrashIcon from "../../../assets/trash.svg";

const ShowTable = ({
  pagination,
  list,
  onItemRemove,
  isRemovable,
  selector,
  senders,
  getTotalAmount,
  resetState
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const PaginationBottomDialog = ({ pagination }) => {
    return (
      <nav className="w-[100%] flex justify-center">
        <ul className=" flex justify-center text-sm ">
          <li
            aria-disabled={currentIndex > 0 ? false : true}
            style={{ opacity: currentIndex > 0 ? 1 : 0.3 }}
            onClick={() => {
              if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
              }
            }}
          >
            <a
              href="#top"
              className="flex items-center rounded-l-[8px] justify-center px-3 h-8 ms-0 leading-tight text-gray-500 border border-e-0 border-gray-300 rounded-s-lg bg-gray-800 border-gray-700  hover:bg-gray-700 hover:text-white"
            >
              {"<"}
            </a>
          </li>
          {pagination?.map((value, index) => {
            return (
              <>
                {(pagination?.length > 18) ?
                  <>
                    {((index > 10 && index < 18)) ? <>{(currentIndex !== index) ? <span className="text-gray-700" style={{
                      textAlign: "center",
                      display: "flex",
                      alignSelf: "end",
                    }}>.</span> : <li
                      onClick={() => {
                        setCurrentIndex(index);
                      }}
                    >
                      <a
                        href="#top"
                        key={index}
                        className={`flex items-center justify-center px-2 h-8 leading-tight text-gray-500 border  rounded-e-lg bg-gray-800${currentIndex == index
                          ? "bg-gray-700 text-[#FD43A5] border-[2px] border-[#FD43A5]"
                          : "bg-gray-700 text-[#FD43A5] border-[2px] border-gray-700"
                          }`}
                      >
                        {index + 1}
                      </a>
                    </li>}</> :
                      <li
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      >
                        <a
                          href="#top"
                          key={index}
                          className={`flex items-center justify-center px-2 h-8 leading-tight text-gray-500 border  rounded-e-lg bg-gray-800${currentIndex == index
                            ? "bg-gray-700 text-[#FD43A5] border-[2px] border-[#FD43A5]"
                            : "bg-gray-700 text-[#FD43A5] border-[2px] border-gray-700"
                            }`}
                        >
                          {index + 1}
                        </a>
                      </li>}
                  </> :

                  <>
                    <li
                      onClick={() => {
                        setCurrentIndex(index);
                      }}
                    >
                      <a
                        href="#top"
                        key={index}
                        className={`flex items-center justify-center px-2 h-8 leading-tight text-gray-500 border  rounded-e-lg bg-gray-800${currentIndex == index
                          ? "bg-gray-700 text-[#FD43A5] border-[2px] border-[#FD43A5]"
                          : "bg-gray-700 text-[#FD43A5] border-[2px] border-gray-700"
                          }`}
                      >
                        {index + 1}
                      </a>
                    </li>
                  </>
                }
              </>
            );
          })}
          <li
            aria-disabled={currentIndex < pagination?.length - 1 ? false : true}
            style={{ opacity: currentIndex < pagination?.length - 1 ? 1 : 0.3 }}
            onClick={() => {
              if (currentIndex < pagination?.length - 1) {
                setCurrentIndex(currentIndex + 1);
              }
            }}
          >
            <a
              aria-disabled={
                currentIndex < pagination?.length - 1 ? false : true
              }
              href="#top"
              className="flex items-center rounded-r-[8px] justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 rounded-e-lg bg-gray-800 border-gray-700 hover:bg-gray-700 hover:text-white"
            >
              {">"}
            </a>
          </li>
        </ul>
      </nav>
    );
  };

  const GenrateNewIndex = (index) => {
    const chunk = 20;
    let newIndex = 0;
    if (currentIndex > 0) {
      newIndex = chunk * currentIndex + index;
    } else {
      newIndex = index;
    }
    return newIndex;
  };

  return (
    <div className="mt-[48px]">
      <div className="w-[726px] flex justify-end items-center mb-[3px]">
        <button style={{
          display: "flex",
          padding: "6px 8px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          borderRadius: "8px",
          border: "1px solid #212023",
          background: "linear-gradient(313deg, rgba(114, 64, 255, 0.15) 6.05%, rgba(144, 57, 255, 0.15) 49.27%, rgba(162, 233, 255, 0.15) 126.97%)",
        }}
          onClick={resetState}
        >
          <p className="sfMedium" style={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.56px",
            background: "var(--UI-elements-Primary-Gradient, radial-gradient(100.15% 100% at 49.85% 0%, #FE73A5 0%, #FE508F 100%))",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            Delete all
          </p>
        </button>
      </div>
      <div id="top" className="transaction-outter-box ">



        <div className="w-[100%] flex justify-between items-center">
          <div className="w-[80%] flex justify-start items-center px-[8px]">
            <p className="final-address"> # Address</p>
          </div>

          <div className="w-[50%] flex justify-around items-center">
            <div className="flex jsutify-center items-center">
              {selector == 3 ? <p className="final-address">Amount</p> : null}
              {selector == 1 ? <p className="final-address">Token Id</p> : null}
              {selector == 0 || selector == 2 ? (
                <p className="final-address">Amount</p>
              ) : null}
            </div>

            {selector === 3 ? (
              <div className="flex jsutify-between items-center">
                <p className="final-address">Token Id</p>
              </div>
            ) : null}

            <div className="flex jsutify-between items-center">
              <p className="final-address"></p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center my-[18px]">
          <div className="w-[100%] border-[#9E9EA2] border-t-[1px]"></div>
        </div>

        <div className="w-[100%]">
          {pagination[currentIndex]?.map((item, index) => (
            <div
              key={index}
              className="w-[100%] flex justify-around items-center mb-[12px] selectedAddress"
            >
              <div className="w-[80%] h-[20px] flex items-center px-[8px]">
                <p className="header-text  text-[white]">
                  {GenrateNewIndex(index + 1)}. {item?.address}
                </p>
              </div>

              <div className="w-[50%] flex justify-around">
                {/* {selector == 1 || selector == 3 ? (
                  <div className="flex justify-end items-center px-[8px]">
                    <p className="header-text text-[white]">{item?.amount}</p>
                  </div>
                ) : null} */}

                {selector === 3 ? (
                  <div className="w-[80%] flex justify-start items-center px-[8px]">
                    <p className="header-text text-[white] ml-[40px]">
                      {item?.erc1155Amount}
                    </p>
                  </div>
                ) : null}

                {isRemovable ? (
                  <div className="w-[100%] flex justify-around items-center">
                    <p className="header-text text-[white]">
                      {" "}
                      {selector === 3
                        ? parseFloat(item?.amount)
                        : parseFloat(item?.amount).toFixed(3)}
                    </p>
                    <img
                      className="w-[20px] h-[20px] ml-[0px]"
                      src={TrashIcon}
                      alt=""
                      onClick={() => onItemRemove(index, GenrateNewIndex(index))}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>

        {/* <div className="w-[100%] flex justify-between items-center">
          <p className="final-address mt-[47px]">
            Total no. of addresses : {senders?.length}
          </p>
          <p className="final-address mt-[47px]">
            Total no. of Amount :{" "}
            {selector == 0 || selector == 2
              ? getTotalAmount(senders).toFixed(3)
              : senders?.length}
          </p>
        </div> */}

        <div className="w-[100%] flex justify-between items-center">
          {/* <button className="w-[100px] selected-Buttom">Pre</button>
          <button className="w-[100px] selected-Buttom">Next</button> */}
          {pagination?.length > 1 ? (
            <PaginationBottomDialog pagination={pagination} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ShowTable;
