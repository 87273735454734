import React, { useState } from "react";
import { useAccount, useProvider, useSigner, useSwitchNetwork } from "wagmi";

import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { ethers } from "ethers";
import abi from "../utils/ContractABI.json";
import { bytecode } from "../utils/FF4Deploy";

export default function Deploy() {
  const { address } = useAccount();
  const { switchNetworkAsync, isSuccess, isLoading, isError } =
    useSwitchNetwork();
  const { data: signer } = useSigner();
  const [loading, setLoading] = useState(false);
  const [hash, setHash] = useState("");

  const deployContract = async () => {
    switchNetworkAsync(137);
    var subscriptionId = /* var of type uint64 here */ "";
    var vrfCoordinator = /* var of type address here */ "";
    var keyHash = /* var of type bytes32 here */ "";
    var komet_minter = /* var of type address here */ "";
    const contractFactory = new ethers.ContractFactory(abi, bytecode, signer);
    const contract = await contractFactory.deploy([
      subscriptionId,
      vrfCoordinator,
      keyHash,
      komet_minter,
    ]);
    await contract.deployed();
  };

  return (
    <div className="w-[100%] h-full flex flex-col items-center justify-center">
      <p className="sm:text-[40px] text-[20px] text-bold text-[white] ">
        Flight Force 4 Contract Deployment
      </p>
      {loading ? (
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="20"
          visible={loading}
        />
      ) : (
        <button
          onClick={() => {
            deployContract();
          }}
          className=" mt-[100px] bold px-6 h-[56px] w-[210px] text-[14px] sm:text-[18px] rounded-[5px] flex items-center justify-center relative fontRegular bg-[#E20C57] self-center"
        >
          <p className="blackFont text-[white] font-bold text-[14px] sm:text-[18px] ">
            DEPLOY NOW
          </p>
        </button>
      )}
    </div>
  );
}
