
import UpdateRafflesActivities from "../components/UpdateRafflesActivities";

const UpdateActivites = ({setOpen, passcode, dropsId}) => {
  return (
    <div className="w-[100%] h-[100%] absolute top-0 left-0 flex justify-center items-center floating-dialog">
      <div className="w-[80%] h-max-[350px] rounded-[6px] backdrop-blur-lg bg-[black] overflow-y-scroll">
        <div className="m-[24px]">
          <p className="w-[100%] text-[white] sfNormal text-[18px]">
            Update Activities
          </p>

        <div className="mt-[20px]">
          <UpdateRafflesActivities setOpen={setOpen} passcode={passcode} dropId={dropsId} />
        </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateActivites;
