import { BsTwitter, BsDiscord, BsTelephone } from "react-icons/bs";
import KometIcon from "../assets/Komet_logo.png";

const Footer = () => {
  return (
    <div className="w-[100%] h-[300px] flex justify-between items-start bg-[#0A0A0A] mt-[140px]">
      <div className="w-[25%] flex justify-center items-center mt-[54px]">
        <div>
          <div className="w-[100%] flex justify-left items-center">
            <img className="w-[117px] h-[30px]" src={KometIcon} alt="logo" />
          </div>

          <div>
            <p className="mt-[32px] text-[14px] font-700 sfNormal text-[white] sfNormal text-left">
              Komet Technology, Inc
            </p>
            <p className=" text-[14px] font-700 sfNormal text-[#CECECE] sfNormal text-left">
              16192 Coastal Highway Lewes,
            </p>
            <p className=" text-[14px] font-700 sfNormal text-[#CECECE] sfNormal text-left">
              Delaware 19958, USA
            </p>
          </div>
        </div>
      </div>
      <div className="w-[40%] flex justify-between items-start cursor-pointer mt-[54px]">
        <ul className="text-left">
          <li className="text-[white] sfNormal text-[14px] font-700">
            Product
          </li>
          <li
            className="text-[#98989F] sfNormal text-[14px] font-300 mt-[35px]"
            onClick={() => {
              window.open("https://app.komet.me");
            }}
          >
            Mobile App
          </li>
        </ul>
        <ul className="text-left">
          <li className="text-[white] sfNormal text-[14px] font-700">
            Company
          </li>
          <li
            className="text-[#98989F] sfNormal text-[14px] font-300 mt-[35px]"
            onClick={() => {
              window.open(
                "https://kometverse.notion.site/About-Us-ee1187a2159842d38960371f815b2db5"
              );
            }}
          >
            About us
          </li>
          <li
            className="text-[#98989F] sfNormal text-[14px] font-300 mt-[5px]"
            onClick={() => {
              window.open(
                "https://kometverse.notion.site/Join-Komet-af006bc7947944aab126907c9af392bd"
              );
            }}
          >
            Careers
          </li>
          <li
            className="text-[#98989F] sfNormal text-[14px] font-300 mt-[5px]"
            onClick={() => {
              window.open("https://www.blog.komet.me/");
            }}
          >
            Blogs
          </li>
        </ul>
        <ul className="text-left">
          <li className="text-[white] sfNormal text-[14px] font-700">
            Contact
          </li>
          <li
            className="text-[#98989F] sfNormal text-[14px] font-300 mt-[35px]"
            onClick={() => {
              window.open(
                "https://kometverse.notion.site/Join-Komet-af006bc7947944aab126907c9af392bd",
                "_blank"
              );
            }}
          >
            partners@komet.me
          </li>
        </ul>
      </div>
      <div className="w-[25%] flex flex-col justify-center mt-[54px]">
        <div className="w-[100%] flex justify-center items-center">
          <BsTwitter
            className="w-[30px] h-[30px] text-[white] cursor-pointer"
            onClick={() => {
              window.open("https://twitter.com/kometverse", "_blank");
            }}
          />
          <BsDiscord
            className="w-[30px] h-[30px] text-[white] ml-[40px] cursor-pointer"
            onClick={() => {
              window.open("https://discord.com/invite/komet", "_blank");
            }}
          />
        </div>

        <div
          className="w-[100%] contract-us flex justify-center items-center mt-[32px] self-center cursor-pointer"
          onClick={() => {
            window.open("https://t.me/kometdrops", "_blank");
          }}
        >
          <BsTelephone className="w-[34px] h-[34px] text-[#FE73A5] mr-[10px]" />
          <div>
            <p className="text-[8px] font-500 sfNormal text-[#FE73A5]">
              Still Having Queries?
            </p>
            <p className="text-[14px] font-700 sfNormal text-[#FE73A5]">
              24 * 7 Support
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
