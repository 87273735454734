import NativeBalance from "../bundle/_components/NativeBalance";
import { FaLock } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import AddressDialog from "../bundle/_components/AddDialog";
import ShowTable from "../bundle/_components/showTable";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { ethers } from "ethers";
import { getNativeBalance } from "../../utils/erc20";
import DEPLOYMENT from "../../data/deployment.json";
import { readContracts, useAccount, useDisconnect } from "wagmi";
import { RotatingLines } from "react-loader-spinner";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import UserTokenModal from "../bundle/_components/user-tokens";
import SelectType from "../bundle/_components/selectType";
import DragAndDrop from "../bundle/_components/DragAndDrop";
import Avatar from "react-avatar";
import useDropzoneHooks from "./useDropzoneHook";
import useScrollLock from "./_components/useScrollLock";
import { getTokenPrice } from "../../utils/chainlink";
import { errorToast } from "../../utils/toast";
import BadgeSelected from "../../assets/Badge_selected.svg";
import BadgeUnSelected from "../../assets/Badge_unselected.svg";
import DownloadIcon from "../../assets/download.svg";
import ProgressProgressIcon from "../../assets/pp.svg";
import AddIcon from "../../assets/plus.svg";
import ProgressIcon from "../../assets/progress_sending.svg";
import ProgressSuccessIcon from "../../assets/progress_success.svg";
import Stepper from "./_components/process";
import AddTokenIdToAddress from "./_components/AddAutomaticTokenId";
import { formatUnits } from "ethers/lib/utils.js";
import Bugsnag from "@bugsnag/js";
import CustomWalletConnect from "./_components/CustomWalletConnect";
import {
  getNativeTokenPriceForAvax,
  getTokenPriceForAvax,
} from "../../utils/avalanche";
import { BsCartCheck } from "react-icons/bs";

// Gloable function

const getTotalAmountForPricingModule = (a, b) => {
  const totalAmount = parseFloat(a) + parseFloat(b);
  return isNaN(totalAmount) ? 0 : totalAmount?.toFixed(2);
};

const Main = ({ steps, setSteps }) => {
  const {
    provider,
    wallet,
    totalAmount,
    setTotalAmount,
    senders,
    setSenders,
    open,
    setOpen,
    isError,
    isTransactionStart,
    isItemRemoved,
    setIsItemRemoved,
    hash,
    failToLoad,
    contractAddress,
    setFailToLoad,
    contractAddressLogo,
    setContractAddress,
    setContractAddressLogo,
    contractloading,
    setContractLoading,
    selecterAddress,
    setSelecterAddresss,
    importCsvOpen,
    setImportCsvOpen,
    radioButtonState,
    setRadioButtonState,
    nativeBalance,
    setNativeBalance,
    contractInfo,
    setContractInfo,
    selectedChain,
    isPending,
    priceInUsd,
    setPriceInUsd,
    requestApproval,
    getTxState,
    getTotalAmount,
    handleAddress,
    handleFile,
    handleSelector,
    AddSender,
    checkBalance,
    set1USDPrice,
    getContractInfo,
    transactionButtonDisable,
    setTransactionButtonDisable,
    getErrorMessage,
    isUserOwnTokenOrNot,
    pagination,
    setPagination,
    ConvertIntoPagination,
  } = useDropzoneHooks({ setSteps, steps });

  const { isConnected } = useAccount();

  const { toggle } = useScrollLock();

  const [openAutoBox, setOpenAutoBox] = useState(false);
  const [amountInUsd, setAmountInUsd] = useState(0); // for storing converted price
  const [isPlatformFee, setIsPLatformFee] = useState(false); // for stroing Amount is eligmble for platform fees or not
  const [isNativeBalanceFeching, setIsNativeBalanceFeching] = useState(false); // for stroing Amount is eligmble for platform fees or not
  const [erc20ContractAddres, setErc20ContractAddress] = useState(""); // for stroing Amount is eligmble for platform fees or not

  useEffect(() => {
    if (selectedChain === 137) {
      const amount = getTotalAmount(senders).toFixed(6);
      getTokenPrice(contractInfo?.symbol?.toString()?.toUpperCase(), "polygon")
        .then((response) => {
          const usd = isNaN(
            (response.price / 10 ** response.decimals).toPrecision(4)
          )
            ? 0.1
            : (response.price / 10 ** response.decimals).toPrecision(4);

          const amount = getTotalAmount(senders)?.toFixed(4);
          // console.log("price module :", usd, amount, amount * usd);
          setPriceInUsd({ usd: amount * usd });
          setTotalAmount(amount * usd);
          // setPriceInUsd({ usd: response.price / 10 ** response.decimals });
        })
        .catch((err) => {
          // Bugsnag.notify(new Error(err));
          // console.log("price fetching failed", err);

          getTokenPrice("MATIC", "polygon")
            .then((response) => {
              const usd = isNaN(
                (response.price / 10 ** response.decimals).toPrecision(4)
              )
                ? 0.1
                : (response.price / 10 ** response.decimals).toPrecision(4);

              const amount = getTotalAmount(senders)?.toFixed(4);
              setPriceInUsd({ usd: amount * usd });
              localStorage.setItem("matRate", usd);
            })
            .catch((err) => {
              // Bugsnag.notify(new Error(err))
              console.log("price fetching failed", err);
              errorToast(err);
            });
        });
    } else if (selectedChain === 5) {
      getEthereumPrice();
    }
    // console.log("pricing module");
  }, [steps, radioButtonState]);

  const getTotalAmountPay = (a, b) => {
    // console.log("add", a, b);
    return (parseFloat(a) + parseFloat(b)).toFixed(2);
  };

  useEffect(() => {
    setSteps(-1);
    setContractAddress("");
    setContractInfo({
      balance: 0,
      symbol: null,
    });
    setSenders([]);
    setPagination([]);
    setContractLoading(false);
    // onboarding;
  }, [selectedChain]);

  useDisconnect({
    onSuccess(data) {
      setSteps(-1);
      setRadioButtonState(-1);
    },
  });

  useEffect(() => {
    if (wallet.isConnected && radioButtonState === 2) {
      getBalance();
    }
  }, [wallet.isConnected, radioButtonState]);

  const getBalance = async () => {
    try {
      setIsNativeBalanceFeching(true);
      const balance = await getNativeBalance(provider, wallet.address);
      setNativeBalance(balance);
      setIsNativeBalanceFeching(false);
    } catch (err) {
      setIsNativeBalanceFeching(false);
      errorToast("Failed to fetch balance");
    }
  };

  useEffect(() => {
    if (radioButtonState === 0 || radioButtonState === 2) {
      checkBalance();
    }
  }, [senders]);

  useEffect(() => {
    if (isError?.bool) {
      errorToast(
        isError?.["err"]?.reason || getErrorMessage(isError?.err?.data?.message)
      );
    }
  }, [isError]);

  useEffect(() => {
    setTransactionButtonDisable(true);
  }, [radioButtonState]);

  useEffect(() => {
    if (senders?.length <= 0) {
      setSteps(-1);
    }
    setSteps(-1);
  }, [senders]);

  useEffect(() => {
    // check the chain

    if (selectedChain == 1 && radioButtonState !== 0) {
      getEthereumPrice();
    } else if (selectedChain == 1 && radioButtonState == 0) {
      handleUsdPrices();
    } else if (selectedChain == 43114 && radioButtonState == 0) {
      getAvaxTokenPrice(contractAddress);
    } else if (selectedChain == 43114 && radioButtonState !== 0) {
      getAvaxNativeTokenPrice();
    } else if (selectedChain == 137 && radioButtonState == 0) {
      handleUsdMaticPrices();
    } else if (selectedChain == 137 && radioButtonState !== 0) {
      getPolygonPrice();
    } else if (selectedChain == 8453 && radioButtonState !== 0) {
      getEthereumPrice();
    } else if (selectedChain == 8453 && radioButtonState == 0) {
      handleUsdPrices();
    }
    // console.log("pricing Module ", radioButtonState, selectedChain);
  }, [steps, contractInfo, radioButtonState]);

  const handleUsdPrices = async () => {
    try {
      const price = await getTokenPrice(
        contractInfo?.symbol?.toString()?.toUpperCase(),
        "eth"
      );
      const usd = formatUnits(price?.price, price?.decimals);
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      console.log(price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
    } catch (err) {
      // Bugsnag.notify(new Error(err));
      console.log(err);

      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      setPriceInUsd({ usd: 0 });
      setAmountInUsd(0);
    }
  };

  const handleUsdMaticPrices = async () => {
    try {
      const data = await getTokenPriceForAvax(contractAddress, selectedChain);
      console.log("price fetching : ", data?.usdPrice);
      const usd = data?.usdPrice;
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log(price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
    } catch (err) {
      console.log("pricing fetching failed");
      const usd = 0.000001;
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log(price, usd, totalAmount * usd);
      // setPriceInUsd({ usd: totalAmount * usd });
      // setAmountInUsd(totalAmount * usd);

      setPriceInUsd({ usd: 0 });
      setAmountInUsd(0);
    }
  };

  const getEthereumPrice = async () => {
    try {
      const price = await getTokenPrice("eth", "eth");
      const usd = formatUnits(price?.price, price?.decimals);
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log("price module", price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
      localStorage.setItem("ethRate", usd);
    } catch (err) {
      // Bugsnag.notify(new Error(err));
      console.log(err);
    }
  };

  const getPolygonPrice = async () => {
    try {
      const price = await getTokenPrice("polygon", "Matic");
      const usd = formatUnits(price?.price, price?.decimals);
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log("price module", price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
      localStorage.setItem("ethRate", usd);
    } catch (err) {
      // Bugsnag.notify(new Error(err));
      console.log(err);
    }
  };

  const getAvaxTokenPrice = async (contractAddress) => {
    try {
      const data = await getTokenPriceForAvax(contractAddress, selectedChain);
      console.log("price fetching : ", data?.usdPrice);
      const usd = data?.usdPrice;
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log(price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
    } catch (err) {
      console.log("pricing fetching failed");
      const usd = 1;
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log(price, usd, totalAmount * usd);
      // setPriceInUsd({ usd: totalAmount * usd });
      // setAmountInUsd(totalAmount * usd);
      setPriceInUsd({ usd: 0 });
      setAmountInUsd(0);
    }
  };

  const getAvaxNativeTokenPrice = async () => {
    try {
      const data = await getNativeTokenPriceForAvax();
      console.log("price fetching : ", data["avalanche-2"]?.usd);
      localStorage?.setItem("avaxRate", data["avalanche-2"]?.usd);
      const usd = data["avalanche-2"]?.usd;
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log(price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
    } catch (err) {
      console.log("pricing fetching failed");
      const usd = 1;
      const totalAmount = getTotalAmount(senders)?.toFixed(6);
      totalAmount >= 100 ? setIsPLatformFee(true) : setIsPLatformFee(false);
      // console.log(price, usd, totalAmount * usd);
      setPriceInUsd({ usd: totalAmount * usd });
      setAmountInUsd(totalAmount * usd);
    }
  };

  // UI Part start from here

  const DataEntrySection = () => {
    return (
      <div className="w-full pb-[20px]">
        <div className="w-[100%] flex justify-start items-center">
          <div className="w-[100%]">
            <div className="w-[100%]">
              <div className="w-[100%] flex items-center mb-[24px]">
                <img
                  className="w-[35px] h-[35px] mr-[40px]"
                  src={BadgeSelected}
                  alt=""
                />
                <p className="header-sfnormal text-start">Choose your token</p>
              </div>

              <div className="ml-[78px]">
                <SelectType
                  setRadioButton={setRadioButtonState}
                  setStep={setSteps}
                  radiobutton={radioButtonState}
                  handleSelectedType={(e, number) => handleSelector(e, number)}
                />
              </div>
              <br></br>
            </div>

            {radioButtonState == 2 ? (
              <NativeBalance
                nativeBalance={nativeBalance?.balance}
                isFetching={isNativeBalanceFeching}
              />
            ) : (
              <>
                <div className="w-[100%] flex justify-start ml-[79px] mt-[5px]">
                  {!isConnected ? <CustomWalletConnect /> : null}
                </div>
                <div className="mt-[48px] ">
                  <div className="w-[100%] flex items-center mb-[32px]">
                    <img
                      className="w-[35px] h-[35px] mr-[40px]"
                      src={
                        contractInfo?.symbol !== null
                          ? BadgeSelected
                          : BadgeUnSelected
                      }
                      alt=""
                    />
                    <p className="header-sfnormal text-start">
                      Enter contract address
                    </p>
                  </div>

                  <div className="ml-[78px]">
                    {contractInfo?.symbol !== null ? (
                      <div className="w-[100%] h-[40px] rounded-[14px] bg-[transparent] mt-[5px] text-[white] p-1 flex justify-start items-center">
                        <div className=" w-[180px] text-center text-[white] selected-Buttom p-1 rounded-[6px] flex justify-between items-center">
                          <div className=" w-[100px] flex justify-center items-center">
                            {failToLoad ? (
                              <div className="w-[30px] h-[30px] flex justify-center items-center m-1 drop-shadow-lg border-[black] border-[2px] rounded-full">
                                <Avatar
                                  name={contractInfo?.symbol}
                                  size="28"
                                  round
                                  color="#FE73A5"
                                />
                              </div>
                            ) : (
                              <img
                                className="w-[30px] h-[30px] rounded-full p-1 ml-[10px] drop-shadow-xl border-[black] border-[2px]"
                                onError={() => setFailToLoad(true)}
                                src={
                                  contractAddressLogo ||
                                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAA2lJREFUeF7tnTFOY0EUBMfOECIm86ks5JQ7cAzuQOAM+QJcxyJ3gBCZISfzr5FarV8bbw+PnnLNrPXX3nx+HH8H+LM97UB6jOv+jPLt4XR/GwHIIiQAGgARSA2qAVD9PKwBNACiSAOg+vJhDaABEIUaANWXD2sADYAo1ACovnxYA2gARKEGQPXlwxpAAyAKNQCqLx/WABoAUagBUH35cNwAl8Mjeh6AVvj9/IqWuH97QXkabp9/IwAMAQFg/Y32Atvn1wArB1gABMBLIGHAI4C0N4Z3gPC/YjwCVg6wAAiAdwDCgHcA0p53gJF+J9MjYOUAC4AAeAcgDHgHIO15B/AO0P4Kap8/fgeAAjEOGxAAWGB7XADadxDOLwCwwPa4ALTvIJxfAGCB7XEBaN9BOL8AwALb4wLQvoNwfgGABbbHBaB9B+H8AgALbI8LQPsOwvkFABbYHheA9h2E8wsALLA9HgeAPlDRvgE+FQw/IEIAWAMagPWH0xpAA2CIyAIagLQ3IasBNMAEjJYvoQGWdzclqQE0wBSQli6iAZY2NymnATTAJJSWLaMBlvU2LaUBNMA0mJYspAGWtDYxowE0wEScbl9KA9ze2dSEBtAAU4G6dTH85dG3/sD/f5++AtLPE7TPLwCQYAFYeYECIACogfQR5hGAtm/gT/kSAPhx6ekCPQJW/goSAAFADaQN5h0AbZ93AFhff4EeARCB9gLb5/cIWDnAAiAAx+iXR7crtH1+DaABNABhYPUGoAWQ8s3yb17FR4AAZDGk7yQKQHb/8E8XAFxh9wIC0L1/eHoBwBV2LyAA3fuHpxcAXGH3AgLQvX94egHAFXYvIADd+4enFwBcYfcCAtC9f3h6AcAVdi8gAN37h6cXAFxh9wIYgMvhET0S9vPwjhq8+3pC+fZwuj/8ETHpX0AA2AtIAMIEpV9AAiAA3gGSDGgAL4GIP3qJ9ghA9fOwBtAAiCINgOrLhzWABkAUagBUXz6sATQAolADoPryYQ2gARCFGgDVlw9rAA2AKIwbgD6QsD3tUAHt4ev+jH4F+t/z8VvBAoD2bwiABkAEaQBUXz6sATQAolADoPryYQ2gARCFGgDVlw9rAA2AKNQAqL58WANoAEShBkD15cMaQAMgCjUAqi8f1gAaAFGoAVB9+XDaAH87U5mOD7sfWwAAAABJRU5ErkJggg=="
                                }
                                alt="logo"
                              />
                            )}
                            <p className="w-[100px] text-[white] text-[12px] px-2 py-1 font-bold font-[center] sfBold">
                              {contractInfo?.symbol?.length > 6
                                ? contractInfo?.symbol?.substring(0, 6) + "..."
                                : contractInfo?.symbol}
                            </p>

                            <div
                              id="tooltip-default"
                              role="tooltip"
                              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                            >
                              Tooltip content
                              <div class="tooltip-arrow"></div>
                            </div>
                          </div>
                          <IoClose
                            className="text-[#FFFFFF] mr-2 cursor-pointer"
                            onClick={() => {
                              setContractInfo({ balance: 0, symbol: null });
                              setContractLoading(false);
                              setContractAddress("");
                              setTransactionButtonDisable(true);
                            }}
                          />
                        </div>
                      </div>
                    ) : radioButtonState === 0 ? (
                      <div
                        className="w-[35%] h-[38px]  rounded-full px-1 mt-[10px] border-[1px] border-[#FE73A5] bg-[transparent] flex justify-center items-center"
                        onClick={() => {
                          if (isConnected) {
                            toggle(true);
                            setSelecterAddresss(true);
                          } else {
                            errorToast("Please Connect your wallet");
                          }
                        }}
                        disabled={contractloading ? true : false}
                        style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                      >
                        <p
                          className=" text-[12px] text-[#FE73A5] font-bold"
                          style={{
                            cursor: isConnected ? "pointer" : "default",
                          }}
                        >
                          Select Token
                        </p>

                        <IoIosArrowDown
                          className="text-[#FE73A5] m-1 mt-[5px] cursor-pointer"
                          onClick={() => {
                            setContractInfo({ balance: 0, symbol: null });
                            setContractLoading(false);
                          }}
                        />
                        {contractloading ? (
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={contractloading}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className="w-[466px] h-[44px] rounded-[6px] px-1 mt-[5px] bg-[#2A2938] flex justify-center items-center"
                        style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                      >
                        {!ethers.utils.isAddress(contractAddress) ? (
                          <input
                            // disabled={radioButtonState > -1 ? false : true}
                            className="w-[94%] h-[40px] bg-[#2A2938] text-[white]"
                            style={{
                              outline: "none",
                              outlineColor: "transparent",
                              color: "white",
                              opacity: isConnected ? 1 : 0.5,
                            }}
                            placeholder="Enter or paste contract address"
                            disabled={isConnected ? false : true}
                            // value={(ethers.utils.isAddress(contractAddress)) ? contractAddress : null}
                            onChange={handleAddress}
                          />
                        ) : (
                          <div
                            // disabled={radioButtonState > -1 ? false : true}
                            className="w-[94%] h-[40px] bg-[transparent] text-[white] flex justify-between items-center"
                            style={{
                              outline: "none",
                              outlineColor: "transparent",
                              color: "white",
                            }}
                            // value={(ethers.utils.isAddress(contractAddress)) ? contractAddress : null}
                          >
                            <p>{contractAddress}</p>

                            {!contractloading && (
                              <IoClose
                                className="text-[white] mr-2 cursor-pointer"
                                onClick={() => {
                                  setContractInfo({ balance: 0, symbol: null });
                                  setContractLoading(false);
                                  setContractAddress("");
                                  setTransactionButtonDisable(true);
                                }}
                              />
                            )}
                          </div>
                        )}
                        {contractloading ? (
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={contractloading}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="w-[100%] mt-[48px]">
              <div className="w-[100%] flex items-center mb-[32px]">
                <img
                  className="w-[35px] h-[35px] mr-[40px]"
                  src={senders?.length > 0 ? BadgeSelected : BadgeUnSelected}
                  alt=""
                />
                <p className="header-sfnormal text-start">
                  Recipient’s address
                </p>
              </div>

              {radioButtonState !== 2 ? (
                <div className="ml-[78px] mt-[32px]">
                  <div className="flex juatify-center items-center">
                    <button
                      className={"w-[213px] button-import-style mr-[40px]"}
                      disabled={!transactionButtonDisable ? false : true}
                      style={{
                        opacity: !transactionButtonDisable ? 1 : 0.5,
                        cursor: !transactionButtonDisable
                          ? "pointer"
                          : "default",
                      }}
                      onClick={() => setImportCsvOpen(true)}
                    >
                      <img
                        className="w-[18px] h-[18px] mr-[8px]"
                        src={DownloadIcon}
                        alt=""
                      />
                      <p>Add file with TokenIDs</p>
                    </button>
                    <button
                      className="w-[213px] button-import-style mr-[40px] cursor-pointer"
                      disabled={!transactionButtonDisable ? false : true}
                      style={{
                        opacity: !transactionButtonDisable ? 1 : 0.5,
                        cursor: !transactionButtonDisable
                          ? "pointer"
                          : "default",
                      }}
                      onClick={() => setOpen(true)}
                    >
                      <img
                        className="w-[18px] h-[18px] mr-[8px]"
                        src={AddIcon}
                        alt=""
                      />
                      <p>Add manually</p>
                    </button>
                    {radioButtonState == 1 ? (
                      <>
                        {selectedChain !== 43114 && (
                          <button
                            className="w-[213px] button-import-style mr-[40px] cursor-pointer"
                            disabled={!transactionButtonDisable ? false : true}
                            style={{
                              opacity: !transactionButtonDisable ? 1 : 0.5,
                              cursor: !transactionButtonDisable
                                ? "pointer"
                                : "default",
                            }}
                            onClick={() => setOpenAutoBox(true)}
                          >
                            <img
                              className="w-[18px] h-[18px] mr-[0px]"
                              src={AddIcon}
                              alt=""
                            />
                            <p className="text-[15px]">
                              Add file without TokenIDs
                            </p>
                          </button>
                        )}
                      </>
                    ) : null}
                    {/* <button className="button-import-style">Add manually</button> */}
                  </div>

                  {open ? (
                    <AddressDialog
                      onAdd={AddSender}
                      onClose={setOpen}
                      selector={radioButtonState}
                      balance={contractInfo?.balance || nativeBalance?.balance}
                      list={senders}
                    />
                  ) : null}

                  {pagination?.pagination?.length > 0 ? (
                    <div
                      style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                      className="overflow-x-hidden overflow-auto mt-[48px] no-scroll scrollable-div"
                    >
                      <ShowTable
                        pagination={pagination?.pagination}
                        list={pagination?.pagination[0]}
                        isRemovable={true}
                        onItemRemove={(index, pageIndex) => {
                          const arr = senders;
                          arr.splice(pageIndex, 1);
                          if (
                            radioButtonState === 0 ||
                            radioButtonState === 2
                          ) {
                            checkBalance();
                          }
                          setSenders(arr);
                          const pagination = ConvertIntoPagination(arr);
                          setPagination(pagination);
                          setIsItemRemoved(!isItemRemoved);
                        }}
                        senders={senders}
                        getTotalAmount={getTotalAmount}
                        selector={radioButtonState}
                        resetState={() => {
                          setSenders([]);
                          setPagination([]);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="ml-[78px] mt-[32px]">
                  <div className="flex juatify-center items-center">
                    <button
                      className="w-[213px] button-import-style mr-[40px] cursor-pointer"
                      disabled={false}
                      style={{ opacity: 1 }}
                      onClick={() => setImportCsvOpen(true)}
                    >
                      <img
                        className="w-[18px] h-[18px] mr-[8px]"
                        src={DownloadIcon}
                        alt=""
                      />
                      <p>Import address sheet</p>
                    </button>
                    <button
                      className="w-[213px] button-import-style mr-[40px] cursor-pointer"
                      disabled={false}
                      style={{ opacity: 1 }}
                      onClick={() => setOpen(true)}
                    >
                      <img
                        className="w-[18px] h-[18px] mr-[8px]"
                        src={AddIcon}
                        alt=""
                      />
                      <p>Add manually</p>
                    </button>
                    {/* <button className="button-import-style">Add manually</button> */}
                  </div>

                  {open ? (
                    <AddressDialog
                      onAdd={AddSender}
                      onClose={setOpen}
                      selector={radioButtonState}
                      balance={contractInfo?.balance || nativeBalance?.balance}
                      list={senders}
                    />
                  ) : null}

                  {pagination?.pagination?.length > 0 ? (
                    <div
                      style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                      className="overflow-x-hidden overflow-auto mt-[48px] no-scroll scrollable-div"
                    >
                      {/* <ShowTable
                        list={senders}
                        isRemovable={true}
                        onItemRemove={(index) => {
                          const arr = senders;
                          arr.splice(index, 1);
                          if (
                            radioButtonState === 0 ||
                            radioButtonState === 2
                          ) {
                            checkBalance();
                          }
                          setSenders(arr);
                          setIsItemRemoved(!isItemRemoved);
                        }}
                        senders={senders}
                        getTotalAmount={getTotalAmount}
                        selector={radioButtonState}
                      /> */}

                      <ShowTable
                        pagination={pagination?.pagination}
                        list={pagination?.pagination[0]}
                        isRemovable={true}
                        onItemRemove={(index) => {
                          const arr = senders;
                          arr.splice(index, 1);
                          if (
                            radioButtonState === 0 ||
                            radioButtonState === 2
                          ) {
                            checkBalance();
                          }
                          setSenders(arr);
                          const pagination = ConvertIntoPagination(arr);
                          setPagination(pagination);
                          setIsItemRemoved(!isItemRemoved);
                        }}
                        senders={senders}
                        getTotalAmount={getTotalAmount}
                        selector={radioButtonState}
                        resetState={() => {
                          setSenders([]);
                          setPagination([]);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>

            <div className="mt-[12px] ">
              {/* <div className="flex justify-between items-center">
                <p className="text-[white] text-[16px] text-start font-bold">
                  Recipient's Addresses
                </p>

                <div>
                  <button
                    className="w-[70px] mb-[5px] text-[white] text-[14px] font-bold rounded-[10px] bg-[#FE73A5] p-[5px] pl-[12px] pr-[12px]"
                    disabled={radioButtonState > -1 ? false : true}
                    style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                    onClick={() => setOpen(true)}
                  >
                    <span className=" text-[18px] font-bold text-[white]">
                      +
                    </span>{" "}
                    Add
                  </button>

                  <button
                    className="w-[70px] mb-[5px] text-[white] ml-2 text-[14px] font-bold rounded-[10px] bg-[#FE73A5] p-[5px] pl-[12px] pr-[12px]"
                    disabled={radioButtonState > -1 ? false : true}
                    style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                    onClick={() => setImportCsvOpen(true)}
                  >
                    <span className=" text-[18px] font-bold text-[white]"></span>{" "}
                    Import
                  </button>
                </div>
              </div> */}

              {/* <div
                style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
                className=" h-[180px] border-2 border-[#FE73A5] rounded-[14px] overflow-x-hidden overflow-auto mt-[5px] no-scroll scrollable-div"
              >
              <ShowTable
                  list={senders}
                  isRemovable={true}
                  onItemRemove={(index) => {
                    const arr = senders;
                    arr.splice(index, 1);
                    if (radioButtonState === 0 || radioButtonState === 2) {
                      checkBalance();
                    }
                    setSenders(arr);
                    setIsItemRemoved(!isItemRemoved);
                  }}
                  selector={radioButtonState}
                /> 
              </div> */}

              {/* <p className="w-[100%] text-[white] text-[14px] text-left mt-[6px]">
                Total Recipient's :{" "}
                <span className="text-[#FE73A5]">{senders?.length}</span>
              </p> */}
            </div>

            <div className="w-[100%] flex justify-center items-center mt-[64px]">
              <div className="w-[100%]">
                {openAutoBox && (
                  <AddTokenIdToAddress
                    senders={senders}
                    setSenders={setSenders}
                    contractAddress={contractAddress}
                    selectedChain={selectedChain}
                    open={openAutoBox}
                    onClose={(list) => {
                      setSenders(list);
                      setOpenAutoBox(false);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="w-[100%] flex justify-center items-center">
              <div className="">
                <button
                  className={`approve-button font-bold w-[100%] py-2 text-[white] text-[16px] font-bold rounded-[10px] bg-[#FE73A5] p-[5px] mt-[14px] ${
                    radioButtonState !== 2
                      ? !transactionButtonDisable
                        ? "opacity-100"
                        : "opacity-30"
                      : !transactionButtonDisable
                      ? "opacity-100"
                      : "opacity-30"
                  }`}
                  disabled={!transactionButtonDisable ? false : true}
                  onClick={() => {
                    if (
                      senders.length > 0 && radioButtonState !== 2
                        ? ethers.utils.isAddress(contractAddress)
                        : true
                    )
                      setSteps((state) => (state += 1));
                    else errorToast("Invalid Entry");
                  }}
                >
                  Continue
                </button>

                <div className="w-[100%] flex justify-center items-center">
                  <div className="w-[263px] mt-[12px] audit-contract-outter">
                    <FaLock color="#4CCBA1" />
                    <p>
                      The smart contracts are audited with <span>Unsnarl.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {importCsvOpen ? (
          <DragAndDrop
            radioButtonState={radioButtonState}
            onFileSelected={handleFile}
            onClose={() => setImportCsvOpen(false)}
          />
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    // console.log("nfts tokens : ", transactionButtonDisable);
  }, [transactionButtonDisable]);

  function PricingModule() {
    return (
      <div>
        <div>
          {radioButtonState == 0 || radioButtonState == 2 ? (
            <NativeAndERC20
              amountInUsd={amountInUsd}
              isErc20={false}
              isPlatformFee={isPlatformFee}
            />
          ) : null}
          {radioButtonState == 1 || radioButtonState == 3 ? (
            <ERC721AndERC1155 />
          ) : null}
        </div>
      </div>
    );
  }

  const NativeAndERC20 = ({ isErc20, isPlatformFee }) => {
    return (
      <div>
        {!isErc20 ? (
          <div className="w-[300px]">
            <div className="w-[100%] flex justify-between items-center">
              <p className="text-[#2E2144] text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                Amount
              </p>{" "}
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                $ {parseFloat(priceInUsd?.usd)?.toFixed(2)}
              </p>
            </div>

            {isPlatformFee ? (
              <div className="w-[100%] flex justify-between items-center">
                <p className=" text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                  Platform Fees
                </p>{" "}
                <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                  {"$ 1"}
                </p>
              </div>
            ) : null}

            <div className="w-[100%] border-b-[2px] border-[#2E2144] my-[10px]"></div>

            <div className="w-[100%] flex justify-between items-center">
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold text-[#73727F] sfNormal">
                Total Amount
              </p>{" "}
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold sfNormal">
                {" "}
                ${" "}
                {getTotalAmountForPricingModule(
                  priceInUsd?.usd,
                  isPlatformFee ? 1 : 0
                )}
              </p>
            </div>
          </div>
        ) : (
          <div className="w-[300px]">
            <div className="w-[100%] flex justify-between items-center">
              <p className="text-[#2E2144] text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                Amount
              </p>{" "}
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                $ {priceInUsd?.usd}
              </p>
            </div>

            {isPlatformFee ? (
              <div className="w-[100%] flex justify-between items-center">
                <p className=" text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                  Platform Fees
                </p>{" "}
                <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                  {"$ 1"}
                </p>
              </div>
            ) : null}

            <div className="w-[100%] border-b-[2px] border-[#2E2144] my-[10px]"></div>

            <div className="w-[100%] flex justify-between items-center">
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold text-[#73727F] sfNormal">
                Total Amount
              </p>{" "}
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold sfNormal">
                {" "}
                ${" "}
                {getTotalAmountForPricingModule(
                  priceInUsd?.usd,
                  isPlatformFee ? 1 : 0
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ERC721AndERC1155 = () => {
    // if the token greater then 100 tokens
    const token = 101;
    // const price = 1000;
    // const isErc20 = true;
    // const tokenName = "USDT";

    // platform fee will be the static number $1

    return (
      <div>
        <div className="w-[300px]">
          {/* <div className="w-[100%] flex justify-between items-center">
            <p className="text-[#2E2144] text-[11px] text-start text-[14px] font-bold text-[#73727F]">
              Amount
            </p>{" "}
            <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
              $ {token}
            </p>
          </div> */}

          {token > 100 ? (
            <div className="w-[100%] flex justify-between items-center">
              <p className=" text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                Platform Fees
              </p>{" "}
              <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                {"$ 1"}
              </p>
            </div>
          ) : null}

          <div className="w-[100%] border-b-[2px] border-[#2E2144] my-[10px]"></div>

          <div className="w-[100%] flex justify-between items-center">
            <p className="text-[white] text-[11px] text-start text-[14px] font-bold text-[#73727F] sfNormal">
              Total Amount
            </p>{" "}
            <p className="text-[white] text-[11px] text-start text-[14px] font-bold sfNormal">
              {" "}
              $ {getTotalAmountForPricingModule(1, 0)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const ApprovalSection = () => {
    return (
      <div className="w-full">
        <div className="w-full flex justify-center items-center pb-[20px]">
          <div className="w-[94%]">
            <div className="mt-[12px]">
              <div className="flex justify-start items-center">
                <IoIosArrowBack
                  className="w-[30px] h-[30px] mr-[12px] cursor-pointer"
                  onClick={() => {
                    setSteps(-1);
                  }}
                  style={{ fontWeight: "bold" }}
                  color="white"
                />
                <p className="text-[36px] sfNormal text-[white] font-600 text-start">
                  List of Recipients
                </p>
              </div>

              <div className="rounded-[14px] overflow-x-hidden overflow-auto no-scroll scrollable-div">
                {/* <ShowTable
                  list={senders}
                  isRemovable={true}
                  selector={radioButtonState}
                  senders={senders}
                  getTotalAmount={getTotalAmount}
                  onItemRemove={(index) => {
                    const arr = senders;
                    arr.splice(index, 1);
                    if (radioButtonState === 0 || radioButtonState === 2) {
                      checkBalance();
                    }
                    setSenders(arr);
                    setIsItemRemoved(!isItemRemoved);
                  }}
                /> */}

                {pagination?.pagination?.length > 0 ? (
                  <ShowTable
                    pagination={pagination?.pagination}
                    list={pagination?.pagination[0]}
                    isRemovable={true}
                    onItemRemove={(index) => {
                      const arr = senders;
                      arr.splice(index, 1);
                      if (radioButtonState === 0 || radioButtonState === 2) {
                        checkBalance();
                      }
                      setSenders(arr);
                      const pagination = ConvertIntoPagination(arr);
                      setPagination(pagination);
                      setIsItemRemoved(!isItemRemoved);
                    }}
                    senders={senders}
                    getTotalAmount={getTotalAmount}
                    selector={radioButtonState}
                    resetState={() => {
                      setSenders([]);
                      setPagination([]);
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div className={`mt-[40px] mb-[40px]`}>
              {radioButtonState !== 2 ? (
                <p className="text-[white] text-[16px] sfNormal text-start text-[14px] font-bold my-[10px]">
                  Amount to Approve
                </p>
              ) : radioButtonState == 2 &&
                getTotalAmount(senders).toFixed(4) > 100 ? (
                <p className="text-[white] text-[16px] sfNormal text-start text-[14px] font-bold my-[10px]">
                  Amount to Approve
                </p>
              ) : null}

              {selectedChain === 137 ? (
                <div className="w-[300px]">
                  <PricingModule />
                  {/* {radioButtonState === 0 || radioButtonState === 2 ? (
                    <div className="w-[100%] flex justify-between items-center">
                      <p className="text-[#2E2144] text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                        Amount
                      </p>{" "}
                      <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                        {radioButtonState === 0
                          ? `$ ${totalAmount?.toFixed(2)} `
                          : radioButtonState === 2
                          ? `$ ${(
                              getTotalAmount(senders) * priceInUsd.usd
                            ).toFixed(2)}`
                          : `$ ${totalAmount?.toFixed(2)}`}
                        {contractInfo?.symbol || ""}
                      </p>
                    </div>
                  ) : null}

                  <div className="w-[100%] flex justify-between items-center">
                    <p className=" text-[11px] text-start text-[14px] font-bold text-[#73727F]">
                      Platform Fees
                    </p>{" "}
                    <p className="text-[white] text-[11px] text-start text-[14px] font-bold">
                      {radioButtonState === 0 || radioButtonState === 2
                        ? getTotalAmount(senders) >= 100
                          ? `${(1 / priceInUsd.usd).toFixed(3)} MAT  ($ ${(
                              set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                            ).toFixed(4)})`
                          : "$0 USD"
                        : senders?.length >= 1
                        ? `${(1 / priceInUsd.usd).toFixed(3)} MAT  ($ ${(
                            set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                          ).toFixed(4)})`
                        : "$0 USD"}

                      {getTotalAmount(senders) >= 100 &&
                      (radioButtonState === 0 || radioButtonState === 2)
                        ? `$ ${
                            isNaN(
                              (
                                set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                              ).toFixed(2)
                            )
                              ? ""
                              : (
                                  set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                                ).toFixed(2)
                          }`
                        : radioButtonState === 1 || radioButtonState === 3
                        ? null
                        : `$ 0`}

                      {senders.length >= 1 &&
                      (radioButtonState === 1 || radioButtonState === 3)
                        ? `$ ${
                            isNaN(
                              (
                                set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                              ).toFixed(2)
                            )
                              ? "$ 1.1193"
                              : (
                                  set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                                ).toFixed(2)
                          }`
                        : radioButtonState === 0 || radioButtonState === 2
                        ? null
                        : `$ 0 USD`}
                    </p>
                  </div>

                  <div className="w-[100%] border-b-[2px] border-[#2E2144] my-[10px]"></div>

                  <div className="w-[100%] flex justify-between items-center">
                    <p className="text-[white] text-[11px] text-start text-[14px] font-bold text-[#73727F] sfNormal">
                      Total Amount
                    </p>{" "}
                    {radioButtonState === 0 || radioButtonState === 2 ? (
                      <p className="text-[white] text-[11px] text-start text-[14px] font-bold sfNormal">
                        {`$ ${
                          getTotalAmount(senders) >= 100
                            ? isNaN(
                                getTotalAmountPay(
                                  totalAmount,
                                  getTotalAmount(senders) >= 100
                                    ? set1USDPrice(priceInUsd.usd) *
                                        priceInUsd.usd
                                    : 0 * priceInUsd.usd
                                )
                              )
                              ? " 1.1193"
                              : getTotalAmountPay(
                                  totalAmount,
                                  getTotalAmount(senders) >= 100
                                    ? set1USDPrice(priceInUsd.usd) *
                                        priceInUsd.usd
                                    : 0 * priceInUsd.usd
                                )
                            : isNaN(
                                getTotalAmountPay(
                                  totalAmount,
                                  getTotalAmount(senders) >= 100
                                    ? set1USDPrice(priceInUsd.usd) *
                                        priceInUsd.usd
                                    : 0 * priceInUsd.usd
                                )
                              )
                            ? " 1.1193"
                            : getTotalAmountPay(
                                totalAmount,
                                getTotalAmount(senders) >= 100
                                  ? set1USDPrice(priceInUsd.usd) *
                                      priceInUsd.usd
                                  : 0 * priceInUsd.usd
                              )
                        } `}
                      </p>
                    ) : radioButtonState === 1 || radioButtonState === 3 ? (
                      <p className="text-[white] text-[11px] text-start text-[14px] font-bold sfNormal">
                        {senders.length >= 1
                          ? ` $ ${
                              isNaN(
                                getTotalAmountPay(
                                  0,
                                  set1USDPrice(priceInUsd.usd) * priceInUsd.usd
                                )
                              )
                                ? "1 "
                                : getTotalAmountPay(
                                    0,
                                    set1USDPrice(priceInUsd.usd) *
                                      priceInUsd.usd
                                  )
                            } `
                          : "$ 0"}
                      </p>
                    ) : null}
                  </div> */}
                </div>
              ) : (
                <>
                  {/* {radioButtonState == 0 || radioButtonState == 2 ? (
                    <>
                    { (radioButtonState == 0 || radioButtonState || 2) ? <p className="text-[white] text-[11px] text-start text-[14px] sfNormal">
                      Total{" "}
                      {radioButtonState === 0
                        ? getTotalAmount(senders).toFixed(2)
                        : radioButtonState === 2
                        ? getTotalAmount(senders).toFixed(2)
                        : ""}{" "}
                      {contractInfo?.symbol}
                    </p> : null}
                    </>
                  ) : <p className="text-[white] text-[11px] text-start text-[14px] sfNormal">Approve Tokens</p>} */}

                  <PricingModule
                    senders={senders}
                    getTotalAmountBySender={getTotalAmount}
                    setPriceInUSD={setPriceInUsd}
                    radioButtonState={radioButtonState}
                    selectedChain={selectedChain}
                    contractInfo={contractInfo}
                    steps={steps}
                  />
                </>
              )}
            </div>

            <div className="w-[100%] flex justify-center items-center">
              <div className="">
                <div
                  className={`approve-button font-bold w-[100%] py-2 text-[white] text-[16px] font-bold rounded-[10px] bg-[#FE73A5] cursor-pointer relative ${
                    !isTransactionStart ? "opacity-100" : "opacity-50"
                  } p-[5px] mt-[30px] flex justify-center items-center`}
                  aria-disabled={isTransactionStart ? true : false}
                  onClick={() => {
                    const totalAmount = getTotalAmount(senders);
                    // console.log(
                    //   "nativebalance",
                    //   contractInfo?.balance,
                    //   totalAmount,
                    //   contractInfo?.balance
                    // );
                    if (!isTransactionStart) {
                      if (radioButtonState === 0) {
                        // if flow for erc20
                        // checinkg the user's balance
                        if (totalAmount < contractInfo?.balance) {
                          requestApproval(senders);
                        } else {
                          errorToast("You have insufficient balance.");
                        }
                      } else {
                        requestApproval(senders);
                      }
                    } else {
                      // console.log("disable");
                    }
                  }}
                >
                  {isTransactionStart ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={isTransactionStart}
                    />
                  ) : null}
                  <p className="ml-2 text-center">
                    {!isTransactionStart
                      ? "Continue"
                      : "Approve Transactions In your Wallet"}
                  </p>
                </div>

                <div className="w-[100%] flex justify-center items-center">
                  <div className="w-[263px] mt-[12px] audit-contract-outter">
                    <FaLock color="#4CCBA1" />
                    <p>
                      The smart contracts are audited with <span>Unsnarl.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CompleteSection = () => {
    return (
      <div className="w-full h-[100%]">
        <div className="w-full h-[100%] flex justify-center items-center mt-[20px]">
          <div className="w-[90%]">
            <div className=" flex justify-center items-center">
              <div className="flex justify-center items-center">
                {isPending ? (
                  <div className="relative top-0 left-0">
                    <img
                      className="w-[701px] h-[622.14]"
                      src={ProgressIcon}
                      alt=""
                    />
                    <img
                      className="absolute top-[168px] left-[308px] spin"
                      src={ProgressProgressIcon}
                      alt=""
                    />

                    <div className="w-[100%] absolute top-[400px] flex justify-center items-center">
                      <div className="">
                        <p className="text-[white] text-[38px] sfNormal font-600">
                          Sending
                        </p>
                        <p className="text-[white] text-[22px] sfNormal font-400 opacity-30">
                          Hold On!{" "}
                          {radioButtonState == 2 || radioButtonState == 4
                            ? "NFT Dropping"
                            : "Token Dropping"}{" "}
                          in a Bit
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  // <RotatingLines
                  //   strokeColor="#FE73A5"
                  //   strokeWidth="5"
                  //   animationDuration="0.75"
                  //   width="20"
                  //   visible={isPending}
                  // />
                  // <MdOutlineDoneOutline className="w-[20px] h-[20px] text-[green]" />
                  <div className="relative top-0 left-0">
                    <img
                      className="w-[701px] h-[622.14]"
                      src={ProgressSuccessIcon}
                      alt=""
                    />
                  </div>
                )}
                {/* <p className="text-[white] font-bold text-[white] ml-2">
                  {isPending ? "pending..." : "Completed"}
                </p> */}
              </div>
            </div>

            <div className="w-[100%] flex flex-col justify-center items-center pb-[40px]">
              <p
                className="w-[200px] sfNormal self-center rounded-md p-[4px] text-[white] font-bold mt-[10px] cursor-pointer"
                onClick={async () => {
                  const { chainId } = await provider.getNetwork();
                  window.open(DEPLOYMENT.explorers[chainId] + hash.hash);
                  // if (chainId === 1) {
                  //   window.open("https://etherscan.io/tx/" + hash.hash);
                  // } else if (id === 80001) {
                  //   console.log("hash", hash);
                  //   window.open("https://mumbai.polygonscan.com/tx/" + hash.hash);
                  // }
                }}
              >
                Explore on blockchain
              </p>
              <p
                className="w-[200px] approve-button font-bold rounded-md self-center sfNormal p-[4px] text-[white] font-bold mt-[10px] mt-[20px] cursor-pointer"
                style={{ opacity: isPending ? 0.5 : 1 }}
                disabled={isPending ? true : false}
                onClick={() => {
                  setSteps(-1);
                  setContractAddress("");
                  setContractInfo({
                    balance: 0,
                    symbol: null,
                  });
                  setSenders([]);
                  setContractLoading(false);
                }}
              >
                Make Next Transaction
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      hash !==
      "0x7109482b955d5e3259d2700e48a7058b23325786bc01258c7cfa5dc7f3813f87"
    ) {
      getTxState();
    }
  }, [hash]);

  return (
    <>
      <div
        className={`w-full flex justify-center items-center mt-[86px] mb-[10px]`}
        // className={`w-full ${
        //   // steps === -1 ? "h-[530px]" : steps === 0 ? "h-[500px]" : "h-[400px]"
        // } flex justify-center items-center mt-[86px] mb-[10px]`}
      >
        <div className="w-[929px] lg:w-[929px] container-gradiant-background pt-[20px] px-[40px]">
          <Stepper steps={steps} setSteps={setSteps} />

          {steps === -1 ? (
            <DataEntrySection />
          ) : steps === 0 ? (
            <ApprovalSection />
          ) : steps === 1 ? (
            <CompleteSection />
          ) : null}
        </div>

        {selecterAddress ? (
          <UserTokenModal
            onTap={(address, logo) => {
              try {
                setContractAddress(address);
                getContractInfo(address);
                // console.log("User Token", address, logo);
                setContractAddressLogo(logo || "");
                toggle(false);
                setSelecterAddresss(false);
              } catch (err) {
                Bugsnag.notify(new Error(err));
                errorToast(err);
              }
            }}
            provider={provider}
            onOutsideTap={() => {
              toggle(false);
              setSelecterAddresss(false);
            }}
          />
        ) : null}

        {/* <ChooseNftSection /> */}
      </div>
    </>
  );
};

export default Main;
