import { useEffect } from "react";
import FriendTech from "../frendtech";
import { errorToast } from "../utils/toast";
import FriendTechChain from "../assets/friendtech_chain.svg";
import useDropzoneHooks from "../components/bundle/useDropzoneHook";

const FriendTechHome = () => {


  const steps = 0;
  const setSteps = () => {

  }
  const {selectedChain} = useDropzoneHooks({setSteps, steps});

  useEffect(() => {
    if(selectedChain !== 8453) {
      errorToast("friend.tech only support Base chain switch Chain to Base")
      // alert("freneds.tech only support Base chain swiitch Chain to Base")
    }
  }, [selectedChain])

  return (
    <div className="w-[100%] bg-[#06040F]">
      <div className="flex justify-center items-center cursor-pointer" onClick={() => {
        window.open("https://www.friend.tech/", "_blank");
      }}>

      <p className=" top-text text-center text-[white] sfNormal">
        You’re on friend.tech
      </p>
      <img className="w-[12px] h-[12px] ml-[4px]" src={FriendTechChain} alt=""/>
      </div>
      <FriendTech />
    </div>
  );
};

export default FriendTechHome;
