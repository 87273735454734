import axios from "axios";

export const getFriendTechHolders = async () => {
  const options = {
    method: "POST",
    url: "https://autov1.onrender.com/friend_tech",
    headers: { collection: "polydogs" },
    data: {
      address: "0x45c1eb5b30090bd284d7f55d74bea70bbefa593d",
      query:
        'query MyQuery {\n                trades(\n                skip: 0\n                first: 1000\n                where: {subject: "0x45c1eb5b30090bd284d7f55d74bea70bbefa593d"}\n                orderBy: shareAmount\n                ) {\n                id\n                isBuy\n                trader\n                shareAmount\n                }\n            }',
    },
  };

  const result = await axios.request(options);

  const arrayOfArrays = [];
  const trades = result?.data?.data?.trades;
  const chunkSize = 25;

  for (let i = 0; i < trades.length; i += chunkSize) {
    const chunk = trades.slice(i, i + chunkSize);
    arrayOfArrays.push(chunk);
  }

  return { data: result?.data, paginationData: arrayOfArrays };
};

export const getFrientectAccountDetails = async (address) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://prod-api.kosetto.com/users/" + address,
    headers: {},
  };

  const result = await axios.request(config);

  return result?.data;
};

export const getFriendTechUsersHolders = async (address) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://prod-api.kosetto.com/users/${address}/token/holders`,
    headers: {},
  };

  const result = await axios.request(config);
  const trades = result?.data?.users;

  const arrayOfArrays = [];
  const chunkSize = 25;

  for (let i = 0; i < trades.length; i += chunkSize) {
    const chunk = trades.slice(i, i + chunkSize);
    arrayOfArrays.push(chunk);
  }

  return {data : result?.data?.users || [], paginationData : arrayOfArrays};
};
