import { MdRocketLaunch } from "react-icons/md";
import useDropsDashboardHooks from "../_utils/DropsDashboardHook";
import { useState } from "react";
import { FaCircle } from "react-icons/fa";

const Dashboard = ({ setDataToTheFeild, setIsDashboard, setIsBrandDetail }) => {
  const { drops, brand } = useDropsDashboardHooks();
  const [option, setOptions] = useState(1);
  return (
    <div className="flex flex-col items-start">
      <div className="flex">
        <div className="flex justify-center items-start ">
          <button
            className="ml-[6px] px-[12px] min-w-[160px] py-[10px] rounded-[6px] flex flex-row justify-center sfBold text-[white] bg-[#1B1833] "
            style={{ opacity: option == 0 ? 1 : 0.4 }}
            onClick={() => {
              setOptions(0);
              setIsBrandDetail(false);
              setIsDashboard(false);
            }}
          >
            {/* <MdRocketLaunch className=" mr-[6px]" color="white" size={20} /> */}
            LAUNCH DROPZ
          </button>
          <button
            className="ml-[6px] px-[12px] min-w-[160px] py-[10px] rounded-[6px] flex flex-row justify-center sfBold text-[white] bg-[#1B1833] "
            style={{ opacity: option == 1 ? 1 : 0.4 }}
            onClick={() => {
              setIsBrandDetail(false)
              setOptions(1);
            }}
          >
            <MdRocketLaunch className=" mr-[6px]" color="white" size={20} /> MY
            DROPZ
          </button>
          <button
            className="ml-[6px] px-[12px] min-w-[160px] py-[10px] rounded-[6px] flex flex-row justify-center sfBold text-[white] bg-[#1B1833] "
            style={{ opacity: option == 2 ? 1 : 0.4 }}
            onClick={() => {
              setDataToTheFeild({}, "");
              setIsBrandDetail(true)
              setOptions(2);
            }}
          >
            {/* <MdRocketLaunch className=" mr-[6px]" color="white" size={20} />  */}
            MY PROFILE
          </button>
        </div>
      </div>
      <div className="w-[100%] h-[100%]">
        {option == 1 && (
          <div className="w-[100%]  mt-[40px] flex justify-center items-center">
            {drops?.length > 0 ? (
              <div className=" h-[500px] overflow-y-scroll">
                <p className="text-[24px] sfBold mb-[12px]  text-[#FB596F]">
                  Dropz
                </p>
                {drops.map((item) => (
                  <button
                    onClick={() => {
                      //   setSelectedDrop(item);
                      setDataToTheFeild(item, brand);
                    }}
                    className="h-[56px] w-[350px] my-2  gradientButton rounded-[16px] flex items-center justify-center"
                  >
                    <div className="h-[54px] w-[348px] px-2 flex flex-row justify-start items-center rounded-[16px] bg-[rgba(0,0,0,0.88)] backdrop-blur-md">
                      <img
                        src={item?.imageUrl}
                        className={"w-[48px] h-[48px] rounded-xl mr-2"}
                      />
                      <div className="w-[80%] flex justify-between items-center">
                      <p className="sfBold text-[white]">{item?.dropName}</p>
                      {(item?.isLive) ? <FaCircle color="green" width={24} height={24} /> : null}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="w-[100%] h-[500px] mt-[40px] flex justify-center items-center">
                <p className="text-[24px] sfBold mb-[12px]  text-[gray]">No Drops</p>
              </div>
            )}
          </div>
        )}

        {option == 2 && (
          <div className="w-[100%] mt-[40px] flex justify-center items-center overflow-hidden">
            <div className="w-[80%]">
              <p className="text-[24px] sfBold mb-[12px]  text-[#FB596F]">
                Brand Details
              </p>
              <p className="text-[18px] sfNormal my-[8px]  text-[white]">
                Brand Name : {brand?.name}
              </p>
              <p className="text-[18px] sfNormal my-[8px]  text-[white]">
                Username : @{brand?.username}
              </p>
              <p className="text-[18px] sfNormal my-[8px]  text-[white]">
                Email : {brand?.emailAddress}
              </p>
              <p className="text-[18px] sfNormal my-[8px]  text-[white]">
                Website : <a href={brand?.website}>{brand?.website}</a>
              </p>
              <p className="text-[18px] sfNormal my-[8px]  text-[white]">
                Twitter : <a href={brand?.twitterUrl}>{brand?.twitterUrl}</a>
              </p>
              <p className="text-[18px] sfNormal mly-[8px]  text-[white]">
                Discord : <a href={brand?.discordUrl}>{brand?.discordUrl}</a>
              </p>
              <p className="text-[18px] sfNormal my-[8px]  text-[white]">
                Telegram : <a href={brand?.telegram}>{brand?.telegram}</a>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
