import { useRef } from "react";
import DropZoneLogo from "../../assets/DropZone.svg";
import { useNavigate } from "react-router-dom";
import Logout from "../../assets/Logout.svg";
import { useAccount, useDisconnect } from "wagmi";
import CustomWalletConnect from "../../components/bundle/_components/CustomWalletConnect";
import LogoutButton from "./logout";
import { successToast } from "../../utils/toast";
import Bugsnag from "@bugsnag/js";

const HeaderDropz = () => {
  const openRef = useRef();
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const wagmi_disconnect = useDisconnect();
  // const { open } = useWeb3Modal();

  const isWalletConnected = () => {
    const isWalletConnected =
      window.localStorage.getItem("wagmi.connected") || isConnected || false;
    return isWalletConnected;
  };

  const logout = async () => {
    localStorage.removeItem("brand");
    localStorage.clear();
    await wagmi_disconnect
      .disconnectAsync()
      .then(() => {
        successToast("disconnected")
        navigate(-1)
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      })
      .catch((err) => {
        Bugsnag.notify(new Error("Loguot Failed" || err))
      });
    setTimeout(() => {
      window.location.reload();
    }, 3000);

  };

  return (
    <>
      <nav className="w-[90%] border-gray-200 top-[40px] absolute fixed  flex justify-center items-center">
        <div className="w-[90%] flex flex items-center justify-between rounded-full header-background">
          <div className="flex">
            <a href="/" className="flex items-center">
              <img
                src={DropZoneLogo}
                className="w-[120px] h-[60px] header-image"
                alt="Flowbite Logo"
              />
            </a>

            <a href="https://friend.tech" className="flex items-center"></a>
          </div>
          <div className="flex items-center md:order-2">
            {/* <NetworkDropDrown className="ml-8" /> */}
            <CustomWalletConnect openAccountModal={false} />
            {isWalletConnected() ? (
              <div className="flex justify-center items-center">
                <LogoutButton
                  icon={Logout}
                  text={"Logout"}
                  open={false}
                  onClick={() => {
                    logout();
                    // openUrl("https://twitter.com/kometverse");
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderDropz;
