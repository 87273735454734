import { collection, getDocs } from "firebase/firestore";
import { AddPOAP, EditPOAP, getDropBanner } from "../utils/DropFunctions";
import { db } from "../utils/firebase";
import { errorToast, successToast } from "../utils/toast";
import Bugsnag from "@bugsnag/js";


  export const getData = async (wallet,setDrops) => {
    const data = await getDropBanner(wallet);
    if (data && setDrops) {
        // console.log('Data',data)
      setDrops(data);
    }
    return data
  };

  export const submitInfo = async (setLoading,dropName,twitterUrl,imageUrl,wallet,number,email,description,utility,telegramId,contractAddress,setSuccess) => {
    setLoading(true);
    try {
      await AddPOAP(
        twitterUrl,
        description,
        imageUrl,
        dropName,
        utility,
        email,
        wallet,
        dropName,
        number,
        null,
        telegramId,
        contractAddress
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      successToast(
        "Drop Added. Please wait until is gets approved. You can see the status on your dashboard"
      );
      setSuccess(true)
      getData();
    } catch (err) {
      Bugsnag.notify(new Error(err))
      setLoading(false);
      successToast("Error. Please try again.");
    }
  };
  export const EditInfo = async (id,dropName,twitterUrl,imageUrl,wallet,number,email,description,utility,telegramId,contractAddress,setLoading,enabled,code) => {
    // console.log(telegramId,contractAddress)
    
    setLoading(true);
    try {
      await EditPOAP(
        twitterUrl,
        description,
        imageUrl,
        dropName,
        utility,
        email,
        wallet,
        dropName,
        number,
        id,
        telegramId,
        contractAddress,
        enabled,
        code
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      successToast(
        "Drop Updated."
      );
      getData();
    } catch (err) {
      Bugsnag.notify(new Error(err));
      setLoading(false);
      errorToast("Error. Please try again.");
    }
  };


  export const DisableDrop = async (id,dropName,twitterUrl,imageUrl,wallet,number,email,description,utility,telegramId,contractAddress,setLoading,code) => {

    setLoading(true);
    try {
      await EditPOAP(
        twitterUrl,
        description,
        imageUrl,
        dropName,
        utility,
        email,
        wallet,
        dropName,
        number,
        id,
        telegramId,
        contractAddress,
        false,
        code
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      successToast(
        "Drop Updated."
      );
      getData();
    } catch (err) {
      setLoading(false);
      Bugsnag.notify(new Error(err));
      errorToast("Error. Please try again.");
    }
  };



  export const getDropUsers = async (name) => {
    let arr = []
    // console.log(`${name}_users`)
    const querySnapshot = await getDocs(collection(db, `${name}_users`));
    //console.log(querySnapshot.docs)

    querySnapshot.forEach((doc) => {
        
            arr.push(doc.data())
        

    });
    // console.log('ArrDt',arr)
    return arr
}