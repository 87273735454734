import React, { useEffect, useState } from "react";
import LaunchStep1 from "./components/LaunchStep1";
import LaunchStep2 from "./components/LaunchStep2";
import LaunchStep3 from "./components/LaunchStep3";
import { errorToast, successToast } from "../utils/toast";
import HeaderDropz from "./_components/Header";
import {
  DisableDropId,
  createActivities,
  createActivitiesV2,
  createBrandId,
  createDropId,
  updateDropId,
} from "./api/SubmitForm";
import { useNavigate } from "react-router-dom";
import { fetchBrand } from "./api/fetchDropz";
import { useAccount, useDisconnect } from "wagmi";
import { isAddress } from "ethers/lib/utils.js";
import UpdateActivites from "./_components/updateActivities";
import useDropsDashboardHooks from "./_utils/DropsDashboardHook";
import WarringDialog from "../pages/WarringDialog";
import Bugsnag from "@bugsnag/js";

export default function Launch() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [step1Data, setStep1Data] = useState({});
  const [step2Data, setStep2Data] = useState({});
  const [step3Data, setStep3Data] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateWhitelist, setUpdateWhitelist] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const { address } = useAccount();
  const { getUserBradByWallet } = useDropsDashboardHooks();


  const ApplyWhiteListings = async (step3Datas) => {
    try {
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setLoading(true);
      const formData = { ...step1Data, ...step2Data, ...step3Datas };
      // console.log("form Data", formData);

      // console.log(brand);

      if (!brand) {
        const brandId = await createBrandId(formData);
        const dropId = await createDropId(formData, brandId);
        if (dropId) {
          const array = Object.values(formData?.activities).filter(
            (data) => data !== ""
          );
          const activityData = await createActivies(formData?.activities, dropId);
          const data = await createActivitiesV2(activityData);
          // console.log(data);
            if (data) {
              if (updateWhitelist) {
                successToast("Dropz changes saved (👏)");
              } else {
                successToast(
                  "Dropz submitted(👏), soon will be reviewed by Komet team."
                );
              }
              setLoading(false);
              setIsDashboard(true);
              resetFeilds();
              // const brand = JSON.parse(localStorage.getItem("brand")) || false;
              setStep(brandId ? 2 : 1);
              navigate("/dropz");
            }
        }
      } else if (brand?.id !== null && brand?.id !== undefined) {
        const dropId = await createDropId(formData, brand?.id);
        if (dropId) {
          // console.log(dropId);
          if (formData?.activities) {
            const array = Object.keys(formData?.activities).filter(
              (data) => data !== ""
            );
            // console.log(dropId, array, array?.length);
            const activityData = await createActivies(formData?.activities, dropId);
            // console.log(activityData);
            const data = await createActivitiesV2(activityData);
            // console.log(data);
            if (data) {
              if (updateWhitelist) {
                successToast("Dropz changes saved (👏)");
              } else {
                successToast(
                  "Dropz submitted(👏), soon will be reviewed by Komet team."
                );
              }
              setLoading(false);
              setIsDashboard(true);
              resetFeilds();
              // const brand = JSON.parse(localStorage.getItem("brand")) || false;
              setStep(brand ? 2 : 1);
              navigate("/dropz");
            }
          } else {
            setLoading(false);
            setIsDashboard(true);
            setStep(brand ? 2 : 1);
            resetFeilds();
            if (updateWhitelist) {
              successToast("Dropz changes saved (👏)");
            } else {
              successToast(
                "Dropz submitted(👏), soon will be reviewed by Komet team."
              );
            }
            navigate("/dropz");
          }
        }
      } else {
        setStep(brand ? 2 : 1);
        setLoading(false);
        errorToast("brandId is missing.");
      }
    } catch (err) {
      Bugsnag.notify(new Error(err))
      errorToast("Submission failed");
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      setLoading(false);
      console.log(err);
    }
  };

  const UpdateWhiteListings = async (step3Datas) => {
    try {
      // console.log("update entry point", updateWhitelist)
      if (updateWhitelist?.nftId && updateWhitelist?.brandId) {

        const brand = JSON.parse(localStorage.getItem("brand")) || false;
        setLoading(true);
        const formData = {
          ...step1Data,
          ...step2Data,
          ...step3Datas,
          id: updateWhitelist?.nftId,
          dropzBrandId: updateWhitelist?.brandId,
        };
        // console.log("form Data", formData);
  
  
        if (!brand) {
          const dropId = await updateDropId(
            formData,
            brand?.id || updateWhitelist?.brandId
          );
          if (dropId) {
            const array = Object.values(formData?.activities).filter(
              (data) => data !== ""
            );
            // createActivitesForTwitter(formData, array, 0, dropId);
          }
        } else if (brand?.id !== null && brand?.id !== undefined) {
          const dropId = await updateDropId(formData, brand?.id);
          if (dropId) {
              setLoading(false);
              setIsDashboard(true);
              successToast("Submitted");
              setStep(brand ? 2 : 1);
              resetFeilds();
          }
        } else {
          setStep(brand ? 2 : 1);
          setLoading(false);
          errorToast("brandId is missing.");
        }
      } else {
        errorToast("Unable to update drop");
      }
    } catch (err) {
      errorToast("Submission failed");
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      setLoading(false);
      console.log(err);
    }
  };

  const disableDrop = async (disable) => {
    try {
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setLoading(true);
      const formData = {
        ...step1Data,
        ...step2Data,
        ...step3Data,
        id: updateWhitelist?.nftId,
        dropzBrandId: updateWhitelist?.brandId,
      };


      if (!brand) {
        const dropId = await DisableDropId(
          formData,
          brand?.id || updateWhitelist?.brandId,
          disable
        );

        if (dropId) {
          setLoading(false);
          setIsDashboard(true);
          setStep(brand ? 2 : 1);
          resetFeilds();
          successToast("Setting Saved");
        }
      } else if (brand?.id !== null && brand?.id !== undefined) {
        const dropId = await DisableDropId(formData, brand?.id, disable);
        if (dropId) {
          setLoading(false);
          setIsDashboard(true);
          setStep(brand ? 2 : 1);
          resetFeilds();
          successToast("Setting Saved");
        }
      } else {
        setStep(brand ? 2 : 1);
        setLoading(false);
        errorToast("brandId is missing.");
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      setLoading(false);
      errorToast("Submission failed");
      console.log(err);
    }
  };

  const createActivies = (value, dropId) => {
    let finalArray = [];
    const key = Object.keys(value);
    key.forEach(async (element) => {
      if (element == "retweet" && value[element] !== "") {
        const username = await getTwitterId(value[element]);
        const a = {
          payload: username,
          tweetUrl: value[element],
          points: 10,
          dropId: dropId,
          type: "RETWEET",
        }
        finalArray.push(a);
      } else if (element == "like" && value[element] !== "") {
        const username = await getTwitterId(value[element]);
        const b = {
          payload: username,
          tweetUrl: value[element],
          points: 10,
          dropId: dropId,
          type: "LIKE",
        }
        finalArray.push(b);
      }
    });
    return finalArray;
  };

  const createActivitesForTwitter = async (formData, array, index, dropId) => {
    if (index < array?.length) {
      if (
        formData?.activities?.follow !== "" &&
        formData?.activities?.follow == array[index]
      ) {
        const username = await getTwitterUsername(array[index]);
        const info = {
          payload: username,
          tweetUrl: array[index],
          dropId: dropId,
          type: "FOLLOW",
        };
        // console.log(
        //   formData?.activities?.follow,
        //   formData?.activities?.follow == array[index],
        //   info
        // );
        const activity = await createActivities(info);
      } else if (
        formData?.activities?.retweet !== "" &&
        formData?.activities?.retweet !== array[index]
      ) {
        const username = await getTwitterId(array[index]);
        // console.log(
        //   formData?.activities?.retweet,
        //   formData?.activities?.retweet == array[index]
        // );
        const info = {
          payload: username,
          tweetUrl: array[index],
          dropId: dropId,
          type: "RETWEET",
        };
        const activity = await createActivities(info);
      } else if (
        formData?.activities?.like !== "" &&
        formData?.activities?.like == array[index]
      ) {
        const username = await getTwitterId(array[index]);
        // console.log(
        //   formData?.activities?.like,
        //   formData?.activities?.like == array[index]
        // );
        const info = {
          payload: username,
          tweetUrl: array[index],
          dropId: dropId,
          type: "LIKE",
        };
        const activity = await createActivities(info);
      }

      let newIndex = index + 1;
      createActivitesForTwitter(formData, array, newIndex, dropId);
      setLoading(false);
    } else {
      if (updateWhitelist) {
        successToast("Dropz changes saved (👏)");
      } else {
        successToast(
          "Dropz submitted(👏), soon will be reviewed by Komet team."
        );
      }
      setLoading(false);
      setIsDashboard(true);
      resetFeilds();
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      navigate("/dropz");
    }
  };

  const getTwitterUsername = (url) => {
    return url?.replace("https://twitter.com/", "");
  };

  const getTwitterId = (url) => {
    // return (url?.substring(url?.toString()?.lastIndexOf("/") + 1))?.toString()?.replace("?s=20","");
    return url?.toString()?.substring(url?.toString()?.lastIndexOf("/") + 1, url?.toString()?.lastIndexOf("?"));
  };

  useEffect(() => {
    getCreatorBrandId(address);
  }, [address]);

  const getCreatorBrandId = async (address) => {
    try {
      const result = await fetchBrand(address);
      if (result?.length > 0 && result !== undefined) {
        setIsDashboard(true);
        setStep(2);
        window.localStorage.setItem("brand", JSON.stringify(result[0]));
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      errorToast("err : while fetching you account");
    }
  };

  useEffect(() => {
    const isBrand = localStorage.getItem("brand");
    if (isBrand) {
      setStep(2);
      setUpdateWhitelist(false);
    }
  }, [isDashboard]);

  // useEffect(() => {

  // }, [updateWhitelist]);

  const setDataToTheFeild = (data, brands) => {
    setUpdateWhitelist({ nftId: data?.id, brandId: data?.dropzBrandId });

    setStep1Data({
      url: brands?.imageUrl,
      chain: brands?.chain,
      name: brands?.name,
      twitterUrl: brands?.twitterUrl,
      discordUrl: brands?.discordUrl,
      siteUrl: brands?.website,
      email: brands?.emailAddress,
      walletAddress: brands?.walletAddress,
      telegram: brands?.telegram,
    });

    setStep2Data({
      title: data?.dropName,
      dropzType: data?.type,
      thumbnail: data?.imageUrl,
      description: data?.description,
      rafflesActivity: "",
      activities: data?.activities,
      startLaunch: data?.activeFrom,
      endLaunch: data?.activeTo,
      id: data?.id,
      isLive: data?.isLive,
      passcode: data?.passcode,
      utility : data?.utility
    });

    setStep3Data({
      rewardOne: data?.reward1,
      rewardTwo: data?.reward2,
      rewardType: data?.rewardType,
      contractAddress: data?.contractAddress,
      MetadataLink: data?.MetadataLink,
    });

    // console.log(data, brands, step1Data, step2Data, step3Data);
  };

  const resetFeilds = () => {
    // setUpdateWhitelist({ nftId: data?.id, brandId: data?.dropzBrandId });

    setStep1Data({
      url: "",
      chain: "",
      name: "",
      twitterUrl: "",
      discordUrl: "",
      siteUrl: "",
      email: "",
      walletAddress: "",
      telegram: "",
    });

    setStep2Data({
      title: "",
      dropzType: "",
      thumbnail: "",
      description: "",
      rafflesActivity: "",
      activities: "",
      startLaunch: "",
      endLaunch: "",
    });

    setStep3Data({
      rewardOne: "",
      rewardTwo: "",
      rewardType: "",
      contractAddress: "",
      MetadataLink: "",
    });

  };

  useEffect(() => {
    if (!isDashboard) {
      resetFeilds()
    }
  }, [isDashboard])


  return (
    <div className="w-[100%] flex justify-center items-center relative">
      <div className="max-w-[1440px] justify-center self-center w-[100%] flex">
        <div className="w-[85%]">
          <div className="w-[100%] h-[160px] flex justify-center items-center">
            <HeaderDropz />
          </div>

          {step === 1 && (
            <LaunchStep1
              step={step}
              setStep={setStep}
              step1Data={step1Data}
              setStep1Data={setStep1Data}
              isDashboard={isDashboard}
              setDataToTheFeild={setDataToTheFeild}
              setIsDashboard={setIsDashboard}
            />
          )}

          {step === 2 && (
            <LaunchStep2
              step={step}
              setStep={setStep}
              step2Data={step2Data}
              setStep2Data={setStep2Data}
              isDashboard={isDashboard}
              setIsDashboard={setIsDashboard}
              setDataToTheFeild={setDataToTheFeild}
              onDisabledClick={disableDrop}
              isUpdate={updateWhitelist?.nftId ? true : false}
            />
          )}

          {step === 3 && (
            <LaunchStep3
              onSubmit={ApplyWhiteListings}
              onUpdate={UpdateWhiteListings}
              loading={loading}
              step={step}
              setStep={setStep}
              step3Data={step3Data}
              setStep3Data={setStep3Data}
              isDashboard={isDashboard}
              setDataToTheFeild={setDataToTheFeild}
              setIsDashboard={setIsDashboard}
              isUpdate={updateWhitelist?.nftId ? true : false}
            />
          )}

          {/* <Footer /> */}
        </div>
      </div>

      {/* <ViewData /> */}

      <WarringDialog />
    </div>
  );
}

// import React, { useState } from "react";
// import DropzForm from "./_components/DropzForm";
// import HeaderDropz from "./_components/Header";
// import "../frendtech/style/index.css";
// import { FaLock } from "react-icons/fa";
// import { useAccount } from "wagmi";
// import { submitInfo } from "./_utils";
// import Success from "../assets/Success.svg";
// import { useNavigate } from "react-router-dom";
// import { errorToast } from "../utils/toast";
// import DropzFooter from "./_components/DropzFooter";

// const Dropz = () => {
//   const { address } = useAccount();
//   const [name, setName] = useState("");
//   const navigate = useNavigate();
//   const [creatorTwitterUrl, setCreatorTwitterUrl] = useState("");
//   const [number, setNumber] = useState(0);
//   const [imageUrl, setImageUrl] = useState("");
//   const [wallet, setWallet] = useState(address);
//   const [email, setEmail] = useState("");
//   const [description, setDescription] = useState("");
//   const [utility, setUtility] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [telegramId, setTelegramId] = useState("");
//   const [contractAddress, setContractAddress] = useState("");
//   React.useEffect(() => {
//     setWallet(address);
//   }, [address]);

//   return (
//     <div className="w-[100%] relative h-screen">
//       <HeaderDropz />
//       {success ? (
//         <div className="flex flex-col w-[100%] h-full items-center justify-center background-image">
//           <img src={Success} />
//           <p className="text-[32px] text-[#F5F5FA] sfSemiBold">
//             Drop Has Launched Successfully
//           </p>
//           <button
//             onClick={() => {
//               navigate("/mydropz");
//             }}
//             className="w-[260px] rounded-[4px] successButton px-[16px] py-[8px] text-[white] mt-[24px] sfBold text-[16px] "
//           >
//             See My Dropz
//           </button>
//         </div>
//       ) : (
//         <div className="w-[100%] flex flex-col  items-center background-image overflow-x-hidden ">
//           <div className="self-center justify-center w-[100%] self-center flex borderBottom dropBackground flex-row pt-[156px] ">
//             <DropzForm
//               name={name}
//               setName={setName}
//               creatorTwitterUrl={creatorTwitterUrl}
//               setCreatorTwitterUrl={setCreatorTwitterUrl}
//               number={number}
//               setNumber={setNumber}
//               imageUrl={imageUrl}
//               setImageUrl={setImageUrl}
//               wallet={wallet}
//               setWallet={setWallet}
//               email={email}
//               setEmail={setEmail}
//               description={description}
//               setDescription={setDescription}
//               utility={utility}
//               setUtility={setUtility}
//               contractAddress={contractAddress}
//               setContractAddress={setContractAddress}
//               telegramId={telegramId}
//               setTelegramId={setTelegramId}
//             />
//           </div>

//           <div className="w-[1440px] self-center h-[90px] px-[80px] flex items-center dropBackground justify-end bg-[#050505]">
//             <>
//               <FaLock color="#4CCBA1" />
//               <p className="text-[14px] text-[white] regularFont mt-1 mx-2">
//                 Secured by 256 End-to-end Encryption
//               </p>
//             </>
//             <button
//               onClick={() => {
//                 if (address && name) {
//                   submitInfo(
//                     setLoading,
//                     name,
//                     creatorTwitterUrl,
//                     imageUrl,
//                     wallet,
//                     number,
//                     email,
//                     description,
//                     utility,
//                     telegramId,
//                     contractAddress,
//                     setSuccess
//                   );
//                 } else {
//                   if (address) {
//                     errorToast("Enter all the fields");
//                   } else {
//                     errorToast("Connect Wallet to Submit");
//                   }
//                 }
//               }}
//               type="submit"
//               className=" ml-[40px] mt-1 h-[48px] w-[144px] gradientButton sfSemiBold text-[white] rounded-[4px]"
//             >
//               <p className="text-[14px] text-[white] regularFont mx-2">
//                 {loading ? "Loading" : "Submit"}
//               </p>
//             </button>
//           </div>
//         </div>
//       )}
//       {/* <DropzFooter /> */}
//       <DropzFooter />
//     </div>
//   );
// };

// export default Dropz;
