import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

const LogoutButton = ({ icon, text, onClick , open}) => {
  return (
    <button
      onClick={(e) => {
        e?.preventDefault();
        // setOpen(!open)
        onClick();
      }}
      className="w-[100%] flex justify-between border-[gray] border-[1px] rounded-[6px] ml-[24px] p-1"
    >
      <div className="flex jutify-center items-center">
        <img alt="" className="w-[24px] h-[24px]" src={icon} />
        <p className="text-[14px] text-[bold] text-[white] sfNormal mx-[12px]">{text}</p>
      </div>
      {/* {open ? (
        <MdKeyboardArrowDown color="white" size={20} />
      ) : (
        <MdKeyboardArrowRight color="white" size={20} />
      )} */}
    </button>
  );
};

export default LogoutButton;
