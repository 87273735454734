import { useWeb3Modal } from "@web3modal/react";
import { useEffect, useRef, useState } from "react";
import DropZoneLogo from "../../../assets/DropZone.svg";
import DropzLogo from "../../../assets/dropz_icon.svg";
import { useNavigate } from "react-router-dom";
import useScrollLock from "./useScrollLock";
import NetworkDropDrown from "./networkDropDrown";
import { useAccount } from "wagmi";
import CustomWalletConnect from "./CustomWalletConnect";
import { errorToast } from "../../../utils/toast";

const Header = () => {
  const { isConnected } = useAccount();
  const [notify] = useState(false);
  const openRef = useRef();
  const { open } = useWeb3Modal();

  const navigate = useNavigate();

  useEffect(() => {
    if (notify) {
      openRef?.current?.classList?.remove("slider-right");
      openRef?.current?.classList?.add("slider-left");
    } else {
      openRef?.current?.classList?.remove("slider-left");
      openRef?.current?.classList?.add("slider-right");
    }
  }, [notify]);

  const isWalletConnected = () => {
    const isWalletConnected =
      window.localStorage.getItem("wagmi.connected") || isConnected || false;
    // console.log(isConnected, isWalletConnected);
    return isWalletConnected;
  };

  return (
    <>
      <nav className="w-[100%] border-gray-200 py-2.5 flex justify-center items-center">
        <div className="w-[90%] flex flex items-center justify-between rounded-full header-background">
          <a href="/" className="flex items-center">
            <img
              src={DropZoneLogo}
              className="w-[120px] h-[60px] header-image"
              alt="Flowbite Logo"
            />
          </a>

          <div className="flex items-center md:order-2">
            <NetworkDropDrown className="ml-[16px]" />

            <div
              className="w-[156px] h-[32px] bg-[#1B1833] flex justify-center items-center p-[7px] cursor-pointer mx-[16px]"
              onClick={() => {
                if (isWalletConnected()) {
                  navigate("/dropz");
                } else {
                  errorToast("please connect your wallet");
                }
                // navigate("/mydropz");
              }}
            >
              <button className="w-[156px] h-[33px] flex justify-center items-center bg-[#1B1833]">
                <img
                  src={DropzLogo}
                  className="w-[14px] h-[14px] header-image"
                  alt="Flowbite Logo"
                />
                <p className="text-[white] ml-[12px] sfNormal text-[12px] font-bold">
                  LAUNCH DROPZ
                </p>
              </button>
            </div>

            <CustomWalletConnect openAccountModal={true} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
