import { useWeb3Modal } from "@web3modal/react";
import { useEffect } from "react";
import { useAccount } from "wagmi";

const CustomWalletConnect = ({ openAccountModal }) => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();

  const isWalletConnected = () => {
    const isWalletConnected =
      window.localStorage.getItem("wagmi.connected") || isConnected || false;
    // console.log(isConnected, isWalletConnected);
    return isWalletConnected;
  };

  useEffect(() => {
    if (localStorage.getItem(address) && address == undefined) {
      alert("please reconnect your wallet");
    }
  }, [address]);

  return (
    <button
      className="w-[126px] h-[33px]  rounded-[4px] p-[5px]"
      style={{
        background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
      }}
      onClick={() => {
        if (openAccountModal) {
          open();
        } else if (!isWalletConnected()) {
          open({ view: "Connect" });
        }
      }}
    >
      {isWalletConnected() && address !== undefined  ? (
        <div className="flex justify-center items-center">
          <div
            className="w-[18px] h-[18px] rounded-full mr-[6px]"
            style={{
              backgroundColor: "rgba(95,97,26,1)",
              backgroundImage:
                "radial-gradient(at 66% 77%, rgba(95,97,26,1)  0,rgba(59,111,19,1) 50%),radial-gradient(at 29% 97%, rgba(51,60,216,1) 0,transparent 50%),radial-gradient(at 99% 86%,rgba(76,51,137,1) 0,transparent 50%),radial-gradient(at 29% 88%,var(--w3m-color-av-5) 0,transparent 50%)",
            }}
          >
            {" "}
          </div>
            <p className="text-[14px] text-[white] sfNormal">
              {address?.toString()?.substring(0, 4) +
                "..." +
                address
                  ?.toString()
                  ?.substring(
                    address?.toString()?.length - 4,
                    address?.toString()?.length
                  )}
            </p>
        </div>
      ) : (
        <p className="text-[14px] text-[white] sfNormal">Connect Wallet</p>
      )}
    </button>
  );
};

export default CustomWalletConnect;
