import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import LaunchPartnerForm from "./LaunchPartnerForm";
import ProgressBar from "./ProgressBar";
import RafflesActivities from "./RafflesActivities";
import Dashboard from "./Dashboard";
import { IoArrowBack } from "react-icons/io5";
import UpdateActivites from "../_components/updateActivities";
import ViewData from "./viewData";
import { errorToast, successToast } from "../../utils/toast";

export default function LaunchStep2({
  step2Data,
  setStep2Data,
  step,
  setStep,
  isDashboard,
  setIsDashboard,
  setDataToTheFeild,
  isUpdate,
  onDisabledClick,
}) {
  const [title, setDropzTitle] = useState(step2Data?.title || "");
  const [thumbnail, setThumbnail] = useState(step2Data?.thumbnail || "");
  const [description, setDescription] = useState(step2Data?.description || "");
  const [raffles, setRaffles] = useState(step2Data?.raffles || "");
  const [activities, setActivities] = useState(
    step2Data?.activities || {
      like: "",
      follow: "",
      retweet: "",
      passcode: "",
    }
  );
  const [selectedType, setSelectedType] = useState("CLAIM");
  const [passcode, setPasscode] = useState("");
  const [selectedCategoryType, setSelectedCategoryType] =
    useState("Brand Rewards");
  const [startLaunchDate, setStartLaunchDate] = useState(
    "2023-05-30T00:00:00.000+00:00"
  );
  const [endLaunchDate, setEndLaunchDate] = useState(
    "2023-05-30T00:00:00.000+00:00"
  );
  const [isActivityEdit, setIsActivityEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dropId, setDropId] = useState(0);
  const [viewData, setViewData] = useState(false);
  const [isBrandDetail, setIsBrandDetail] = useState(false);

  useEffect(() => {
    setDropzTitle(step2Data?.title);
    setThumbnail(step2Data?.thumbnail);
    setDescription(step2Data?.description);
    setRaffles(step2Data?.raffles);
    setActivities(
      step2Data?.activities || {
        like: "",
        follow: "",
        retweet: "",
        passcode: "",
      }
    );
    setPasscode(step2Data?.passcode);
    setSelectedType(step2Data?.dropzType || "CLAIM");
    setStartLaunchDate(
      step2Data?.activeFrom || `${new Date().getFullYear()}-${((new Date().getMonth() + 1) < 9)? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)}-${(new Date().getDate() < 9)?  "0" + new Date().getDate()  : new Date().getDate()}T00:00:00.000+00:00` || "2023-05-30T00:00:00.000+00:00"
    );
    setEndLaunchDate(step2Data?.activeTo || `${new Date().getFullYear()}-${((new Date().getMonth() + 1) < 9)? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)}-${(new Date().getDate() < 9)?  "0" + new Date().getDate()  : new Date().getDate()}T00:00:00.000+00:00` || "2023-05-30T00:00:00.000+00:00");
    setDropId(step2Data?.id);
    setIsDisabled(step2Data?.isLive);
    setDropId(step2Data?.id);
    setSelectedCategoryType(step2Data?.utility || "Brand Rewards");
  }, [step2Data]);

  const handlePasscode = (text) => {
    setPasscode(text);
  };

  const [locationKeys, setLocationKeys] = useState([]);

  const labelStyle =
    "w-[100%] ml-10 self-start text-[white] my-2 text-[15px] semiBoldFont";
  return (
    <div className="w-[100%] h-screen flex flex-col mt-[-100px]">
      <div className="flex-col  w-[100%] pt-[100px] lauch-bg flex ">
        <div
          className={`flex flex-1 border-[white] ${
            false ? " flex-col" : " flex-row"
          } border-b`}
        >
          <div className="flex flex-1 flex-col items-center py-4 justify-around border-[white] border-r">
            <p className="text-[white] text-[20px] regularFont "></p>
            {!isDashboard ? (
              <div className="flex flex-col w-[100%] px-6 my-2 ">
                <div className="w-[100%] h-[120px]">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      const isBrand = localStorage.getItem("brand");
                      if (isBrand) {
                        setIsDashboard(true);
                      } else {
                        setStep((step) => step - 1);
                      }
                    }}
                  >
                    <IoArrowBack
                      className="w-[25px] h-[25px] cursor-pointer"
                      color="white"
                    />
                    <p className="ml-[6px] sfNormal text-[20px] text-[white]">
                      Back
                    </p>
                  </div>
                </div>
                <p className="text-[white] text-[20px] regularFont my-4 mt-4">
                  Step {localStorage.getItem("brand") ? step - 1 : step} of{" "}
                  {localStorage.getItem("brand") ? step : 3} : Dropz Details
                </p>
                {/* <ProgressBar val1={creatorName} val2={creatorEmail} val3={creatorDiscord} val4={creatorTelegram} val5={walletAddress} val6={price}/> */}
                <ProgressBar step={step} />
                <div className="my-4"></div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={title ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    Dropz Title:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={selectedType ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    DropZ-type:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck
                    color={selectedCategoryType ? "#4CCBA1" : "grey"}
                  />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    Category:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={thumbnail ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    Dropz Thumbnail URL:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={description ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    Description:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={activities ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    Raffle Activities:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck
                    color={startLaunchDate ? "#4CCBA1" : "grey"}
                  />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    Start Duration of campaign:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={endLaunchDate ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                    End Duration of campaign:
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-[100%] h-[100%] px-6 my-2">
                <Dashboard
                  setDataToTheFeild={setDataToTheFeild}
                  setIsDashboard={setIsDashboard}
                  setIsBrandDetail={setIsBrandDetail}
                />
              </div>
            )}

            <LaunchPartnerForm />
          </div>

          <div
            className="flex flex-1 w-[100%] h-[100%] flex-col py-4 relative"
            disabled={true}
            style={{
              opacity: isBrandDetail
                ? 0.2
                : isUpdate && isDashboard
                ? 1
                : isDashboard
                ? 0.5
                : 1,
            }}
          >
            {!isUpdate && isDashboard ? (
              <div
                className="w-[100%] h-[100%] absolute top-0 right-0"
                onClick={() => {
                  errorToast("Please select Drop for edit");
                }}
              ></div>
            ) : null}
            <form
              id="step1"
              className=" w-[90%] h-[100%] items-center flex flex-col"
              onSubmit={(e) => {
                e.preventDefault();
                if (thumbnail?.toString()?.startsWith("https://")) {
                  setStep2Data({
                    title: title,
                    dropzType: selectedType,
                    category: selectedCategoryType,
                    thumbnail: thumbnail,
                    description: description,
                    rafflesActivity: "",
                    passcode: passcode,
                    activities: activities,
                    startLaunch: startLaunchDate,
                    endLaunch: endLaunchDate,
                    isLive: step2Data?.isLive,
                  });
                  setStep(3);
                } else {
                  errorToast("Please provide vaild Url");
                }
                //ApplyWhiteListings();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label className={labelStyle}>Dropz Title</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  placeholder="Enter a catchy title for your Dropz campaign."
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[black]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  value={title}
                  onChange={(e) => {
                    setDropzTitle(e.target.value);
                  }}
                />
              </div>
              <label className={labelStyle}>DropZ-type</label>
              <div className="w-[55%] self-start ml-10 h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <select
                  value={selectedType}
                  required
                  style={{
                    outline: "none",
                  }}
                  onChange={(e) => {
                    //console.log(e.target.value);
                    setSelectedType(e.target.value);
                  }}
                  className="w-[80%] p-2 px-4 rounded-xl text-[black] regularFont"
                >
                  <option value="CLAIM">Claims</option>
                  {/* <option value="POAP">POAPs</option>
                  <option value="SBT">SBTs</option> */}
                  <option value="Other">Collect Wallets</option>
                </select>
              </div>
              <label className={labelStyle}>Category</label>
              <div className="w-[55%] self-start ml-10 h-[50px] regularFont rounded-[7px]  mb-[15px] bg-[white] flex justify-center items-center">
                {/* <input
                  value={selectedCategoryType}
                  required
                  placeholder="Specify the category of your Dropz campaign."
                  onChange={(e) => {
                    //console.log(e.target.value);
                    setSelectedCategoryType(e.target.value);
                  }}
                  style={{
                    outline: "none",
                  }}
                  className="w-[80%] p-2 px-4 rounded-xl text-[black] regularFont"
                /> */}
                {/* <option value="Games">Games</option>
                  <option value="ART and PFPs">ART and PFPs</option>
                  <option value="Brand Rewards">Brand Rewards</option>
                  <option value="IRL Events">IRL Events</option> */}
                {/* /> */}

                <select
                  value={selectedCategoryType}
                  required
                  style={{
                    outline: "none",
                  }}
                  onChange={(e) => {
                    //console.log(e.target.value);
                    setSelectedCategoryType(e.target.value);
                  }}
                  className="w-[80%] p-2 px-4 rounded-xl text-[black] regularFont"
                >
                  <option value="Games">Games</option>
                  <option value="ART and PFPs">ART and PFPs</option>
                  <option value="Brand Rewards">Brand Rewards</option>
                  <option value="IRL Events">IRL Events</option>
                </select>
              </div>
              <label className={labelStyle}>Dropz Thumbnail URL</label>
              <div className="w-[85%] h-[44px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  className="w-[97%] regularFont h-[40px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setThumbnail(e.target.value)}
                  value={thumbnail}
                  placeholder={
                    "Provide the URL for the Dropz campaign thumbnail image."
                  }
                />
              </div>
              <label className={labelStyle}>Description</label>
              <div className="w-[85%]  regularFont rounded-[7px] p-1 mb-[15px] bg-[white] flex justify-center items-center">
                <textarea
                  required
                  className="w-[97%] h-[100px] regularFont bg-[white] text-[white]"
                  style={{
                    resize: "vertical",
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder={
                    "Provide a brief description of your Dropz campaign."
                  }
                />
              </div>

              <label className={labelStyle}>Raffles Activities</label>
              {isUpdate ? (
                <>
                  <div className="w-[85%] flex justify-start items-center">
                    <button
                      className="mt-1 h-[33px] w-[120px] rounded-[4px]"
                      style={{
                        background:
                          "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
                      }}
                      type="button"
                      onClick={() => {
                        setIsActivityEdit(true);
                      }}
                    >
                      <p className="text-[14px] text-[white] semiBoldFont mx-2">
                        Edit
                      </p>
                    </button>
                  </div>

                  <div className="w-[100%] flex justify-start item-center">
                    <div className="mb-[15px]">
                      <div className="my-2">
                        <label className={labelStyle}>Passcode</label>
                      </div>
                      <div className="w-[85%] h-[50px] self-start regularFont rounded-[7px] px-1 mb-[15px] bg-[white] ml-10 flex justify-center items-center">
                        <input
                          id="launchdate"
                          name="launchdate"
                          type="name"
                          onChange={(e) => handlePasscode(e.target.value)}
                          value={passcode}
                          placeholder={
                            "If you want to make this campaign gated and require a passcode for participation, enter the passcode."
                          }
                          className="text-black grow items-center rounded-xl sm:w-[30%] px-[10px] lg:px-4 text-sm outline-none regularFont lg:text-lg h-10"
                          height="48"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-[85%]  regularFont rounded-[7px] px-1 mb-[15px]">
                    <RafflesActivities
                      setActivities={setActivities}
                      activities={activities}
                      setPasscode={setPasscode}
                      passcode={passcode}
                    />
                  </div>
                </>
              )}
              <label className={labelStyle}>Start Duration of Dropz</label>
              <div className="w-[85%] h-[50px] self-start regularFont rounded-[7px] px-1 mb-[15px] bg-[white] ml-10 flex justify-center items-center">
                <input
                  required
                  id="launchdate"
                  name="launchdate"
                  type="datetime-local"
                  onChange={(e) => setStartLaunchDate(e.target.value)}
                  value={startLaunchDate?.substring(0, 16)}
                  placeholder={"LaunchDate"}
                  className="text-black grow items-center rounded-xl sm:w-[30%] px-[10px] lg:px-4 text-sm outline-none regularFont lg:text-lg h-10"
                  height="48"
                />
              </div>
              <label className={labelStyle}>End Duration of Dropz</label>
              <div className="w-[85%] h-[50px] self-start regularFont rounded-[7px] px-1 mb-[15px] bg-[white] ml-10 flex justify-center items-center">
                <input
                  required
                  id="launchdate"
                  name="launchdate"
                  type="datetime-local"
                  onChange={(e) => setEndLaunchDate(e.target.value)}
                  value={endLaunchDate?.substring(0, 16)}
                  placeholder={"LaunchDate"}
                  className="text-black grow items-center rounded-xl sm:w-[30%] px-[10px] lg:px-4 text-sm outline-none regularFont lg:text-lg h-10"
                  height="48"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="h-[100px] items-center h-[48px] pr-4 w-[98.5%] flex justify-end mt-[10px]">
          <FaLock color="#4CCBA1" />
          <p className="text-[14px] text-[white] regularFont mt-1 mx-2">
            Secured by 256 End-to-end Encryption
          </p>
          {!isDashboard && (
            <button
              onClick={() => {
                const isBrand = localStorage.getItem("brand");
                if (isBrand) {
                  setIsDashboard(true);
                } else {
                  setStep((step) => step - 1);
                }
              }}
              className="mt-1 mx-2"
            >
              <p className="text-[14px] text-[white] regularFont mx-2">Back</p>
            </button>
          )}

          {isUpdate && step2Data?.isLive ? (
            <button
              className="mt-1 h-[33px] w-[120px] rounded-[4px] mr-[10px]"
              style={{
                borderColor: "gray",
                borderWidth: "1px",
              }}
              onClick={() => {
                onDisabledClick(false.toString());
                setIsDisabled(false);
              }}
            >
              <p className="text-[14px] text-[white] semiBoldFont mx-2">
                Disable
              </p>
            </button>
          ) : null}

          {isUpdate && (
            <button
              className="mt-1 h-[33px] w-[120px] rounded-[4px] mr-[10px]"
              style={{
                background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
              }}
              onClick={() => {
                setViewData(true);
              }}
            >
              <p className="text-[14px] text-[white] semiBoldFont mx-2">
                View Data
              </p>
            </button>
          )}
          {!isUpdate && isDashboard ? (
            <button
              form="step1"
              type="submit"
              className="mt-1 h-[33px] w-[120px] rounded-[4px]"
              style={{
                opacity: 0.2,
                background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
              }}
              disabled={true}
            >
              <p className="text-[14px] text-[white] semiBoldFont mx-2">Next</p>
            </button>
          ) : (
            <button
              form="step1"
              type="submit"
              className="mt-1 h-[33px] w-[120px] rounded-[4px]"
              style={{
                background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
              }}
            >
              <p className="text-[14px] text-[white] semiBoldFont mx-2">Next</p>
            </button>
          )}
        </div>

        {viewData && (
          <ViewData
            dropId={dropId}
            setOpen={() => {
              setViewData(false);
            }}
          />
        )}

        {isActivityEdit ? (
          <UpdateActivites setOpen={setIsActivityEdit} dropsId={dropId} />
        ) : null}
      </div>
    </div>
  );
}
