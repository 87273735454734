import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { errorToast } from "../../../utils/toast";
import useScrollLock from "./useScrollLock";

const DragAndDrop = ({ onFileSelected, onClose, radioButtonState }) => {
  // Files
  const {toggle} = useScrollLock()
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    if (acceptedFiles[0]?.type === "text/csv") {
      onFileSelected(acceptedFiles[0]);
    } else {
      errorToast("Please select .csv file. Only csv file are accepted");
    }
  }, []);

  const getMessage = (radioButtonState) => {
    switch (radioButtonState) {
      case 0:
        return " Amount";

      case 1:
        return " Token Id";

      case 2:
        return " Amount";

      case 3:
        return " Amount, Token Id";
    }
    return;
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    toggle(true)
  },[])

  return (
    <div className="w-full h-full floating-dialog left-0  backdrop-blur-md flex justify-center items-center">
      <div className="flex items-center justify-center w-[50%]">
        <div className="w-[100%] bg-[black] p-[20px] rounded-lg">
          <div className="w-[100%] flex justify-center items-center">
            <p className="text-[#FE73A5] text-[16px] font-bold mb-[20px]">
              Please input the file in the format of : Recipients Address, 
              {getMessage(radioButtonState)}
            </p>
          </div>

          <label
            forHtml="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-600 border-dashed rounded-lg cursor-pointer  bg-gray-800"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="w-10 h-10 mb-3 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              {/* <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload</span>
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p> */}
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className=" text-xs text-[white]">
                    Drop the files here ...
                  </p>
                ) : (
                  <p
                    className="text-xs text-[white]"
                    aria-disabled
                  >
                    Drag 'n' drop .csv files here
                  </p>
                )}
              </div>

              {/* <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 pt-[80px]">
              <span className="font-semibold">Click to upload</span>
            </p> */}
            </div>
            {/* <input type="file" className="hidden" value="" onChange={handleFile} /> */}
          </label>

          <IoClose
            className="w-[30px] h-[30px] text-[white] absolute top-10 right-10 cursor-pointer"
            size={30}
            color="#FFD230"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;
