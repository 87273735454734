import React from "react";
import { useAccount } from "wagmi";
import Header from "../components/Header";
import Deploy from "../components/Deploy";

function DeployContracts() {
  const [steps, setSteps] = React.useState(-1);
  const { address } = useAccount();
  return (
    <div className="w-full bg-gray-900 h-[100%] overflow-auto">
      <Header />

      {address === "0x9DeB79A450420887f9FeEF4839833a13827058Bf" && (
        <Deploy steps={steps} setSteps={setSteps} />
      )}
    </div>
  );
}

export default DeployContracts;
