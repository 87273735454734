import React, { useContext, useEffect, useState } from "react";
import { useAccount, useProvider } from "wagmi";
import TokensRow from "./tokensData";
import { RotatingLines } from "react-loader-spinner";
import { ethers } from "ethers";
import { ERC20Balance } from "../../../utils/erc20";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { ChainInfo } from "../../../App";
import useScrollLock from "./useScrollLock";
import Bugsnag from "@bugsnag/js";
import { getOwnerErc20TokensOnAvax } from "../../../utils/avalanche";
import { errorToast } from "../../../utils/toast";


const UserTokenModal = (props) => {
  const wallet = useAccount();
  const provider = useProvider();
  const [usersTokens, setUsersTokens] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  const [contractloading, setContractLoading] = useState(false);
  const [isCoinLoading, setIsCoinLoading] = useState(false);
  const { selectedChain } = useContext(ChainInfo);
  const { toggle } = useScrollLock();
  const [contractInfo, setContractInfo] = useState({
    balance: 0,
    symbol: null,
  });

  useEffect(() => {
    detectChian();
  }, [selectedChain]);

  const detectChian = async () => {
    const chainId = selectedChain;
    if (chainId === 137) {
      getUsersTokensForPolygon();
    } else if (chainId === 1) {
      getUsersTokensForEthereum();
    } else if (chainId === 56) {
      BinanceToken(wallet.address);
    } else if (chainId === 43114) {
      getfetchAvaxChainErc20Tokens();
    }
  };

  const getEVMRPCURL = (chainId) => {
    switch (chainId) {
      case 1:
        return "https://polygon-mainnet.g.alchemy.com/v2/XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH";

      case 137:
        return "https://polygon-mainnet.g.alchemy.com/v2/XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH";
    }
  };

  const getUsersTokensForPolygon = () => {
    setIsCoinLoading(true);
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "alchemy_getTokenBalances",
        params: [wallet.address, "erc20"],
      }),
    };

    fetch(
      "https://polygon-mainnet.g.alchemy.com/v2/XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH",
      options
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log("polygon erc20 tokens : ", response?.result?.tokenBalances)
        setUsersTokens(response?.result?.tokenBalances);
        setIsCoinLoading(false);
      })
      .catch((err) => {
        Bugsnag.notify(new Error(err));
        console.error(err);
        setIsCoinLoading(false);
      });
  };

  const getUsersTokensForEthereum = () => {
    setIsCoinLoading(true);
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "alchemy_getTokenBalances",
        params: [wallet.address, "erc20"],
      }),
    };

    fetch(
      "https://eth-mainnet.g.alchemy.com/v2/XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH",
      options
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response?.result?.tokenBalances)
        setUsersTokens(response?.result?.tokenBalances);
        setIsCoinLoading(false);
      })
      .catch((err) => {
        Bugsnag.notify(new Error(err));
        console.error(err);
        setIsCoinLoading(false);
      });
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    if (ethers.utils.isAddress(value)) {
      // console.log(value);
      setContractAddress(value);
      getContractInfo(value);
    } else {
      setContractAddress(value);
    }
  };

  // BSC

  const BinanceToken = (address) => {
    setIsCoinLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        "https://deep-index.moralis.io/api/v2/" + address + "/erc20?chain=bsc",
      headers: {
        accept: "application/json",
        "X-API-Key": process.env.REACT_APP_MORALIS,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUsersTokens(response?.data);
        // console.log(response.data);
        setIsCoinLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsCoinLoading(false);
      });
  };

  // Avax 

  const getfetchAvaxChainErc20Tokens = async () => {
    try {
      if (wallet?.address) {
        setIsCoinLoading(true);
        const response = await getOwnerErc20TokensOnAvax(wallet?.address);
        // console.log("erc20 tokens : ", response);
        setUsersTokens(response);
        setIsCoinLoading(false);
      } else {
        errorToast("Failed to fetch wallet");
      }
    } catch (err) {
      setIsCoinLoading(false);
    }
  }

  const getContractInfo = async (contractAddress) => {
    setContractLoading(true);
    const info = await ERC20Balance(
      provider,
      contractAddress,
      wallet.address
    ).catch((err) => {
      setContractLoading(false);
    });
    //  console.log(info)
    props?.onTap(contractAddress);
    props?.onOutsideTap();
    // setContractInfo(info);
  };

  useEffect(() => {
    toggle(true);
  }, []);

  return (
    <div
      className="w-[100%] h-[100%] floating-dialog flex justify-center items-center absolute top-0 bottom-0 backdrop-blur-md"
      onClick={(event) => {
        event.preventDefault();
        // props?.onOutsideTap();
      }}
      style={{ background: "rgba(0,0,0,0.5)" }}
    >
      <div className="w-[500px] h-[560px] bg-[#06040F] rounded-[6px] flex justify-center p-5 overflow-y-auto">
        <div className="w-[98%]">
          <div className="w-[100%] flex justify-between items-center">
            <p className="text-[18px] text-[white] text-start ml-2 font-bold sfNormal">
              Select a token
            </p>

            <IoClose
              className="text-[white] m-1 mt-[5px] cursor-pointer"
              onClick={() => {
                props?.onOutsideTap();
              }}
            />
          </div>

          <div
            className="w-[100%] rounded-[6px] px-1 mt-[20px] text-[white] flex justify-center items-center"
          // style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
          >
            <div className="w-[100%]">
              <p className="text-[12px] sfNormal italic text-start text-[gray]">
                Refresh to see the respective token values, if not loading.
              </p>
              <input
                className="w-[100%] rounded-[6px] p-[10px] mt-[5px]  bg-[#2A2938] h-[40px] text-[white] bg-[#2A2938] text-[white]"
                style={{
                  outline: "none",
                  outlineColor: "transparent",
                  color: "white",
                }}
                placeholder="Import Contract Address"
                value={contractAddress}
                onChange={handleAddress}
              />
            </div>
            {contractloading ? (
              <div className="ml-[4px] h-[45px] flex jsutify-center items-end">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={contractloading}
                />
              </div>
            ) : null}
          </div>

          <div className="w-[100%] h-[40px] rounded-[12px] mt-[30px]">
            {usersTokens?.length > 0 ? (
              <div>
                {usersTokens?.map((item, index) => {
                  return (
                    <TokensRow
                      key={index}
                      item={item}
                      onTap={props.onTap}
                      provider={props.provider}
                      address={wallet.address}
                    />
                  );
                })}
              </div>
            ) : isCoinLoading ? (
              <div className="w-[100%] h-[400px] flex justify-center items-center">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible={true}
                />
              </div>
            ) : (
              <div className="w-[100%]  h-[100%] flex justify-center items-center">
                <p className="text-[white]">No tokens found</p>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTokenModal;
