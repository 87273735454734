import { useEffect, useState } from "react";

const useScrollLock = () => {
  const bodyStyle = document.body.style;

  const [isLock, setIsLock] = useState(false);

  useEffect(() => {
    if (isLock) {
      bodyStyle.overflow = "hidden";
    } else {
      bodyStyle.overflow = "auto";
    }
  }, [isLock, bodyStyle]);

  const toggle = () => setIsLock(!isLock);

  return { isLock, toggle };
};

export default useScrollLock;
