import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAxoF9oIa-1BqdOHIBUn9VczwsjOoYD6ZQ",
  authDomain: "generated-surf-349813.firebaseapp.com",
  projectId: "generated-surf-349813",
  storageBucket: "generated-surf-349813.appspot.com",
  messagingSenderId: "277296107198",
  appId: "1:277296107198:web:fdba1462f851a2147518ef",
  measurementId: "G-R32252551B"

}
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)