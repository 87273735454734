import { useCallback, useEffect, useState } from "react";
import { getOwnNft } from "../../../utils/Alchemy";
import Papa from "papaparse";
import { errorToast } from "../../../utils/toast";
import { useDropzone } from "react-dropzone";
import { useAccount } from "wagmi";
import { IoClose } from "react-icons/io5";
import Progress from "../../../assets/progress-progress.svg";
import TrashIcon from "../../../assets/trash.svg";
import Bugsnag from "@bugsnag/js";

const AddTokenIdToAddress = ({
  senders,
  selectedChain,
  contractAddress,
  open,
  onClose,
}) => {
  const [tokens, setTokens] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [stage, setStage] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const { address } = useAccount();

  useEffect(() => {
    // "0x02b2c5524ecab7e817196b74b9f8e730f9bb9e51"
    getTokenIds(address, contractAddress, selectedChain);
  }, []);

  function parseCSVFile(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const getTokenIds = async (address, contractAddres, selectedChain) => {
    try {
      setIsFetching(true);
      let firstHundred = [],
        secondHundred = [],
        combineArray = [];
      firstHundred = await getOwnNft(address, contractAddres, selectedChain);
      if (firstHundred?.response?.totalCount > 100) {
        const pageKey = firstHundred?.response?.pageKey;
        secondHundred = await getOwnNft(
          address,
          contractAddres,
          selectedChain,
          pageKey
        );
      }

      if (firstHundred?.nfts?.length > 0 && secondHundred?.nfts?.length > 0) {
        combineArray = [
          ...firstHundred?.nfts?.map((value) => value?.tokenId),
          ...secondHundred?.nfts?.map((value) => value?.tokenId),
        ];
      } else {
        combineArray = [...firstHundred?.nfts?.map((value) => value?.tokenId)];
      }

      setTokens(combineArray);
      // setTokens((state) => (state = [...combineArray]));
      setIsFetching(false);
    } catch (err) {
      Bugsnag.notify(new Error(err))
      setIsFetching(false);
      console.log(err);
    }
  };

  const shuffleTokens = async (array) => {
    const shuffledArray = [...array];

    let currentIndex = shuffledArray.length,
      randomIndex,
      tempValue;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      tempValue = shuffledArray[currentIndex];
      shuffledArray[currentIndex] = shuffledArray[randomIndex];
      shuffledArray[randomIndex] = tempValue;
    }

    setTokens((state) => (state = [...shuffledArray]));
  };

  const copyAddress = (address, amount) => {
    let array = [];
    for (let i = 0; i < amount; i++) {
      array.push(address);
    }
    return array;
  };

  const HandleFiles = async (file) => {
    try {
      let finalArray = [];
      const csvData = await parseCSVFile(file);
      // console.log("File", file, csvData);
      const array = [];
      csvData?.forEach((item, index) => {
        if (index > 0) {
          finalArray = [...finalArray, ...copyAddress(item[0], item[1])];
        }
      });

      // console.log(
      //   "final list ",
      //   finalArray,
      //   tokens?.length,
      //   tokens,
      //   finalArray?.length
      // );

      if (finalArray.length > 280) {
        errorToast(
          "Up-to 300 NFTs are allowed in bulk-send through Dropzone! Please upload file with lesser entries"
        );
      } else {
        if (finalArray.length > tokens?.length) {
          errorToast("Number of tokens in file exceeds wallet amount.");
        } else if (finalArray.length <= tokens?.length) {
          setStage((state) => (state = 2));
          const array = [];
          // address,amount

          finalArray?.forEach((element, index) => {
            const data = {
              address: element,
              amount: tokens[index],
            };
            array.push(data);
          });

          // setSenders(array);
          setFinalList(array);
        }
        // setStage(2);
        // setSenders(array);
      }
    } catch (error) {
      Bugsnag.notify(new Error(error))
      console.error("Error parsing CSV file:", error);
      // setImportCsvOpen(false);
    }
  };

  const handleStage = (num) => {
    setStage(num);
  };

  const handleRemoveTokenId = (index) => {
    const arr = tokens;
    arr.splice(index, 1);
    setTokens(arr);
  };

  useEffect(() => {
    if (senders.length > 200) {
      errorToast("Only 200 NFTs will transferred at a time.");
    }
  }, [senders]);

  // useEffect(() => {
  //   console.log("value changui in tonkes".tokens);
  // }, [tokens]);

  return (
    <>
      {open ? (
        <div className="w-full h-full floating-dialog left-0  backdrop-blur-xl flex justify-center items-center">
          <div className="w-[80%] h-[400px] bg-[#06040F] overflow-auto rounded-[6px] p-[24px]">
            <p className="text-[20px] font-bold sfNormal text-[white] text-left">
              Automatic Token Detection
            </p>

            <>
              {stage == 0 ? (
                <>
                  <ShowTokens
                    tokens={tokens}
                    shuffleTokens={shuffleTokens}
                    handleStage={handleStage}
                    onClose={() => {
                      onClose(finalList);
                    }}
                    isFetching={isFetching}
                    onItemRemove={(index) => {
                      const arr = tokens;
                      arr.splice(index, 1);
                      // console.log("remove tokens", arr);
                      setTokens([...arr]);
                    }}
                  />
                </>
              ) : stage == 1 ? (
                <ImportSection onFileSelected={HandleFiles} />
              ) : stage == 2 ? (
                <ShowGenratedData
                  senders={finalList}
                  handleClose={() => {
                    onClose(finalList);
                  }}
                />
              ) : null}
            </>
          </div>

          <IoClose
            className="w-[30px] h-[30px] text-[white] absolute top-10 right-10 cursor-pointer"
            size={30}
            color="#FFD230"
            onClick={() => {
              onClose(finalList);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default AddTokenIdToAddress;

const ShowTokens = ({
  tokens,
  shuffleTokens,
  handleStage,
  onClose,
  onItemRemove,
  isFetching,
}) => {
  return (
    <>
      {tokens?.length > 0 ? (
        <>
          <p className="text-[14px] sfNormal text-[white] text-left mt-[24px]">
            Token Own by you.
          </p>
          <div className="w-[100%] h-[200px] bg-[black] mt-[12px] px-[10px] overflow-auto">
            {tokens?.map((items, index) => {
              return (
                <div className="w-[100%] flex justify-between items-center selectedAddress">
                  <p
                    key={index}
                    className="sfNormal text-[white] text-[14px] text-left"
                  >
                    {"Token Id : "} {items}
                  </p>

                  <div className="flex jsutify-between items-center cursor-pointer">
                    <img
                      className="w-[20px] h-[20px] ml-[8px]"
                      src={TrashIcon}
                      alt=""
                      onClick={() => {
                        onItemRemove(index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="w-[100%] flex justify-end items-center mt-[20px]">
            <button
              className="w-[100px] h-[40px] button-friend-tech"
              onClick={() => {
                shuffleTokens(tokens);
              }}
            >
              Shuffle
            </button>

            <button
              className="w-[100px] h-[40px] button-friend-tech"
              onClick={() => {
                handleStage(1);
              }}
            >
              Next
            </button>
          </div>
        </>
      ) : isFetching ? (
        <>
          <div className="w-[100%] h-[260px] flex justify-center items-center">
            <img
              className="w-[40px] h-[40px] spin"
              src={Progress}
              alt="progress"
            />
          </div>

          <div className="w-[100%] flex justify-end items-center">
            <button
              className="w-[100%] h-[40px] button-friend-tech"
              onClick={() => {
                onClose();
              }}
            >
              close
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="w-[100%] h-[260px] flex justify-center items-center">
            <p className="text-[18px] sfNormal text-[white] text-center">
              NFT is not detected in your wallet.
            </p>
          </div>

          <div className="w-[100%] flex justify-end items-center">
            <button
              className="w-[100%] h-[40px] button-friend-tech"
              onClick={() => {
                onClose();
              }}
            >
              close
            </button>
          </div>
        </>
      )}
    </>
  );
};

const ShowGenratedData = ({ senders, onClose, handleClose }) => {
  return (
    <>
      <p className="text-[14px] sfNormal text-[white] text-left mt-[24px]">
        Generate List :
      </p>

      <div className="w-[100%] h-[200px] bg-[black] mt-[12px] px-[10px] overflow-auto">
        {senders?.map((items, index) => {
          return (
            <div className="w-[100%] flex justify-between items-center selectedAddress">
              <p className="sfNormal text-[white] text-[14px] text-left">
                {index + 1}. {items?.address}
              </p>
              <p className="sfNormal text-[white] text-[14px] text-left">
                {items?.amount}
              </p>
            </div>
          );
        })}
      </div>

      <div className="w-[100%] flex justify-end items-center mt-[20px]">
        {/* <button
          className="w-[100px] h-[40px] button-friend-tech"
          onClick={() => {
            shuffleTokens(tokens);
          }}
        >
          Shuffle
        </button> */}

        <button
          className="w-[100px] h-[40px] button-friend-tech"
          onClick={() => {
            if (senders.length >= 200) {
              errorToast("Sorry,only 200 tokens is trasfereed at a time.");
              handleClose();
            } else {
              handleClose();
            }
          }}
        >
          Finish
        </button>
      </div>
    </>
  );
};

const ImportSection = ({ onFileSelected }) => {
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    if (acceptedFiles[0]?.type === "text/csv") {
      onFileSelected(acceptedFiles[0]);
    } else {
      errorToast("Please select .csv file. Only csv file are accepted");
    }
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="w-[100%] h-[300px] flex mt-[4px]">
      <div className="flex w-[100%]">
        <div className="w-[100%]">
          <div className="flex mt-[20px]">
            {/* {["By Manually", "By CSV"].map((value, key) => {
              return (
                <button
                  key={key}
                  onClick={() => {
                    setSelctedOptions(key);
                  }}
                  className={`${
                    selectedOptions == key
                      ? "button-friend-tech-selected"
                      : "button-friend-tech"
                  }`}
                >
                  {value}
                </button>
              );
            })} */}
          </div>

          {/* {(selectedOptions == 0) ? <div className="w-[70%] flex-col justify-center items-center mt-[25px]">
            <p className="w-[100%] text-[14px] sfNormal text-[white] text-start ">
              Recepient Address 
            </p>
            <input
              className="w-[100%] rounded-[6px] p-[10px] mt-[5px]  bg-[#2A2938] h-[40px] text-[white] bg-[#2A2938] text-[white]"
              style={{
                outline: "none",
                outlineColor: "transparent",
                color: "white",
              }}
              placeholder="Enter sender Address"
            />
          </div> : */}
          <div className="w-[100%]">
            <div className="w-[100%] flex justify-center items-center">
              <p className="text-[#FE73A5] text-[16px] font-bold m-2">
                Please input the file in the format of : Recepient Address,
                Amount
              </p>
            </div>

            <label
              forHtml="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-[210px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="text-xs text-[white] dark:text-[white]">
                      Drop the files here ...
                    </p>
                  ) : (
                    <p
                      className="text-xs text-[white] dark:text-[white]"
                      aria-disabled
                    >
                      Drag 'n' drop .csv files here
                    </p>
                  )}
                </div>

                {/* <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 pt-[80px]">
              <span className="font-semibold">Click to upload</span>
            </p> */}
              </div>
              {/* <input type="file" className="hidden" value="" onChange={handleFile} /> */}
            </label>
          </div>
          {/* } */}
        </div>
      </div>
    </div>
  );
};
