import React from 'react'
import { AiOutlineClose, AiOutlineDownload } from 'react-icons/ai';
import CsvDownload from 'react-json-to-csv';
import Modal from 'react-modal';

export default function UserModal({open,setOpen,data}) {
    const customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
  return (
    <>
    <Modal
        isOpen={open}
        onRequestClose={setOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <>
        <div className='w-[100%] fixed flex bg-[white] items-center justify-between px-10'>
          <p className='sfBold text-[black] my-2 text-[18px]'>User Data</p>
          <p className='sfBold text-[black] my-2 text-[18px]'>{data?.length} Applications</p>
          <CsvDownload title='Download Users' filename={`Application`} className='sfBold my-2 text-[black] text-[18px]' data={data} /> 
          <AiOutlineClose onClick={()=>{
            setOpen(false)
          }} color='black' size={20}/>
          </div>
          <div className='min-w-[800px] max-h-[500px] pt-14 rounded-xl overflow-y-scroll'>
          
          {data?.map((item,index)=>(
            <div className='w-[100%] text-[black] my-2 flex flex-row items-start justify-start rounded-[4px] h-[48px]'>
              <p>{item?.address || item?.email}</p>
              <p className='ml-2 sfSemiBold'>{item?.user_name}</p>
            </div>
          ))}
        </div>
        </>
       
      </Modal>
    </>
  )
}
