import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import LaunchPartnerForm from "./LaunchPartnerForm";
import ProgressBar from "./ProgressBar";
import { useAccount } from "wagmi";
import Dashboard from "./Dashboard";
import { useNavigate } from "react-router-dom";
import { isAddress } from "ethers/lib/utils.js";
import { errorToast } from "../../utils/toast";
import Bugsnag from "@bugsnag/js";

export default function LaunchStep1({
  step1Data,
  setStep1Data,
  step,
  setStep,
  isDashboard,
  setDataToTheFeild,
  setIsDashboard,
}) {
  const wcAccounts = useAccount();
  const [collectionName, setCollectionName] = useState(step1Data?.name || "");
  const [imageUrl, setImageUrl] = useState("" || step1Data?.url || "");
  const [twitterUrl, setTwitterUrl] = useState(step1Data?.twitterUrl || "");
  const [discordUrl, setDiscordUrl] = useState(step1Data?.discordUrl || "");
  const [website, setWebsite] = useState(step1Data?.siteUrl || "");
  const [chain, setChain] = useState(step1Data?.chain || "Polygon");
  const [walletAddress, setWalletAddress] = useState(
    wcAccounts?.address || step1Data?.walletAddress || ""
  );
  const [email, setEmail] = useState(step1Data?.email || "");
  const [telegram, setTelegram] = useState(step1Data?.telegram || "");
  const navigate = useNavigate();

  useEffect(() => {
    setCollectionName(step1Data?.name);
    setImageUrl(step1Data?.url);
    setTwitterUrl(step1Data?.twitterUrl);
    setDiscordUrl(step1Data?.discordUrl);
    setWebsite(step1Data?.siteUrl);
    setChain(step1Data?.chain);
    setWalletAddress(wcAccounts.address || step1Data?.walletAddress);
    setEmail(step1Data?.email);
    setTelegram(step1Data?.telegram);
  }, [step1Data]);

  const labelStyle =
    "w-[100%] ml-10 self-start text-[white] my-2 text-[15px] semiBoldFont";

  return (
    <div className="w-[100%] h-screen flex flex-col mt-[-100px]">
      <div className="flex-col  w-[100%] pt-[100px] lauch-bg flex ">
        <div
          className={`w-[100%] flex flex-1 border-[white] ${
            false ? " flex-col" : "flex-row"
          } border-b`}
        >
          <div className="flex flex-1 flex-col items-center py-4 justify-around border-[white] border-r">
            {!isDashboard ? (
              <div className="flex flex-col w-[100%] px-6 my-2">
                <p className="text-[white] text-[20px] regularFont my-4 mt-4">
                  Step 1 of 3 : Creator/Brand Details:
                </p>

                <ProgressBar step={step} />
                <div className="my-4"></div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={collectionName ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Creator/Brand Username:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={imageUrl ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Creator/Brand Thumbnail:
                  </p>
                </div>

                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={twitterUrl ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Twitter:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={discordUrl ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Discord:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={website ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Website:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={walletAddress ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Wallet Address:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={email ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Email Address:
                  </p>
                </div>
                <div className="m-1 flex items-center">
                  <AiOutlineCheck color={telegram ? "#4CCBA1" : "grey"} />
                  <p className="ml-2 text-[white] my-1 text-[15px] mediumFont">
                  Telegram:
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-[100%] h-[100%] px-6 my-2">
                <Dashboard
                  setDataToTheFeild={setDataToTheFeild}
                  setIsDashboard={setIsDashboard}
                />
              </div>
            )}

            {/* <LaunchPartnerForm /> */}
          </div>

          <div className="flex flex-1 w-[100%] h-[100%] justify-start items-start flex-col py-4">
            <form
              id="step1"
              className=" w-[90%] h-[100%] items-center flex flex-col"
              onSubmit={(e) => {
                e.preventDefault();
                if (wcAccounts?.address !== undefined) {
                  setStep1Data({
                    url: imageUrl,
                    chain: chain,
                    name: collectionName,
                    twitterUrl: twitterUrl,
                    discordUrl: discordUrl,
                    siteUrl: website,
                    email: email,
                    walletAddress: walletAddress,
                    telegram: telegram,
                  });
                  setStep(2);
                } else {
                  errorToast("Please connect your wallet.")
                }
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label className={labelStyle}>Creator/Brand Username</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  placeholder="Enter your unique creator or brand username."
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[black]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  value={collectionName}
                  onChange={(e) => {
                    setCollectionName(e.target.value);
                  }}
                />
              </div>
              <label className={labelStyle}>Creator/Brand Thumbnail</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  placeholder="Provide a link to an image representing your creator or brand."
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[black]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  value={imageUrl}
                  onChange={(e) => {
                    setImageUrl(e.target.value);
                  }}
                />
              </div>
              <label className={labelStyle}>Twitter</label>
              <div className="w-[85%] h-[44px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  className="w-[97%] regularFont h-[40px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setTwitterUrl(e.target.value)}
                  value={twitterUrl}
                  placeholder={"Enter the official Twitter link of your project."}
                />
              </div>
              <label className={labelStyle}>Discord</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setDiscordUrl(e.target.value)}
                  value={discordUrl}
                  placeholder={"Enter the official discord link of your server."}
                />
              </div>
              <label className={labelStyle}>Website</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  type={"text"}
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                  placeholder={"Input the URL of your official collection website."}
                />
              </div>
              <label className={labelStyle}>Enter Wallet Address</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  type={"text"}
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  value={walletAddress}
                  placeholder={"Wallet Address"}
                />
              </div>
              <label className={labelStyle}>Email</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  type={"text"}
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={"Share your email address for communication."}
                />
              </div>
              <label className={labelStyle}>Telegram</label>
              <div className="w-[85%] h-[50px] regularFont rounded-[7px] px-1 mb-[15px] bg-[white] flex justify-center items-center">
                <input
                  required
                  type={"text"}
                  className="w-[97%] regularFont h-[44px] bg-[white] text-[white]"
                  style={{
                    outline: "none",
                    outlineColor: "black",
                    color: "black",
                  }}
                  onChange={(e) => setTelegram(e.target.value)}
                  value={telegram}
                  placeholder={"Enter your Telegram username for communication."}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="h-[100px] items-center h-[48px] pr-4 w-[100%] flex justify-end mt-[10px]">
          <FaLock color="#4CCBA1" />
          <p className="text-[14px] text-[white] regularFont mt-1 mx-2">
            Secured by 256 End-to-end Encryption
          </p>
          <button
            onClick={() => {
              try {
                const isBrand = localStorage.getItem("brand") || false;
                if (isBrand) {
                  if (!isDashboard) setIsDashboard(true);
                  else navigate(-1);
                } else {
                  navigate(-1);
                }
              } catch (err) {
                Bugsnag.notify(new Error(err));
                console.log(err);
              }
            }}
            className="mt-1 mx-2"
          >
            <p className="text-[14px] text-[white] regularFont mx-2">Back</p>
          </button>
          <button
            form="step1"
            type="submit"
            className="mt-1 h-[33px] w-[120px] rounded-[4px]"
            style={{
              background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)",
            }}
          >
            <p className="text-[14px] text-[white] semiBoldFont mx-2">Next</p>
          </button>
        </div>
      </div>
    </div>
  );
}
