import { useState } from "react";

const RafflesActivities = ({
  setActivities,
  activities,
  setPasscode,
  passcode,
}) => {
  const [step1, setStep1] = useState(activities?.follow || false);
  const [step2, setStep2] = useState(activities?.retweet || false);
  const [step3, setStep3] = useState(activities?.like || false);
  const [step4, setStep4] = useState(activities?.passcode || false);

  const handleInputs = (text) => {
    setActivities({ ...activities, ...text });
  };

  return (
    <div className="w-[100%] regularFont  px-1 mb-[15px]">
      <div className="">
        <ul className="text-[white]">
          {/* <li>
            <div className="w-[100%] flex mt-[10px]">
              <label className="flex">
                <input
                  type="checkbox"
                  checked={step1 || activities?.follow || false}
                  className="ml-[4px]"
                  value="Item 1"
                  onChange={() => {
                    setStep1(!step1);
                  }}
                />
              </label>
              <label className="ml-[10px]">Follow Twitter ID</label>
            </div>
            {step1 && (
              <input
                required
                className="w-[97%] regularFont h-[44px] bg-[white] text-[white] rounded-[7px] my-2 p-2"
                style={{
                  outline: "none",
                  outlineColor: "black",
                  color: "black",
                }}
                onChange={(e) => handleInputs({follow : e.target.value})}
                value={activities?.follow}
                placeholder={"Twitter Id"}
              />
            )}
            <div></div>
          </li> */}
          <li>
            <div className="w-[100%] flex mt-[10px]">
              <label className="flex">
                <input
                  type="checkbox"
                  checked={step2 || activities?.retweet || false}
                  className="ml-[4px]"
                  value="Item 1"
                  onChange={() => {
                    setStep2(!step2);
                  }}
                />
              </label>
              <label className="ml-[10px]">Retweet:</label>
            </div>
            {step2 && (
              <input
                required
                className="w-[97%] regularFont h-[44px] bg-[white] text-[white] rounded-[7px] my-2 p-2"
                style={{
                  outline: "none",
                  outlineColor: "black",
                  color: "black",
                }}
                onChange={(e) => handleInputs({ retweet: e.target.value })}
                //   onChange={(e) => setWalletAddress(e.target.value)}
                value={activities?.retweet}
                placeholder={"Enter tweet link"}
              />
            )}
            <div></div>
          </li>
          <li>
            <div className="w-[100%] flex mt-[10px]">
              <label className="flex">
                <input
                  type="checkbox"
                  checked={step3 || activities?.like || false}
                  className="ml-[4px]"
                  value="Item 1"
                  onChange={() => {
                    setStep3(!step3);
                  }}
                />
              </label>
              <label className="ml-[10px]">Like:</label>
            </div>
            {step3 && (
              <input
                required
                className="w-[97%] regularFont h-[44px] bg-[white] text-[white] rounded-[7px] my-2 p-2"
                checked={step3 || activities?.like || false}
                style={{
                  outline: "none",
                  outlineColor: "black",
                  color: "black",
                }}
                onChange={(e) => handleInputs({ like: e.target.value })}
                //   onChange={(e) => setWalletAddress(e.target.value)}
                value={activities?.like}
                placeholder={"Enter tweet link"}
              />
            )}

            <div></div>
          </li>
          <li>
            <div className="w-[100%] flex mt-[10px]">
              <label className="flex">
                <input
                  type="checkbox"
                  checked={step4 || passcode || false}
                  className="ml-[4px]"
                  value="Item 1"
                  onChange={() => {
                    setStep4(!step4);
                  }}
                />
              </label>
              <label className="ml-[10px]"> Enter passcode</label>
            </div>
            {step4 && (
              <input
                required
                className="w-[97%] regularFont h-[44px] bg-[white] text-[white] rounded-[7px] my-2 p-2"
                style={{
                  outline: "none",
                  outlineColor: "black",
                  color: "black",
                }}
                onChange={(e) => setPasscode(e.target.value)}
                // onChange={(e) => setWalletAddress(e.target.value)}
                value={passcode}
                placeholder={"If you want to make this campaign gated and require a passcode for participation, enter the passcode. "}
              />
            )}

            <div></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RafflesActivities;
