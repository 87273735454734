import React, { useState } from "react";

export default function ProgressBar(props) {
  const [done, setDone] = useState(0);
  return (
    <div className="w-[300px] overflow-hidden sm:w-[400px] flex flex-1 rounded-[5px] bg-[white] ">
      <div
        style={{ width: props?.step * (400 / 3), background: "linear-gradient(90deg, #FB596F 0%, #FE30D2 100%)" }}
        className={`flex h-[5px]`}
      ></div>
    </div>
  );
}
