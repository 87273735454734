import PolygonErc20 from "./polygonErc20.json";
import EthereumErc20 from "./ethereumErc20.json";
import ChainLinkABI from "./chainlinkAbi.json";
import { ethers } from "ethers";
import Bugsnag from "@bugsnag/js";

const getValue = (token, data) => {
  const arr = [];
  data.map((element) => {
    if (element.Pair.includes(token.toString().toUpperCase())) {
      arr.push(element);
      return element;
    }
  });

  if (arr.length === 0) {
    throw new Error("token Not found");
  }
  return arr[0];
};

export const randomApiKey = () => {
  const keys = [
    "2dmZtJYBMur97rEKyvHN2c72lFstYn1J",
    "XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH",
    "YaX5EKNQL2Avv8L2oW6kGkshXKWjdIgg",
    "VCtJLOy0qGRovxbCKkwZgWlcm5x5wbkT",
    "P7Z47VdHTMNYzD5YQfM3-qxaZ0vlx90T",
  ];
  return keys[Math.floor(Math.random() * keys.length)];
};

export const getChain = (chain) => {
  switch (chain) {
    case "eth":
      return "homestead";
    case "polygon":
      return "matic";

    case "arbitrum":
      return "arbitrum";

    case "maticmum":
      return "maticmum";

    case "goerli":
      return "goerli";

    default:
      return "homestead";
  }
};

export const getAlchemyProvider = (chian) => {
  const key = randomApiKey();
  return new ethers.providers.AlchemyProvider(getChain(chian), key);
};

export const getTokenPrice = async (tokenSymbol, chain) => {
  const provider = getAlchemyProvider(chain);
  const contractMetadata = chain === "eth" ? EthereumErc20 : PolygonErc20;
  const contractAddress = getValue(
    `${tokenSymbol.toUpperCase()} / USD`,
    contractMetadata
  );
  const contract = new ethers.Contract(
    contractAddress.Address,
    ChainLinkABI,
    provider
  );

  const value = await contract.latestRoundData().catch((err) => {
    Bugsnag.notify(new Error(err));
    console.log(err);
  });

  return {
    price: parseInt(value?.answer?._hex) || 0,
    decimals: contractAddress?.Dec || 0,
  };
};
