import { Network, Alchemy } from "alchemy-sdk";

const getChains = (chain) => {
  switch (chain) {
    case 137:
      return Network.MATIC_MAINNET;

    case 1:
      return Network.ETH_MAINNET;

    case 8453:
      return Network.BASE_MAINNET;

    default:
      return Network.MATIC_MAINNET;
  }
};

export const getOwnNft = async (address, contractAddress, chain, pageKey) => {
  const settings = {
    apiKey: "XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH", // Replace with your Alchemy API Key.
    network: getChains(chain),
  };
  const alchemy = new Alchemy(settings);
  const nftData = await alchemy.nft.getNftsForOwner(address, {
    contractAddresses: [contractAddress],
    pageKey: pageKey,
    pageSize: 100,
  });
  return { nfts: nftData?.ownedNfts, response: nftData };
};
