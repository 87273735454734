import toast from "react-hot-toast";

const style = {
  style: {
    border: "1px solid #FE73A5",
    padding: "16px",
    color: "#FE73A5",
    background: "#111827",
  },
  iconTheme: {
    primary: "#FE73A5",
    secondary: "#111827",
  },
};



export const errorToast = (msg) => {
  toast.error(msg, style);
};

export const NormalToast=(msg)=>{
  toast(msg,{
    duration: 4000,
    position: 'top-center',
  
    style: {},
    className: '',
  

    icon: '👏',
  
   
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
  

    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  })
}
export const successToast=(msg)=>{
  toast.success(msg,{
    duration: 4000,
    position: 'top-center',
  
    style: {},
    className: '',
  

    icon: '👏',
  
   
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
  

    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  })
}