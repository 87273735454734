import "./App.css";
import BundleTransaction from "./pages/bundleTransaction";
import Footer from "./assets/footer.svg";
import { createContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LaunchDrops from "./pages/launchDrops";
import DeployContracts from "./pages/deploy";
import FriendTechHome from "./pages/friendTech";
import Dropz from "./dropz";
import MyDropz from "./dropz/_components/MyDropz";
import { useDisconnect } from "wagmi";
import LaunchStep1 from "./dropz/_pages/LaunchStep1";

export const ChainInfo = createContext();

function App() {
  const [currentChain, setCurrentChain] = useState(137);

  const disconnect = useDisconnect({
    onSuccess() {
      console.log("logout wallet connect");
    },
  });


  const setSlectedChain = (chainId) => {
    setCurrentChain(chainId);
  };

  return (
    <ChainInfo.Provider
      value={{ selectedChain: currentChain, setSlectedChain }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BundleTransactionWithFooter />} />
          <Route path="/friend_tech" element={<FriendTechHome />} />
          <Route path="/dropz" element={<LaunchDrops />} />
          <Route path="/dropz/brand" element={<LaunchStep1 />} />
          <Route path="/mydropz" element={<MyDropz />} />
          <Route path="/deploy" element={<DeployContracts />} />
        </Routes>
      </BrowserRouter>
    </ChainInfo.Provider>
  );
}

const BundleTransactionWithFooter = () => {
  return (
    <div className="App relative bg-gray-900">
      <BundleTransaction />
      {/* <div className="w-[100%] mt-[40px] bg-gray-900 bottom-0 left-0 flex justify-center items-center">
        <img className="h-[40px]" src={Footer} alt="footers" />
      </div> */}
    </div>
  );
};

export default App;
