import { useContext, useEffect, useState } from "react";
import BinanceIcon from "../../../assets/binance.svg";
import PolygonIcon from "../../../assets/polygon.svg";
import EthereumIcon from "../../../assets/ethereum.svg";
import avalancheIcon from "../../../assets/avalanche.png";
import BaseIcon from "../../../assets/basechain.png";
import AvalancheIcon from "../../../assets/avalanche.png";
import { useProvider, useSwitchNetwork, useAccount } from "wagmi";
import { MdOutlineDone } from "react-icons/md";
import { ChainInfo } from "../../../App";
import useScrollLock from "./useScrollLock";
import { errorToast } from "../../../utils/toast";

export const getChainLogo = (chainId) => {
  switch (chainId) {
    case 1:
      return EthereumIcon;

    case 56:
      return BinanceIcon;

    case 137:
      return PolygonIcon;

    case 80001:
      return PolygonIcon;

    case 5:
      return PolygonIcon;

    case 84531:
      return BaseIcon;

    case 43114:
      return AvalancheIcon;

    case 8453:
      return BaseIcon;
  }
};

const NetworkDropDrown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentChain, setCurrentChain] = useState(1);
  const { switchNetworkAsync, isSuccess, isLoading, isError } =
    useSwitchNetwork();
  const provider = useProvider();
  const { isConnected } = useAccount();

  const { setSlectedChain } = useContext(ChainInfo);
  const { isLock, toggle } = useScrollLock();

  useEffect(() => {
    getCurrentChain();
  }, [isConnected]);

  useEffect(() => {
    if (isError) {
      errorToast("Failed please try again");
    }
  }, [isSuccess, isError]);

  const getCurrentChain = async () => {
    const networkId = await provider.getNetwork();
    // console.log(networkId?.chainId, networkId);
    setCurrentChain({
      chainId: networkId?.chainId,
      logo: getChainLogo(networkId?.chainId),
    });
    setSlectedChain(networkId?.chainId);
  };

  //   chains

  const chains = [
    {
      logo: EthereumIcon,
      title: "Ethereum",
      chainId: 1,
    },
    {
      logo: PolygonIcon,
      title: "Polygon",
      chainId: 137,
    },
    {
      logo: PolygonIcon,
      title: "Polygon Testnet",
      chainId: 80001,
    },
    {
      logo: EthereumIcon,
      title: "Goerli Testnet",
      chainId: 5,
    },
    {
      logo: BaseIcon,
      title: "Base Testnet",
      chainId: 84531,
    },
    {
      logo: avalancheIcon,
      title: "Avax",
      chainId: 43114,
    },
    {
      logo: BaseIcon,
      title: "Base",
      chainId: 8453,
    },
  ];

  const getChainList = () => {
    if (window?.location?.pathname?.toString()?.endsWith("/friend_tech")) {
      return [
        {
          logo: BaseIcon,
          title: "Base",
          chainId: 8453,
        },
      ];
    } else {
      return [
        {
          logo: EthereumIcon,
          title: "Ethereum",
          chainId: 1,
        },
        {
          logo: PolygonIcon,
          title: "Polygon",
          chainId: 137,
        },
        {
          logo: PolygonIcon,
          title: "Polygon Testnet",
          chainId: 80001,
        },
        {
          logo: EthereumIcon,
          title: "Goerli Testnet",
          chainId: 5,
        },
        {
          logo: BaseIcon,
          title: "Base Testnet",
          chainId: 84531,
        },
        {
          logo: avalancheIcon,
          title: "Avax",
          chainId: 43114,
        },
        {
          logo: BaseIcon,
          title: "Base",
          chainId: 8453,
        },
      ];
    }
  };

  const getNetworkChain = (networkId) => {
    switch (networkId) {
      case 1:
        return 0;

      case 137:
        return 1;

      case 80001:
        return 2;

      case 5:
        return 3;

      case 84531:
        return 4;

      case 8453:
        return 6;

      case 43114:
        return 5;

      default:
        return 1;
    }
  };

  return (
    <div>
      <div
        className="w-[150px] flex justify-center items-center rounded-full"
        onClick={() => {
          if (isConnected) {
            setIsOpen(!isOpen);
            toggle(true);
          } else {
            errorToast("Please Connect Your Wallet.");
          }
        }}
      >
        <img
          className="w-[21px] h-[21px]"
          src={chains[getNetworkChain(currentChain.chainId)]?.logo}
          alt="icons"
        />
        <p className="text-[14px] text-[white] font-bold ml-[8px] sfNormal font-700 cursor-pointer">
          {chains[getNetworkChain(currentChain.chainId)].title}
        </p>
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="w-[34] h-[34]"
          type="button"
        >
          <svg
            className="w-4 h-4 ml-2 text-[white]"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>
      </div>

      {isOpen ? (
        <div
          id="dropdown"
          className="z-100 mt-[20px] absolute right-[340px] bg-gray-700 text-[white] divide-y divide-gray-700 rounded-lg shadow w-44 dark:bg-gray-800 slider-top z-50"
        >
          <ul
            className="py-2 text-sm text-gray-100 dark:text-gray-200 p-3"
            aria-labelledby="dropdownDefaultButton"
          >
            {getChainList()?.map((item, index) => (
              <>
                {!item?.title?.toString()?.endsWith("Testnet") ? (
                  <div
                    key={index}
                    className="w-[100%] flex justify-between items-center cursor-pointer mt-[5px]"
                    onClick={async () => {
                      await switchNetworkAsync(item.chainId);
                      setCurrentChain({
                        chainId: item?.chainId,
                        logo: getChainLogo(item?.chainId),
                      });
                      setSlectedChain(item?.chainId);
                      setIsOpen(false);
                    }}
                  >
                    <div className="flex justify-start items-center">
                      <img
                        className="w-[22px] h-[22px]"
                        src={item.logo}
                        alt="chain_icons"
                      />
                      <p className="block px-4 py-2 text-[white] text-[16px]">
                        {item.title}
                      </p>
                    </div>

                    {currentChain.chainId === item.chainId ? (
                      <div>
                        <MdOutlineDone
                          className="w-[20px] h-[20px] font-bold"
                          color="#FE73A5"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ))}
          </ul>
        </div>
      ) : null}

      {isOpen ? (
        <div
          className="w-screen h-screen absolute top-0 -left-[160px] backdrop-blur-[4px]"
          style={{ backgroundColor: "rgba(0,0,0,0.4)", zIndex: 1 }}
          onClick={() => {
            toggle(false);
            setIsOpen(false);
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default NetworkDropDrown;
