import React, { useState } from 'react'
import { DisableDrop, EditInfo, getDropUsers } from '../_utils';
import DropComponent from './DropComponent';
import FormStatus from './FormStatus';
import GradientButton from './GradientButton';
import CsvDownloadButton from 'react-json-to-csv'
import UserModal from './UserModal';

export default function DropList({dropsData,dropList,setSelectedDrop}) {

    const [name,setName]=useState(dropsData?.dropName)
    const [visible,setVisible]=useState(false)
    const [creatorTwitterUrl,setCreatorTwitterUrl]=useState(dropsData?.twitterUrl)
    const [number,setNumber]=useState(dropsData?.supply);
    const [imageUrl,setImageUrl]=useState(dropsData?.imageUrl);
    const [wallet,setWallet]=useState(dropsData?.creatorWalletAddress);
    const [email,setEmail]=useState(dropsData?.email);
    const [description,setDescription]=useState(dropsData?.description)
    const [utility,setUtility]=useState(dropsData?.utility);
    const [loading,setLoading]=useState(false)
    const [userData,setUserData]=useState([])
    const [contractAddress,setContractAddress]=useState(dropsData?.contractAddress);
    const [telegramId,setTelegramId]=useState(dropsData?.telegramId || '');

    const fetchUserData=async(dropsData)=>{
        if(dropsData){
            const users= await getDropUsers(dropsData?.dropName)
            setUserData(users)
        }
    }

    React.useEffect(()=>{
        setName(dropsData?.dropName)
        setCreatorTwitterUrl(dropsData?.twitterUrl || '')
        setNumber(dropsData?.supply || '')
        setImageUrl(dropsData?.imageUrl || '')
        setWallet(dropsData?.creatorWalletAddress || '')
        setEmail(dropsData?.email || '')
        setDescription(dropsData?.description || '')
        setUtility(dropsData?.utility || '')
        setTelegramId(dropsData?.telegramId || '')
        setContractAddress(dropsData?.contractAddress || '')
        fetchUserData(dropsData || '')
    },[dropsData])

  return (
    <div className='max-w-[1440px] self-center justify-start w-[100%] self-center flex  flex-row '>
    <div className=" min-h-[100%] ml-[80px] flex flex-col borderRight">
        <DropComponent dropList={dropList} setSelectedDrop={setSelectedDrop}/>
    </div>
    
    <>
    <div className='max-w-[637px] ml-[40px]'>
   <UserModal open={visible} setOpen={setVisible} data={userData} />
    <div className='w-[100%] flex flex-row'>
        <div className='w-[100%] flex flex-col '>
        <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Drop Title</p>
        <input
                
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="name"
                placeholder=""
                value={name}
                onChange={(e) => {
                  //setName(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Official Twitter URL</p>
        <input
                
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="twitter"
                value={creatorTwitterUrl}
                placeholder=""
                onChange={(e) => {
                    setCreatorTwitterUrl(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Telegram ID</p>
        <input
                
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="telegram"
                value={telegramId}
                placeholder=""
                onChange={(e) => {
                    setTelegramId(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Number of POAPs (supply)</p>
        <input
                type="number"
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="amount"
                placeholder=""
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
    </div>
        </div>
        {imageUrl &&  <img src={imageUrl} className={"w-[250px] h-[250px] rounded-xl m-[20px] ml-[50px]"}/>}
       
    </div>
    

    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Thumbnail (Image Url)*</p>
        <input
                
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="amount"
                placeholder="POAP Image URL"
                value={imageUrl}
                onChange={(e) => {
                  setImageUrl(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Wallet Address*</p>
        <input
                
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="amount"
                placeholder="Creator Wallet Address"
                value={wallet}
                onChange={(e) => {
                  setWallet(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Email</p>
        <input
                
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="email"
                type={'email'}
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Description of the dropz*</p>
        <textarea
                
                className=" min-w-[650px] min-h-[150px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="email"

                placeholder="Enter POAP Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Utility of the dropz*</p>
        <textarea
                
                className=" min-w-[650px] min-h-[150px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="email"
               
                placeholder="Email POAP Utility"
                value={utility}
                onChange={(e) => {
                  setUtility(e.target.value);
                }}
              />
    </div>
    <div className='flex flex-col mt-[12px] items-start  mb-[70px] justify-center'>
        <p className='sfSemiBold text-[white] text-[24px]'>Do you want smart contract for this POAP? (Yes/ No)</p>
        <input
              
                className=" min-w-[466px] rounded-[6px] mt-[12px] p-[12px] h-[48px] bg-[#2A2938] border-rm text-[white]"
                name="amount"
                placeholder="If yes, Komet team will reach out to you"
                value={contractAddress}
                onChange={(e) => {
                  setContractAddress(e.target.value);
                }}
              />
    </div>
    <div className='w-[100%] flex flex-row mb-[50px] mt-[-30px] justify-around'>
    <GradientButton onClick={()=>{
        EditInfo(dropsData?.dropId,name,creatorTwitterUrl,imageUrl,wallet,number,email || '',description,utility,telegramId,contractAddress,setLoading,dropsData?.enabled,dropsData?.code)
    }} text={'Update Drop'}/>
    
    <button onClick={()=>{
       DisableDrop(dropsData?.dropId,name,creatorTwitterUrl,imageUrl,wallet,number,email || '',description,utility,telegramId,contractAddress,setLoading,dropsData?.code)
    }} type='submit' className=' ml-[40px] mt-1 h-[48px] bg-[rgba(255,255,255,0.3)] w-[144px] sfSemiBold text-[white] rounded-[4px]'><p className='text-[14px] text-[white] regularFont mx-2'>Disable Drop</p></button>
    {/* <CsvDownloadButton title='Download Users' filename={`${dropsData?.dropName}.csv`} className='w-[144px] sfSemiBold text-[white] rounded-[4px]' data={userData} /> */}
    <button onClick={()=>{
      setVisible(true)
    }} type='submit' className=' ml-[40px] mt-1 h-[48px] bg-[rgba(255,255,255,0.3)] w-[144px] sfSemiBold text-[white] rounded-[4px]'><p className='text-[14px] text-[white] regularFont mx-2'>View Data</p></button>
    </div>

    
    </div>
   
    </>

    </div>
    
  )
}
