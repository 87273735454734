import React, { useState } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdRocketLaunch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { getData } from '../_utils';
import ProgressBar from './ProgressBar'
import AirDrop from '../../assets/airdrop.svg'

export default function DropComponent({dropList,setSelectedDrop}) {
    const navigate=useNavigate();
  const [progress,setProgress]=useState(0);
  const [data,setData]=useState([])
  const {address}=useAccount();

//   React.useEffect(()=>{
//     getDropsData()
//   })

  const getDropsData=async()=>{
    const data=getData(address,setData)
  }
  
    return (
    <div className='pr-[40px] mt-[6px] flex flex-col items-start justify-start '>
        <p className='sfSemiBold text-[40px] gradientText'>Update Your Drops</p>

        <div className='flex flex-col w-[100%] my-2'>
           {/* <ProgressBar val1={collectionName} val2={imageUrl} val3={twitterUrl} val4={discordUrl} val5={website} val6={chain}/> */}
           <div className=' flex flex-row  justify-around mb-[32px]'>
            <button onClick={()=>{
                navigate('/dropz')
            }}  className='px-[12px] min-w-[160px] rounded-[6px] py-[10px] flex flex-row justify-between sfBold text-[white] '>
                <img src={AirDrop} className={'w-[20px] h-[20px] mr-[6px]'}/>   LAUNCH DROPZ
            </button>
            <button className='ml-[6px] px-[12px] min-w-[160px] py-[10px] rounded-[6px] flex flex-row justify-center sfBold text-[white] bg-[#1B1833] '>
            <MdRocketLaunch className=' mr-[6px]' color='white' size={20}/> MY DROPZ
            </button>
        </div>
    
            {dropList?.map((item,index)=>(
                <button onClick={()=>{
                    setSelectedDrop(item)
                }} className='h-[56px] w-[350px] my-2  gradientButton rounded-[16px] flex items-center justify-center'>
                <div className='h-[54px] w-[348px] px-2 flex flex-row justify-start items-center rounded-[16px] bg-[black]'>
                <img src={item?.imageUrl} className={'w-[48px] h-[48px] rounded-xl mr-2'}/>
                <p className='sfBold text-[white]'>{item?.dropName}</p>
                </div>
                </button>
            ))}
            
           
            
            
           
            
            
        </div>
    </div>
  )
}
