import { ethers } from "ethers";
import ABI from "./ERC20ABI.json";
import { erc721ABI } from "wagmi";
import DEPLOYMENT from "../data/deployment.json";
import axios from "axios";
import toast from "react-hot-toast";
import { errorToast } from "./toast";
import Bugsnag from "@bugsnag/js";

export const ERC20Balance = async (provider, contractAddress, userAddress) => {
  const contract = new ethers.Contract(contractAddress, ABI, provider);
  const name = await contract.symbol();
  const decimal = await contract.decimals();
  const balance = await contract.balanceOf(userAddress);
  // console.log(balance, decimal, name)
  return {
    balance: ethers.utils.formatUnits(balance._hex, decimal),
    symbol: name,
  };
};

export const getDecimals = async (provider, contractAddress) => {
  const contract = new ethers.Contract(contractAddress, ABI, provider);
  const name = await contract.symbol();
  const decimal = await contract.decimals();

  return decimal;
};

export const ERC721Balance = async (provider, contractAddress, userAddress) => {
  const contract = new ethers.Contract(contractAddress, erc721ABI, provider);
  const name = await contract.symbol();
  const balance = await contract.balanceOf(userAddress);
  // console.log(balance, decimal, name)
  return {
    balance: balance,
    symbol: name,
  };
};

export const ERC1155Metadata = async (apiKey, address, chain) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://${chain}.g.alchemy.com/nft/v3/${apiKey}/getContractMetadata?contractAddress=${address}`,
    headers: {},
  };

  const data = await axios.request(config).catch((error) => {
    Bugsnag.notify(new Error(error));
    console.log(error);
  });

  const metadata = data?.data;
  console.log("Metadata recived", metadata, data);

  // errors

  let bool = false;

  if ((metadata?.tokenType).toUpperCase() === "ERC721") {
    errorToast("Token type is ERC721. please select ERC721 option.");
    bool = true;
    throw Error("ERC721");
  } else if (
    (metadata?.tokenType).toUpperCase() === "NOT_A_CONTRACT" ||
    (metadata?.tokenType).toUpperCase() === "UNKNOWN"
  ) {
    errorToast("Contract is not found. Make sure you are in currect chain.");
    throw Error("contract not found");
  }

  return bool;
};

export const ERC721Metadata = async (apiKey, address, chain) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://${chain}.g.alchemy.com/nft/v3/${apiKey}/getContractMetadata?contractAddress=${address}`,
    headers: {},
  };

  const data = await axios.request(config).catch((error) => {
    Bugsnag.notify(new Error(error));
    console.log(error);
  });

  const metadata = data?.data;

  // errors

  let bool = false;

  if ((metadata?.tokenType).toUpperCase() === "ERC1155") {
    errorToast("Token type is ERC1155. please select ERC1155 option.");
    throw Error("ERC1155");
  } else if (
    (metadata?.tokenType).toUpperCase() === "NOT_A_CONTRACT" ||
    (metadata?.tokenType).toUpperCase() === "UNKNOWN"
  ) {
    errorToast("Contract is not found. Make sure you are in currect chain.");
    throw Error("Contract not found");
  }

  return bool;
};

export const ERC1155Balance = async (
  provider,
  contractAddress,
  userAddress
) => {
  const contract = new ethers.Contract(
    contractAddress,
    DEPLOYMENT.erc1155Abi,
    provider
  );
  const name = await contract.name();
  const balance = await contract.balanceOf(userAddress, 1);
  // if (contractAddress === "0xf147d8e5c00d661624D40C364C1992fc0623038a") {
  //   return {
  //     balance: balance,
  //     symbol: "Web3 Matters",
  //   };
  // }
  return {
    balance: parseInt(balance._hex),
    symbol: name,
  };
};

export const getApprovalData = async (
  provider,
  contractAddress,
  userAddress,
  signer,
  amount
) => {
  const contract = new ethers.Contract(contractAddress, ABI, provider);
  const iface = new ethers.utils.Interface(ABI);
  const { chainId } = await provider.getNetwork();
  const decimal = await contract.decimals();

  let allowance = await contract.allowance(
    userAddress,
    DEPLOYMENT.deployed_address[chainId]
  );
  allowance = parseInt(allowance._hex.toString()) / 10 ** decimal;
  if (allowance >= amount) {
    return null;
  }
  let approvalAmt = ethers.utils.parseUnits(amount.toString(), decimal);
  // console.log(typeof approvalAmt);
  // console.log(approvalAmt);

  const txData = iface.encodeFunctionData("approve", [
    DEPLOYMENT.deployed_address[chainId],
    approvalAmt,
  ]);

  return txData;
};

export const getApprovalDataNFT = async (
  provider,
  contractAddress,
  userAddress,
  signer
) => {
  const contract = new ethers.Contract(contractAddress, erc721ABI, provider);
  const iface = new ethers.utils.Interface(erc721ABI);
  const { chainId } = await provider.getNetwork();

  let allowance = await contract.isApprovedForAll(
    userAddress,
    DEPLOYMENT.deployed_address[chainId]
  );
  if (allowance == true) return null;

  const txData = iface.encodeFunctionData("setApprovalForAll", [
    DEPLOYMENT.deployed_address[chainId],
    true,
  ]);
  return txData;
};

export const getApprovalData1155 = async (
  provider,
  contractAddress,
  userAddress,
  signer
) => {
  const contract = new ethers.Contract(
    contractAddress,
    DEPLOYMENT.erc1155Abi,
    provider
  );
  const iface = new ethers.utils.Interface(DEPLOYMENT.erc1155Abi);
  const { chainId } = await provider.getNetwork();

  let allowance = await contract.isApprovedForAll(
    userAddress,
    DEPLOYMENT.deployed_address[chainId]
  );
  if (allowance == true) return null;

  const txData = iface.encodeFunctionData("setApprovalForAll", [
    DEPLOYMENT.deployed_address[chainId],
    true,
  ]);
  return txData;
};

export const getNativeBalance = async (provider, address) => {
  const balance = await provider.getBalance(address);
  //   console.log(balance);
  return { balance: ethers.utils.formatUnits(balance._hex, 18) };
};
