import { useContext, useState } from "react";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { RotatingLines } from "react-loader-spinner";
import { ChainInfo } from "../../../App";
import useScrollLock from "./useScrollLock";

const SelectType = ({
  setStep,
  setRadioButton,
  radiobutton,
  handleSelectedType,
}) => {
  const { selectedChain } = useContext(ChainInfo);
  const tokens = [
    selectedChain === 56 ? "BEP20" : "ERC20",
    selectedChain === 56 ? "BEP721" : "ERC721",
    selectedChain === 56 ? "BIP1155" : "ERC1155",
    selectedChain === 56
      ? "Native Token (BNB)"
      : selectedChain === 137
      ? "Native Token (MATIC)"
      : "Native Token (ETH)",
  ];

  const customIndex = [
    0,
    1,
    3,
    2
  ]

  const { toggle } = useScrollLock();

  const handleState = (index) => {
    setRadioButton(index);
  };

  return (
    <div>
      {/* <div
        className="w-[35%] h-[38px] rounded-full px-[1] mt-[10px] border-[1px] border-[#FE73A5] bg-[transparent] flex justify-center items-center"
        onClick={() => {
          setOpen(true);
          toggle(true);
        }}
        // disabled={contractloading ? true : false}
        // style={{ opacity: radioButtonState > -1 ? 1 : 0.5 }}
      >
        <p className=" text-[12px] text-[#FE73A5] font-bold">
          {radiobutton === -1
            ? `Select Token Type`
            : "Type : " + tokens[radiobutton]}
        </p>

        <IoIosArrowDown
          className="text-[#FE73A5] m-1 mt-[5px] cursor-pointer"
          onClick={() => {}}
        />
        {false ? (
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="20"
            visible={true}
          />
        ) : null}
      </div> */}

      <div className="w-[100%] flex justify-start items-center mt-[20px]">
        {tokens.map((value, key) => {
          return (
            <button
              key={key}
              onClick={(e) => {
                // setSelctedOptions(key);
                handleSelectedType(e, customIndex[key]);
                handleState(customIndex[key]);
                toggle(false);
              }}
              className={`${
                radiobutton == customIndex[key]
                  ? "button-friend-tech-selected"
                  : "button-friend-tech"
              }`}
            >
              {value}
            </button>
          );
        })}
      </div>

      {/* {open ? (
        <div
          className="w-screen h-[100%] absolute top-0 bottom-0 left-0 flex justify-center items-center backdrop-blur-xl z-20"
          style={{ backgroundColor: "rgba(0,0,0, 0.5)" }}
        >
          <div className="w-[280px] bg-gray-900 rounded-[16px] px-10  py-[16px]">
            <div className="w-full flex justify-between items-center py-2">
              <p className="text-[white] font-bold">Tokens Type</p>
              <IoMdClose
                className="w-[20px] h-[20px] text-[#FE73A5] cursor-pointer"
                onClick={() => {
                  setOpen(false);
                  toggle(false);
                }}
              />
            </div>

            <div className="w-full flex justify-between items-center mt-[10px]">
              <div className="w-[100%]">
                {tokens.map((item, index) => {
                  return (
                    <div
                      className="flex justify-between items-center m-2 cursor-pointer"
                      onClick={(e) => {
                        handleSelectedType(e, index);
                        toggle(false);
                      }}
                    >
                      <p className="text-[white] text-[14px]">{item}</p>
                      <input
                        checked={radiobutton === index ? true : false}
                        className="cursor-pointer"
                        type="radio"
                        id="token"
                        name="token_type"
                        value="token"
                        onChange={() => {}}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default SelectType;
