import { useState } from "react";
import { useAccount } from "wagmi";
import useDropsDashboardHooks from "../_utils/DropsDashboardHook";
import { DisableDropId, createActivities, createActivitiesV2, createBrandId, createDropId, updateDropId } from "../api/SubmitForm";
import { errorToast, successToast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { fetchBrand } from "../api/fetchDropz";
import Bugsnag from "@bugsnag/js";

function useFormHook() {
   const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [step1Data, setStep1Data] = useState({});
  const [step2Data, setStep2Data] = useState({});
  const [step3Data, setStep3Data] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateWhitelist, setUpdateWhitelist] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const { address } = useAccount();
  const { getUserBradByWallet } = useDropsDashboardHooks();

  const ApplyWhiteListings = async (step3Datas) => {
    try {
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setLoading(true);
      const formData = { ...step1Data, ...step2Data, ...step3Datas };
      // console.log("form Data", formData);

      // console.log(brand);

      if (!brand) {
        const brandId = await createBrandId(formData);
        const dropId = await createDropId(formData, brandId);
        if (dropId) {
          const array = Object.values(formData?.activities).filter(
            (data) => data !== ""
          );
          const activityData = await createActivies(
            formData?.activities,
            dropId
          );
          const data = await createActivitiesV2(activityData);
          // console.log(data);
          if (data) {
            if (updateWhitelist) {
              successToast("Dropz changes saved (👏)");
            } else {
              successToast(
                "Dropz submitted(👏), soon will be reviewed by Komet team."
              );
            }
            setLoading(false);
            setIsDashboard(true);
            resetFeilds();
            const brand = JSON.parse(localStorage.getItem("brand")) || false;
            setStep(brand ? 2 : 1);
            navigate("/dropz");
          }
        }
      } else if (brand?.id !== null && brand?.id !== undefined) {
        const dropId = await createDropId(formData, brand?.id);
        if (dropId) {
          // console.log(dropId);
          if (formData?.activities) {
            const array = Object.keys(formData?.activities).filter(
              (data) => data !== ""
            );

            const activityData = await createActivies(
              formData?.activities,
              dropId
            );
            const data = await createActivitiesV2(activityData);
            if (data) {
              if (updateWhitelist) {
                successToast("Dropz changes saved (👏)");
              } else {
                successToast(
                  "Dropz submitted(👏), soon will be reviewed by Komet team."
                );
              }
              setLoading(false);
              setIsDashboard(true);
              resetFeilds();
              const brand = JSON.parse(localStorage.getItem("brand")) || false;
              setStep(brand ? 2 : 1);
              navigate("/dropz");
            }
          } else {
            setLoading(false);
            setIsDashboard(true);
            setStep(brand ? 2 : 1);
            resetFeilds();
            if (updateWhitelist) {
              successToast("Dropz changes saved (👏)");
            } else {
              successToast(
                "Dropz submitted(👏), soon will be reviewed by Komet team."
              );
            }
            navigate("/dropz");
          }
        }
      } else {
        setStep(brand ? 2 : 1);
        setLoading(false);
        errorToast("brandId is missing.");
      }
    } catch (err) {
      Bugsnag.notify(new Error(err))
      errorToast("Submission failed");
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      setLoading(false);
      console.log(err);
    }
  };

  const UpdateWhiteListings = async (step3Datas) => {
    try {
      if (updateWhitelist?.nftId && updateWhitelist?.brandId) {
        const brand = JSON.parse(localStorage.getItem("brand")) || false;
        setLoading(true);
        const formData = {
          ...step1Data,
          ...step2Data,
          ...step3Datas,
          id: updateWhitelist?.nftId,
          dropzBrandId: updateWhitelist?.brandId,
        };

        if (!brand) {
          const dropId = await updateDropId(
            formData,
            brand?.id || updateWhitelist?.brandId
          );
          if (dropId) {
            const array = Object.values(formData?.activities).filter(
              (data) => data !== ""
            );
            createActivitesForTwitter(formData, array, 0, dropId);
          }
        } else if (brand?.id !== null && brand?.id !== undefined) {
          const dropId = await updateDropId(formData, brand?.id);
          if (dropId) {
            if (formData?.activities) {
              const array = Object.values(formData?.activities).filter(
                (data) => data !== ""
              );
              createActivitesForTwitter(formData, array, 0, dropId);
            } else {
              setLoading(false);
              setIsDashboard(true);
              setStep(brand ? 2 : 1);
              resetFeilds();
              successToast("Submitted");
            }
          }
        } else {
          setStep(brand ? 2 : 1);
          setLoading(false);
          errorToast("brandId is missing.");
        }
      } else {
        errorToast("Unable to update drop");
      }
    } catch (err) {
      errorToast("Submission failed");
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      setLoading(false);
      console.log(err);
    }
  };

  const disableDrop = async (disable) => {
    try {
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setLoading(true);
      const formData = {
        ...step1Data,
        ...step2Data,
        ...step3Data,
        id: updateWhitelist?.nftId,
        dropzBrandId: updateWhitelist?.brandId,
      };

      if (!brand) {
        const dropId = await DisableDropId(
          formData,
          brand?.id || updateWhitelist?.brandId,
          disable
        );

        if (dropId) {
          setLoading(false);
          setIsDashboard(true);
          setStep(brand ? 2 : 1);
          resetFeilds();
          successToast("Setting Saved");
        }
      } else if (brand?.id !== null && brand?.id !== undefined) {
        const dropId = await DisableDropId(formData, brand?.id, disable);
        if (dropId) {
          // console.log(dropId);
          setLoading(false);
          setIsDashboard(true);
          setStep(brand ? 2 : 1);
          resetFeilds();
          successToast("Setting Saved");
        }
      } else {
        setStep(brand ? 2 : 1);
        setLoading(false);
        errorToast("brandId is missing.");
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      setLoading(false);
      errorToast("Submission failed");
      console.log(err);
    }
  };

  const createActivies = (value, dropId) => {
    let finalArray = [];
    const key = Object.keys(value);
    key.forEach(async (element) => {
      if (element == "retweet" && value[element] !== "") {
        const username = await getTwitterId(value[element]);
        const a = {
          payload: username,
          tweetUrl: value[element],
          dropId: dropId,
          type: "RETWEET",
        };
        // console.log("activity", a);
        finalArray.push(a);
      } else if (element == "like" && value[element] !== "") {
        const username = await getTwitterId(value[element]);
        const b = {
          payload: username,
          tweetUrl: value[element],
          dropId: dropId,
          type: "LIKE",
        };
        // console.log("activity", b);
        finalArray.push(b);
      }
    });
    return finalArray;
  };

  const createActivitesForTwitter = async (formData, array, index, dropId) => {
    if (index < array?.length) {
      if (
        formData?.activities?.follow !== "" &&
        formData?.activities?.follow == array[index]
      ) {
        const username = await getTwitterUsername(array[index]);
        const info = {
          payload: username,
          tweetUrl: array[index],
          dropId: dropId,
          type: "FOLLOW",
        };
        const activity = await createActivities(info);
      } else if (
        formData?.activities?.retweet !== "" &&
        formData?.activities?.retweet !== array[index]
      ) {
        const username = await getTwitterId(array[index]);
        const info = {
          payload: username,
          tweetUrl: array[index],
          dropId: dropId,
          type: "RETWEET",
        };
        const activity = await createActivities(info);
      } else if (
        formData?.activities?.like !== "" &&
        formData?.activities?.like == array[index]
      ) {
        const username = await getTwitterId(array[index]);
        const info = {
          payload: username,
          tweetUrl: array[index],
          dropId: dropId,
          type: "LIKE",
        };
        const activity = await createActivities(info);
      }

      let newIndex = index + 1;
      createActivitesForTwitter(formData, array, newIndex, dropId);
      setLoading(false);
    } else {
      if (updateWhitelist) {
        successToast("Dropz changes saved (👏)");
      } else {
        successToast(
          "Dropz submitted(👏), soon will be reviewed by Komet team."
        );
      }
      setLoading(false);
      setIsDashboard(true);
      resetFeilds();
      const brand = JSON.parse(localStorage.getItem("brand")) || false;
      setStep(brand ? 2 : 1);
      navigate("/dropz");
    }
  };

  const getTwitterUsername = (url) => {
    return url?.replace("https://twitter.com/", "");
  };

  const getTwitterId = (url) => {
    return url?.substring(url?.toString()?.lastIndexOf("/") + 1);
  };

  const getCreatorBrandId = async (address) => {
    try {
      const result = await fetchBrand(address);
      if (result?.length > 0) {
        setIsDashboard(true);
        setStep(2);
        if (result !== undefined) {
            window.localStorage.setItem("brand", JSON.stringify(result[0]));
        }
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      errorToast("err : while fetching you account");
    }
  };

  const setDataToTheFeild = (data, brands) => {
    setUpdateWhitelist({ nftId: data?.id, brandId: data?.dropzBrandId });

    setStep1Data({
      url: brands?.imageUrl,
      chain: brands?.chain,
      name: brands?.name,
      twitterUrl: brands?.twitterUrl,
      discordUrl: brands?.discordUrl,
      siteUrl: brands?.website,
      email: brands?.emailAddress,
      walletAddress: brands?.walletAddress,
      telegram: brands?.telegram,
    });

    setStep2Data({
      title: data?.dropName,
      dropzType: data?.type,
      thumbnail: data?.imageUrl,
      description: data?.description,
      rafflesActivity: "",
      activities: data?.activities,
      startLaunch: data?.activeFrom,
      endLaunch: data?.activeTo,
      id: data?.id,
      isLive: data?.isLive,
      passcode: data?.passcode,
      utility: data?.utility,
    });

    setStep3Data({
      rewardOne: data?.reward1,
      rewardTwo: data?.reward2,
      rewardType: data?.rewardType,
      contractAddress: data?.contractAddress,
      MetadataLink: data?.MetadataLink,
    });

    // console.log(data, brands, step1Data, step2Data, step3Data);
  };

  const resetFeilds = () => {
    // setUpdateWhitelist({ nftId: data?.id, brandId: data?.dropzBrandId });

    setStep1Data({
      url: "",
      chain: "",
      name: "",
      twitterUrl: "",
      discordUrl: "",
      siteUrl: "",
      email: "",
      walletAddress: "",
      telegram: "",
    });

    setStep2Data({
      title: "",
      dropzType: "",
      thumbnail: "",
      description: "",
      rafflesActivity: "",
      activities: "",
      startLaunch: "",
      endLaunch: "",
    });

    setStep3Data({
      rewardOne: "",
      rewardTwo: "",
      rewardType: "",
      contractAddress: "",
      MetadataLink: "",
    });
  };

  return {
    step1Data,
    setStep1Data,
    step2Data,
    setStep2Data,
    step3Data,
    setStep3Data,
    loading,
    setLoading,
    updateWhitelist,
    setUpdateWhitelist,
    isDashboard,
    setIsDashboard,
  };
}

export default useFormHook;
