import React from "react";
// import useCheckMobileScreen from "../utills/checkMobile";
import PolygonLogo from "../../assets/images/polygon.svg";
import BiconomyLogo from "../../assets/images/biconomy.svg";

export default function LaunchPartnerForm() {
  const names = [" Alchemy", " | ", "Polygon", " | ", "BuildersTribe "];
  return (
    <div className="w-[100%] relative top-0 mt-[80px]">
      <div className="flex flex-col items-center justify-center">
        <p className=" w-[100%] text-center mb-6 regularFont text-[rgba(255,255,255,0.5)] text-[18px]">
          Our Trusted Partners
        </p>
        {/* <div className='w-[90%] sm:w-[80%] h-[2px] my-4 bg-[rgba(255,255,255,0.08)]'/> */}
        <div className="flex w-[100%] items-center justify-center my-4">
          {/* <img
            className="mx-2 sm:mx-10 w-[85px] sm:w-[80px]"
            src={BiconomyLogo}
          /> */}

          <img
            className="mx-2 sm:mx-10 w-[85px] sm:w-[80px]"
            src={PolygonLogo}
          />
          <img
            className="mx-2 sm:mx-10 w-[85px] sm:w-[80px]"
            src={
              "https://firebasestorage.googleapis.com/v0/b/generated-surf-349813.appspot.com/o/Launch%2Fchainlink.svg?alt=media&token=eb790ee2-f2bd-4c24-a7e0-d8b25be97c78&_gl=1*1gfvwta*_ga*OTMyMDkwMDQxLjE2NzE1NTQ0MzI.*_ga_CW55HF8NVT*MTY4NjA2Njk0OS4xMDUuMS4xNjg2MDY4OTIxLjAuMC4w"
            }
          />
        </div>
        {/* <img className='mx-2 sm:mx-10 w-[85px] sm:w-[190px]' src={ChainlinkLogo}/> */}

        {/* <img className='mx-2 sm:mx-10 w-[85px] sm:w-[190px]' src={Wyre}/> */}
      </div>
    </div>
  );
}
{
  /*Experience and fall in love with a re-imagined uncomplicated way to interact with NFTs in a secured environment. */
}
