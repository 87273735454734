import { collection, doc, getDocs, setDoc, where } from "firebase/firestore";
import { db } from "./firebase";
import { errorToast } from "./toast";
import Bugsnag from "@bugsnag/js";

export const AddPOAP = async (creatorTwitter,description,imageUrl,name,utility,email,wallet,dropName,number,dropId,telegramId,contractAddress) => {
    try{
      const data = {
        createdAt:new Date(),
        creator: creatorTwitter?.split('.com/')[1] || creatorTwitter ,
        email: email,
        creatorWalletAddress: wallet,
        dropId:dropId?dropId:Math.floor((Math.random() * 10000) + 1),
        twitterUrl:creatorTwitter,
        description:description,
        dropName:dropName.split(" ").join(""),
        imageUrl:imageUrl,
        name:name,
        supply:isNaN(parseInt(number))?number:parseInt(number),
        utility:utility,
        enabled:false,
        telegramId:telegramId,
        contractAddress:contractAddress,
        code:''
      };

      // console.log("onClick submit data ", data);

      await setDoc(
        doc(
          db,
          'drops',
          dropName.split(" ").join(""),
        ),
        data
      ).catch((err)=>{
        Bugsnag.notify(new Error(err));
        errorToast('Please try again or contact team')
      })
      //setSuccess(true)
      //eventTrack(`DROP_APPLIED_SUCCESS`,dropname,localStorage.getItem('address'))
    } catch (err) {
      Bugsnag.notify(new Error(err));
      console.log("Error",err)
      errorToast('Please try again or contact team')
      //errorToast('Please try again later')
      //eventTrack(`DROP_APPLIED_FAILED`,JSON.stringify(err),localStorage.getItem('address'))
    }
  };

  export const EditPOAP = async (creatorTwitter,description,imageUrl,name,utility,email,wallet,dropName,number,dropId,telegramId,contractAddress,enabled,code) => {
    try{
      const data = {
        createdAt:new Date(),
        creator: creatorTwitter?.split('.com/')[1] || creatorTwitter ,
        email: email,
        creatorWalletAddress: wallet,
        dropId:dropId?dropId:Math.floor((Math.random() * 10000) + 1),
        twitterUrl:creatorTwitter,
        description:description,
        dropName:dropName.split(" ").join(""),
        imageUrl:imageUrl,
        name:name,
        supply:isNaN(parseInt(number))?number:parseInt(number),
        utility:utility,
        enabled:enabled,
        telegramId:telegramId,
        contractAddress:contractAddress,
        code:code || ''
      };

      // console.log("onClick submit data ", data);

      await setDoc(
        doc(
          db,
          'drops',
          dropName.split(" ").join(""),
        ),
        data
      ).catch((err)=>{
        Bugsnag.notify(new Error(err));
        errorToast('Please try again or contact team')
      })
      //setSuccess(true)
      //eventTrack(`DROP_APPLIED_SUCCESS`,dropname,localStorage.getItem('address'))
    } catch (err) {
      Bugsnag.notify(new Error(err));
      console.log("Error",err)
      errorToast('Please try again later')
      //eventTrack(`DROP_APPLIED_FAILED`,JSON.stringify(err),localStorage.getItem('address'))
    }
  };

  export const getDropBanner = async (address) => {
    let arr = [];
    const querySnapshot = await getDocs(collection(db, "drops"));
    
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    // console.log('Arr',arr)
    let filter= arr.filter((x) => x.creatorWalletAddress === address )
    return filter;
  };