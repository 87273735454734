import Bugsnag from "@bugsnag/js";
import axios from "axios";

export const addMetadataTxn = async (addresses, hash) => {
  const transactionMetadataArray = [];
  addresses?.forEach((element) => {
    transactionMetadataArray.push({
      amount: element.amount,
      hash: hash,
      toAddress: element.address,
    });
  });

  //   Array constant

  let data = JSON.stringify(transactionMetadataArray);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://prod-api.komet.me/dropzone/add/metadata",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios.request(config);
  return result?.data || false;
};

export const sendUserTransactionDetails = (
  address,
  amount,
  chain,
  hash,
  tag,
  token
) => {
  let data = JSON.stringify({
    address,
    amount,
    chain,
    hash,
    tag,
    token,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://prod-api.komet.me/dropzone/add",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log("Txn user sended", JSON.stringify(response.data));
    })
    .catch((error) => {
      Bugsnag.notify(new Error(error));
      console.log(error);
    });
};
