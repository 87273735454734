import { ChainInfo } from "../../App";

import { useContext, useEffect, useState } from "react";

import { ethers } from "ethers";
import {
  ERC20Balance,
  getApprovalData,
  ERC721Balance,
  getApprovalDataNFT,
  getApprovalData1155,
  ERC1155Balance,
  ERC1155Metadata,
  ERC721Metadata,
} from "../../utils/erc20";

import {
  generateSignature,
  getBatchingERC20Data,
  sendBatchingTransaction,
  getBatchingERC721Data,
  getBatchingNativeData,
  getBatchingERC1155Data,
} from "../../utils/contractInteraction";

import { useAccount, useProvider, useSigner } from "wagmi";

import Papa from "papaparse";

import { getOwnNft } from "../../utils/Alchemy";
import { errorToast } from "../../utils/toast";

import {
  addMetadataTxn,
  sendUserTransactionDetails,
} from "../../utils/transactionApi";
import { formatUnits, isAddress, parseEther } from "ethers/lib/utils.js";
import Bugsnag from "@bugsnag/js";
import {
  getContractMetadataAvax,
  getErc20TokenContractBalanceFetch,
  getErc20TokenContractMetadata,
  getNftBalanceOwnByOwnerAvax,
} from "../../utils/avalanche";

const useDropzoneHooks = ({ setSteps, steps }) => {
  const provider = useProvider();
  const wallet = useAccount();
  const { data: signer } = useSigner();
  const [senders, setSenders] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState({ bool: false, err: "" });
  const [isTransactionStart, setIsTransactionStart] = useState(false);
  const [isItemRemoved, setIsItemRemoved] = useState(false);
  const [hash, setHash] = useState(
    "0x7109482b955d5e3259d2700e48a7058b23325786bc01258c7cfa5dc7f3813f87"
  );
  const [contractAddress, setContractAddress] = useState("");
  const [tempContractAddress, setTempContractAddress] = useState("");
  const [failToLoad, setFailToLoad] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [contractAddressLogo, setContractAddressLogo] = useState("");
  const [contractloading, setContractLoading] = useState(false);
  const [selecterAddress, setSelecterAddresss] = useState(false);
  const [importCsvOpen, setImportCsvOpen] = useState(false);
  const [radioButtonState, setRadioButtonState] = useState(-1);
  const [contractInfo, setContractInfo] = useState({
    balance: 0,
    symbol: "",
  });
  const [nativeBalance, setNativeBalance] = useState({ balance: 0 });
  const [currentChain, setCurrentChain] = useState(1);
  const [priceInUsd, setPriceInUsd] = useState({ usd: "0" });
  const [transactionButtonDisable, setTransactionButtonDisable] =
    useState(true);

  //   total amount
  const [totalAmount, setTotalAmount] = useState(1);

  const { selectedChain } = useContext(ChainInfo);

  const getAlchemyChain = (chainId) => {
    switch (chainId) {
      case 1:
        return "eth-mainnet";

      case 137:
        return "polygon-mainnet";

      case 80001:
        return "polygon-mumbai";

      case 5:
        return "eth-goerli";

      case 80002:
        return "polygon-amoy";

      case 8453:
        return "base-mainnet";

      default:
        return null;
    }
  };

  const getContractInfo = async (contractAddress) => {
    try {
      setContractLoading(true);
      // console.log("radio", radioButtonState);
      let info;
      if (radioButtonState == 0) {
        getSelcetedChainTokenDetection(0, contractAddress);
      } else if (radioButtonState === 1) {
        getSelcetedChainTokenDetection(1, contractAddress);
        // info = await getErc721TokenData();
      } else if (radioButtonState === 3) {
        getSelcetedChainTokenDetection(3, contractAddress);
        // info = await getErc1155TokenData();
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      console.log("err", err);
      setContractLoading(false);
    }
  };

  // devide call on the basis of selected network

  const getSelcetedChainTokenDetection = async (Type, contractAddress) => {
    let info;

    if (Type == 0) {
      if (selectedChain == 137) {
        info = await getErc20TokenData(contractAddress);
      } else if (selectedChain == 1) {
        info = await getErc20TokenData(contractAddress);
      } else if (selectedChain == 80001) {
        info = await getErc20TokenData(contractAddress);
      } else if (selectedChain == 80002) {
        info = await getErc20TokenData(contractAddress);
      } else if (selectedChain == 8453) {
        info = await getErc20TokenData(contractAddress);
      } else if (selectedChain == 43114) {
        info = await getErc20TokenDataAvax(contractAddress);
      }
    } else if (Type == 1) {
      if (selectedChain == 137) {
        info = await getErc721TokenData(contractAddress);
      } else if (selectedChain == 1) {
        info = await getErc721TokenData(contractAddress);
      } else if (selectedChain == 80001) {
        info = await getErc721TokenData(contractAddress);
      } else if (selectedChain == 80002) {
        info = await getErc721TokenData(contractAddress);
      } else if (selectedChain == 8453) {
        info = await getErc721TokenData(contractAddress);
      } else if (selectedChain == 43114) {
        info = await getErc721TokenDataAvax(contractAddress, true);
      }
    } else if (Type == 3) {
      if (selectedChain == 137) {
        info = await getErc1155TokenData(contractAddress);
      } else if (selectedChain == 1) {
        info = await getErc1155TokenData(contractAddress);
      } else if (selectedChain == 80001) {
        info = await getErc1155TokenData(contractAddress);
      } else if (selectedChain == 80002) {
        info = await getErc1155TokenData(contractAddress);
      } else if (selectedChain == 8453) {
        info = await getErc1155TokenData(contractAddress);
      } else if (selectedChain == 43114) {
        info = await getErc721TokenDataAvax(contractAddress, false);
      }
    }
  };

  // token fectch for Avax

  const getErc721TokenDataAvax = async (contractAddress, isErc721) => {
    try {
      const data = await getContractMetadataAvax(contractAddress).catch(
        (err) => {
          setTransactionButtonDisable((state) => (state = true));
          setContractInfo({
            balance: 0,
            symbol: null,
          });
          setContractLoading(false);
          Bugsnag.notify(err?.toString());
          throw Error("error");
        }
      );

      if (data && wallet?.address) {
        const response = await getNftBalanceOwnByOwnerAvax(
          wallet?.address,
          contractAddress
        );

        if ((isErc721 && response?.length > 0) || (!isErc721 && response)) {
          setContractInfo({
            balance: response?.length,
            symbol: isErc721 ? data?.symbol : data?.name,
          });
          setTransactionButtonDisable((state) => (state = false));
          // setContractLoading(false);
        } else {
          errorToast("NFT not detected in wallet.");
          setTransactionButtonDisable((state) => (state = true));
          setContractInfo({
            balance: 0,
            symbol: null,
          });
          setContractLoading(false);
        }
      } else {
        errorToast("Failed to fetch.");
      }
    } catch (err) {
      console.log(err);
      setContractLoading(false);
      Bugsnag.notify(
        "fetching while token Erc721 Erc1155 fetching for avax" +
          err?.toString()
      );
    }
  };

  const getErc20TokenDataAvax = async (contractAddress) => {
    try {
      const data = await getErc20TokenContractMetadata(contractAddress).catch(
        (err) => {
          console.log("error msg", err);
          setTransactionButtonDisable((state) => (state = true));
          setContractInfo({
            balance: 0,
            symbol: null,
          });
          setContractLoading(false);
          Bugsnag.notify(err?.toString());
          throw Error("error");
        }
      );

      const firstData = data[0];

      const response = await getErc20TokenContractBalanceFetch(
        wallet?.address,
        contractAddress
      );

      if (firstData && response?.length > 0) {
        setContractInfo({
          balance: formatUnits(response[0]?.balance, response[0]?.decimals),
          symbol: firstData?.symbol,
        });
        setTransactionButtonDisable((state) => (state = false));
      }

      // if (data && wallet?.address) {
      //   const response = await getNftBalanceOwnByOwnerAvax(wallet?.address, contractAddress);

      //   if (response?.length > 0) {
      //     setContractInfo({
      //       balance: response?.length,
      //       symbol: data?.symbol
      //     })
      //   } else {
      //     errorToast("NFT not detected in wallet.")
      //     setTransactionButtonDisable((state) => (state = true));
      //     setContractInfo({
      //       balance: 0,
      //       symbol: null,
      //     });
      //     setContractLoading(false);
      //   }
      // } else {
      //   errorToast("Failed to fetch.")
      // }
    } catch (err) {
      console.log(err);
      errorToast("Unable to fetch token details");
      Bugsnag.notify(
        "fetching while token fetching for avax" + err?.toString()
      );
      setContractLoading(false);
    }
  };

  // Erc20 tokens metadata reading

  const getErc20TokenData = async (contractAddress) => {
    console.log(contractAddress, wallet.address);
    const info = await ERC20Balance(
      provider,
      contractAddress,
      wallet.address
    ).catch((err) => {
      console.log(err);
      setTransactionButtonDisable((state) => (state = true));
      setContractInfo({
        balance: 0,
        symbol: null,
      });
      setContractLoading(false);
      errorToast("contract Address is not valid");
    });

    // console.log(info, contractAddress);
    setTransactionButtonDisable((state) => (state = false));
    setContractInfo(info);

    return info;
  };

  const getErc721TokenData = async (contractAddress) => {
    console.log("selected chain", selectedChain);
    let chain = getAlchemyChain(selectedChain);

    if (
      selectedChain !== 56 &&
      selectedChain !== 37 &&
      selectedChain !== 80001
    ) {
      console.log("chain", chain);
      await ERC721Metadata(
        "XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH",
        contractAddress,
        chain
      ).catch(() => {
        setTransactionButtonDisable((state) => (state = true));
        setContractInfo({
          balance: info.balance,
          symbol: info.symbol,
        });
        throw Error("error");
      });
    }

    const info = await ERC721Balance(
      provider,
      contractAddress,
      wallet.address
    ).catch((err) => {
      setContractLoading(false);
    });

    // console.log(info, contractAddress);
    setContractInfo(info);
    setTransactionButtonDisable((state) => (state = false));

    if (info) {
      const value = await getOwnNft(
        wallet.address,
        contractAddress,
        selectedChain
      );
      // console.log("nfts owned by owner", value?.nfts?.length);
      if (value?.nfts?.length <= 0) {
        setTransactionButtonDisable((state) => (state = true));
        errorToast("NFT not detected in wallet");
      }
    }

    return info;
  };

  const getErc1155TokenData = async (contractAddress) => {
    let chain = getAlchemyChain(selectedChain);

    if (
      selectedChain !== 56 &&
      selectedChain !== 31 &&
      selectedChain !== 80001
    ) {
      await ERC1155Metadata(
        "XeQqz2MyT7LbBTuWjVXxdiN05TCqQKuH",
        contractAddress,
        chain
      ).catch(() => {
        setContractInfo({
          balance: info.balance,
          symbol: info.symbol,
        });
        setTransactionButtonDisable((state) => (state = true));
        throw Error("error");
      });
    }

    const info = await ERC1155Balance(
      provider,
      contractAddress,
      wallet.address
    );
    setTransactionButtonDisable((state) => (state = false));
    setContractInfo({
      balance: info.balance,
      symbol: info.symbol,
    });

    if (info) {
      const value = await getOwnNft(
        wallet.address,
        contractAddress,
        selectedChain
      );
      // console.log("nfts owned by owner", value.nfts);
      if (value?.nfts?.length <= 0) {
        setTransactionButtonDisable((state) => (state = true));
        errorToast("NFT not detected in wallet");
      }
    }

    return info;
  };

  // convert csvlist into small chunk

  const ConvertIntoPagination = (data) => {
    const arrayOfArrays = [];
    // console.log("result : ", data);
    const chunkSize = 20;

    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      arrayOfArrays.push(chunk);
    }

    return { pagination: arrayOfArrays };
  };

  // check user enter tokens actually they have or not

  const isUserOwnTokenOrNot = async () => {
    const data = await getOwnNft(
      wallet.address,
      contractAddress,
      selectedChain
    );

    const missingTokens = [];

    const convertIntoTokens = data?.nfts?.map((value) => value?.tokenId);

    senders.forEach((element) => {
      if (!convertIntoTokens?.includes(element.amount)) {
        missingTokens.push(element.amount);
      }
    });

    // console.log("missing tokens", data?.nfts, missingTokens);

    if (missingTokens.length > 0) {
      alert("mising tokens : " + missingTokens?.toString());
    }
    // errorToast("missing tokens : ", missing?.toString());
  };

  const checkBalance = () => {
    let tempBal = 0;
    let balance =
      radioButtonState === 0
        ? contractInfo?.balance
        : radioButtonState === 2
        ? nativeBalance.balance
        : 0;

    // console.log("start");
    if (senders.length > 0) {
      senders.forEach((item) => {
        tempBal += parseFloat(item.amount);
      });

      // console.log("tempbalance", tempBal, balance);
      if (tempBal > balance) {
        setTimeout(() => {
          errorToast("You have insufficient balance.");
          // setTransactionButtonDisable((state) => (state = false));
          setTransactionButtonDisable((state) => (state = true));
        }, 1000);
      } else {
        setTransactionButtonDisable((state) => (state = false));
      }
    }
  };

  const AddSender = async ({ address, amount, erc1155Amount }) => {
    setSenders(
      (state) => (state = [...state, { address, amount, erc1155Amount }])
    );
  };

  useEffect(() => {
    setDataInPaginationView();
  }, [senders]);

  const setDataInPaginationView = async () => {
    // const data = [...senders, { address, amount, erc1155Amount }];
    const pagination = await ConvertIntoPagination(senders);
    // console.log("pagination : ", pagination);
    setPagination((state) => (state = pagination));
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    // console.log(value);
    if (ethers.utils.isAddress(value)) {
      setContractAddress(value);
      getContractInfo(value);
    }
  };

  const handleSelector = (e, number) => {
    e.stopPropagation();
    const data = JSON.parse(localStorage.getItem("wagmi.store"));
    // console.log(data);
    if (data.state.data.account) {
      setRadioButtonState(number);
      resetState();
    } else {
      errorToast("Please connect your wallet");
    }
  };

  function parseCSVFile(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const handleFile = async (file) => {
    try {
      setImportCsvOpen(false);
      const csvData = await parseCSVFile(file);
      // console.log("File", file, csvData);
      const array = [];
      csvData?.forEach((item, index) => {
        if (index > 0) {
          if (
            item[0] !== "address" &&
            item[1] !== "tokenId" &&
            item[2] !== "amount"
          ) {
            // check Address

            // console.log("csv address", index, item[0], isAddress(item[0]));
            if (
              ethers.utils.isAddress(item[0]) &&
              item[0] !== "0x0000000000000000000000000000000000000000"
            ) {
              if (radioButtonState === 0) {
                // const data = {
                //   address: item[0],
                //   amount: item[1],
                // };
                // console.log(senders, data);
                // array.push(data);

                if (item[1]?.toString()?.includes(",")) {
                  item[1]?.split(",").map((element) => {
                    const data = {
                      address: item[0],
                      amount: element?.trim(),
                    };
                    // console.log(senders, data);
                    array.push(data);
                  });
                } else {
                  const data = {
                    address: item[0],
                    amount: item[1]?.trim(),
                  };
                  // console.log(senders, data);
                  array.push(data);
                }
              } else if (radioButtonState === 1) {
                // const data = {
                //   address: item[0],
                //   amount: item[1],
                // };
                // console.log(senders, data);
                // array.push(data);

                if (item[1]?.toString()?.includes(",")) {
                  item[1]?.split(",").map((element) => {
                    const data = {
                      address: item[0],
                      amount: element?.trim(),
                    };
                    // console.log(senders, data);
                    array.push(data);
                  });
                } else {
                  const data = {
                    address: item[0],
                    amount: item[1]?.trim(),
                  };
                  // console.log(senders, data);
                  array.push(data);
                }
              } else if (radioButtonState === 2) {
                if (item[1]?.toString()?.includes(",")) {
                  item[1]?.split(",").map((element) => {
                    const data = {
                      address: item[0],
                      amount: element?.trim(),
                    };
                    // console.log(senders, data);
                    array.push(data);
                  });
                } else {
                  const data = {
                    address: item[0],
                    amount: item[1]?.trim(),
                  };
                  // console.log(senders, data);
                  array.push(data);
                }
              } else if (radioButtonState === 3) {
                const data = {
                  address: item[0],
                  amount: item[1]?.trim(),
                  erc1155Amount: item[2]?.trim(),
                };
                // console.log(senders, data);
                array.push(data);
              }
            } else {
              if (item[0] !== "") {
                errorToast(
                  "one of the address are not vaild address and may be it's zero address",
                  item[0]
                );
              }
              // console.log("fault address", item[0]?.toString());
            }
          }
        }
      });

      if (selectedChain !== 137) {
        if (array.length > 280) {
          // setSenders([]);
          errorToast(
            "Up-to 280 NFTs are allowed in bulk-send through Dropzone! Please upload file with lesser entries"
          );
        } else {
          const paginationData = await ConvertIntoPagination(array);
          // console.log("pagination Data : ", paginationData);
          setSenders(array);
          setPagination(paginationData);
        }
      } else if (selectedChain == 137) {
        if (
          (radioButtonState == 1 && array.length > 500) ||
          (radioButtonState == 0 && array.length > 500)
        ) {
          errorToast(
            "Up-to 500 NFTs are allowed in bulk-send through Dropzone! Please upload file with lesser entries"
          );
        } else if (radioButtonState == 4) {
          if (array.length > 280) {
            // setSenders([]);
            errorToast(
              "Up-to 280 NFTs are allowed in bulk-send through Dropzone! Please upload file with lesser entries"
            );
          } else {
            const paginationData = await ConvertIntoPagination(array);
            // console.log("pagination Data : ", paginationData);
            setSenders(array);
            setPagination(paginationData);
          }
        } else if (
          (radioButtonState == 1 && array.length <= 500) ||
          (radioButtonState == 0 && array.length <= 500) ||
          (radioButtonState == 3 && array.length <= 340)
        ) {
          const paginationData = await ConvertIntoPagination(array);
          // console.log("pagination Data : ", paginationData);
          setSenders(array);
          setPagination(paginationData);
        } else if (radioButtonState == 3 && array.length > 340) {
          errorToast(
            "Up-to 340 NFTs are allowed in bulk-send through Dropzone! Please upload file with lesser entries"
          );
        }
      }
    } catch (error) {
      Bugsnag.notify(new Error(error));
      console.error("Error parsing CSV file:", error);
      setImportCsvOpen(false);
    }
  };

  const resetState = () => {
    setSteps(-1);
    setContractAddress("");
    // setTransactionButtonDisable((state) => (state = false));
    setContractInfo({
      balance: 0,
      symbol: null,
    });
    setSenders([]);
    setContractLoading(false);
  };

  const getTotalAmount = (senders) => {
    let sum = 0;
    if (radioButtonState !== 3) {
      senders.map((item) => {
        sum += parseFloat(item.amount);
      });
    } else {
      senders.map((item) => {
        sum += parseFloat(item.erc1155Amount);
      });
    }
    return sum;
  };

  const set1USDPrice = () => {
    const platformInUsd = 1;
    const ethInUsd = localStorage.getItem("matRate") || 0.9;
    return (platformInUsd / parseFloat(ethInUsd))?.toFixed(8);
  };

  const getEthereumWorth = () => {
    const platformInUsd = 1;
    const ethInUsd = localStorage.getItem("ethRate") || 3399.53;
    return (platformInUsd / parseFloat(ethInUsd))?.toFixed(8);
  };

  const getAvaxWorth = () => {
    const platformInUsd = 1;
    const avaxInUsd = localStorage.getItem("avaxRate") || 39.13;
    return (platformInUsd / parseFloat(avaxInUsd))?.toFixed(8);
  };

  const sendTransaction = async (r, s, v) => {
    try {
      setIsTransactionStart(true);
      let data;
      let value = 0;
      switch (radioButtonState) {
        case 0:
          data = await getBatchingERC20Data(
            provider,
            signer,
            wallet.address,
            contractAddress,
            getTotalAmount(senders).toFixed(6),
            senders,
            80001
          );

          if (selectedChain == 137) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(set1USDPrice(priceInUsd.usd))
                : 0;
          } else if (selectedChain == 1) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(getEthereumWorth())
                : 0;
          } else if (selectedChain == 43114) {
            value =
              getTotalAmount(senders) >= 100 ? parseFloat(getAvaxWorth()) : 0;
          } else if (selectedChain == 8453) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(getEthereumWorth())
                : 0;
          }
          break;
        case 1:
          data = await getBatchingERC721Data(
            provider,
            signer,
            wallet.address,
            contractAddress,
            senders
          );

          // console.log("data 721 => ", data);

          if (selectedChain == 137) {
            value =
              senders.length >= 1
                ? parseFloat(set1USDPrice(priceInUsd.usd))
                : 0;
          } else if (selectedChain == 1) {
            value = senders.length >= 1 ? parseFloat(getEthereumWorth()) : 0;
          } else if (selectedChain == 43114) {
            value = senders.length >= 1 ? parseFloat(getAvaxWorth()) : 0;
          } else if (selectedChain == 8453) {
            value = senders.length >= 1 ? parseFloat(getEthereumWorth()) : 0;
          }
          break;
        case 2:
          let response = await getBatchingNativeData(
            provider,
            signer,
            wallet.address,
            getTotalAmount(senders).toFixed(6),
            senders
          );
          // console.log(response);
          data = response["encodedData"];

          if (selectedChain == 137) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(
                    ethers.utils.formatEther(response["totalAmount"])
                  ) + parseFloat(set1USDPrice(priceInUsd.usd))
                : ethers.utils.formatEther(response["totalAmount"]);
          } else if (selectedChain == 1) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(
                    ethers.utils.formatEther(response["totalAmount"])
                  ) + parseFloat(getEthereumWorth())
                : ethers.utils.formatEther(response["totalAmount"]);
          } else if (selectedChain == 43114) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(
                    ethers.utils.formatEther(response["totalAmount"])
                  ) + parseFloat(getAvaxWorth())
                : ethers.utils.formatEther(response["totalAmount"]);
          } else if (selectedChain == 8453) {
            value =
              getTotalAmount(senders) >= 100
                ? parseFloat(
                    ethers.utils.formatEther(response["totalAmount"])
                  ) + parseFloat(getEthereumWorth())
                : ethers.utils.formatEther(response["totalAmount"]);
          } else {
            value = ethers.utils.formatEther(response["totalAmount"]);
          }
          break;
        case 3:
          data = await getBatchingERC1155Data(
            provider,
            signer,
            wallet.address,
            contractAddress,
            senders
          );

          if (selectedChain == 137) {
            value =
              senders.length >= 1
                ? parseFloat(set1USDPrice(priceInUsd.usd))
                : 0;
          } else if (selectedChain == 1) {
            value = senders.length >= 1 ? parseFloat(getEthereumWorth()) : 0;
          } else if (selectedChain == 43114) {
            value = senders.length >= 1 ? parseFloat(getAvaxWorth()) : 0;
          } else if (selectedChain == 8453) {
            value = senders.length >= 1 ? parseFloat(getEthereumWorth()) : 0;
          }

          // console.log("whats the issue", priceInUsd, set1USDPrice(priceInUsd.usd), value);
          break;
      }

      console.log("signature : ", r, s, v, "data : ", data);
      const hashs = await sendBatchingTransaction(
        provider,
        signer,
        wallet.address,
        r,
        s,
        v,
        data,
        value
      );

      // console.log("hash", hashs);

      // call API here

      const result = await addMetadataTxn(senders, hashs?.hash);
      if (result) {
        const amount =
          radioButtonState === 0 || radioButtonState == 2
            ? getTotalAmount(senders)
            : senders?.length;
        sendUserTransactionDetails(
          wallet.address,
          amount,
          getChainByChainId(selectedChain),
          hashs?.hash,
          getCurrentTokenType(radioButtonState),
          contractInfo?.symbol || "NATIVE_TOKEN"
        );
      }

      if (hashs?.hash) {
        setHash(hashs);
      } else {
        setHash({ hashs });
      }
      setSteps((state) => (state += 1));
      setIsTransactionStart(false);
    } catch (err) {
      const jsonFormat = JSON.stringify(err);
      Bugsnag.notify(new Error(jsonFormat));
      console.log(
        "sendTransaction",
        JSON.parse(JSON.stringify(err)),
        JSON.stringify(err),
        err
      );
      setIsError({ bool: true, err: err });
      setIsTransactionStart(false);
      // errorToast(getErrorMessage(err?.data?.message) || "unexpected error occure");
    }
  };

  const getChainByChainId = (chain) => {
    switch (chain) {
      case 1:
        return "Ethereum";

      case 137:
        return "Polygon";

      case 80001:
        return "Polygon_Mumbai";

      case 84531:
        return "Base";
    }
  };

  const getCurrentTokenType = (radiobutton) => {
    switch (radioButtonState) {
      case 0:
        return "ERC20";

      case 1:
        return "ERC721";

      case 2:
        return "NATIVE_TOKEN";

      case 3:
        return "ERC1155";
    }
  };

  const getErrorMessage = (error) => {
    if (
      error
        ?.toString()
        ?.startsWith("err: insufficient funds for gas * price + value:")
    ) {
      return "err: insufficient funds for transaction";
    } else {
      return error;
    }
  };

  const processApproval = async (signature, approvalTxnData) => {
    const { r, s, v } = signature;
    try {
      // console.log(approvalTxnData);
      if (approvalTxnData != null) {
        const sentTx = await signer.sendTransaction({
          to: contractAddress,
          data: approvalTxnData,
          value: 0,
        });

        provider.waitForTransaction(sentTx.hash).then(async (state) => {
          // console.log("start", state);
          if (state?.status === 1) {
            setTimeout(() => {
              sendTransaction(r, s, v);
            }, 4000);
          }
        });
      } else {
        sendTransaction(r, s, v);
      }
    } catch (err) {
      const jsonFormatError = JSON.stringify(err);
      console.log("json error :", err);
      Bugsnag.notify(new Error(jsonFormatError));
      setIsTransactionStart(false);
      setIsError({ bool: true, err: err });
      console.log(err);
      errorToast("Transaction failed");
    }
  };

  const requestApproval = async (senders) => {
    try {
      setIsTransactionStart(true);
      const amount = getTotalAmount(senders);
      // console.log(provider);
      // console.log(contractAddress);
      // console.log(wallet.address);
      // console.log(amount);
      // console.log(amount.toFixed(6));

      const sig = await generateSignature(
        provider,
        signer,
        wallet.address,
        senders
      );

      let approvalTxnData;

      switch (radioButtonState) {
        case 0:
          approvalTxnData = await getApprovalData(
            provider,
            contractAddress,
            wallet.address,
            signer,
            amount.toFixed(6)
          );
          processApproval(sig, approvalTxnData);
          break;
        case 1:
          approvalTxnData = await getApprovalDataNFT(
            provider,
            contractAddress,
            wallet.address,
            signer
          );
          processApproval(sig, approvalTxnData);
          break;
        case 2:
          processApproval(sig, null);
          break;
        case 3:
          approvalTxnData = await getApprovalData1155(
            provider,
            contractAddress,
            wallet.address,
            signer
          );
          processApproval(sig, approvalTxnData);
          break;
      }
    } catch (err) {
      Bugsnag.notify(new Error(err));
      setIsError({ bool: true, err: err });
      setIsTransactionStart(false);
      console.log(err);
      errorToast("Transaction failed");
    }
  };

  const getTxState = () => {
    setIsPending(true);
    provider
      .waitForTransaction(hash.hash || "")
      .then((state) => {
        if (state?.status === 1) {
          setIsPending(false);
        }
      })
      .catch((err) => {
        // Bugsnag.notify(new Error(err));
        console.error(err);
        errorToast("Transaction Failed");
      });
  };

  return {
    provider,
    wallet,
    signer,
    senders,
    setSenders,
    totalAmount,
    setTotalAmount,
    open,
    setOpen,
    isError,
    setIsError,
    isTransactionStart,
    setIsTransactionStart,
    isItemRemoved,
    setIsItemRemoved,
    hash,
    setHash,
    contractAddress,
    contractInfo,
    setContractInfo,
    setContractAddress,
    tempContractAddress,
    setTempContractAddress,
    failToLoad,
    setFailToLoad,
    contractAddressLogo,
    setContractAddress,
    setContractAddressLogo,
    contractloading,
    setContractLoading,
    isPending,
    setIsPending,
    selecterAddress,
    setSelecterAddresss,
    importCsvOpen,
    setImportCsvOpen,
    radioButtonState,
    setRadioButtonState,
    nativeBalance,
    setNativeBalance,
    currentChain,
    setCurrentChain,
    selectedChain,
    priceInUsd,
    setPriceInUsd,
    requestApproval,
    getTxState,
    getTotalAmount,
    handleAddress,
    handleFile,
    handleSelector,
    AddSender,
    checkBalance,
    getAlchemyChain,
    getContractInfo,
    transactionButtonDisable,
    setTransactionButtonDisable,
    set1USDPrice,
    getErrorMessage,
    isUserOwnTokenOrNot,
    pagination,
    setPagination,
    ConvertIntoPagination,
  };
};

export default useDropzoneHooks;
